import React, { Component } from 'react';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';

const WhatWeDoTextBlockWrapper = styled.div`
  margin-top: 100px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: #161515;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8em 0;
  h6 {
    margin-bottom: 10px;
  }
  h5 {
    color: white;
    margin-bottom: 20px;
    font-weight: 600;
  }
  hr {
    margin-inline-start: initial;
    margin-top: 60px;
    margin-bottom: 60px;
    width: 43px;
    opacity: 0.6;
  }
  p {
    color: white;
    &:first-of-type {
      margin-bottom: 25px;
    }
  }
`;

const TextWrapper = styled.div`
  max-width: 800px;
`;

export default class WhatWeDoTextBlock extends Component {
  render() {
    const {
      what_we_do_text_description,
      what_we_do_text_subtitle,
      what_we_do_text_title,
    } = this.props.data.primary;
    return (
      <WhatWeDoTextBlockWrapper>
        <TextWrapper>
          {RichText.render(what_we_do_text_subtitle)}
          {RichText.render(what_we_do_text_title)}
          <hr />
          {RichText.render(what_we_do_text_description)}
        </TextWrapper>
      </WhatWeDoTextBlockWrapper>
    );
  }
}
