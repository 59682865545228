import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import InViewMonitor from 'react-inview-monitor';

const LinkWrapper = styled(Link)`
  display: block;

  ${breakpoint('xs')`
     margin: 25px 0 !important;
   
  `}

  ${breakpoint('md')`
     margin: 0px 0;
  `}

  .image {
    transform: scale(1);
    transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    object-fit: cover;
  }
  &:hover {
    .image {
      transform: scale(1.05);
    }
  }
  h6 {
    margin-top: 20px;
    margin-bottom: 11px;
  }
  h4 {
    margin: 10px 0 !important;
  }
`;

const ImageWrapper = styled.div`
  height: 320px;
  width: 100%;
  overflow: hidden;
  ${breakpoint('xs')`
    height: 160px;
  `}
  ${breakpoint('sm')`
  height: 320px;
  `}
`;

export default class LatestNewsItem extends Component {
  state = {};

  static propTypes = {
    data: PropTypes.shape({
      latest_news_date: PropTypes.array,
      latest_news_image: PropTypes.object,
      latest_news_link: PropTypes.object,
      latest_news_title: PropTypes.array,
    }),
  };

  render() {
    const { data } = this.props;
    return (
      <InViewMonitor
        classNameNotInView="animated fadeOutUpZoomOut"
        classNameInView="animated fadeOutIn "
      >
        <LinkWrapper as={Link} to={`/insights/${data.latest_news_link.uid}`}>
          <div>
            <ImageWrapper>
              <div
                className="image"
                style={{
                  backgroundImage: 'url(' + data.latest_news_image.url + ')',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  height: '320px',
                  width: '100%',
                }}
              />
            </ImageWrapper>
            {RichText.render(data.latest_news_date)}
            {RichText.render(data.latest_news_title)}
          </div>
        </LinkWrapper>
      </InViewMonitor>
    );
  }
}
