import React, { useState } from 'react';
import { createContainer } from 'unstated-next';

function useVideoState(initialState = { url: '', isPlaying: false }) {
  let [url, setUrl] = useState(initialState.url);
  let [isPlaying, setIsPlaying] = useState(initialState.isPlaying);
  let updateIsPlaying = val => setIsPlaying(val);
  let updateUrl = val => setUrl(val);
  return { url, isPlaying, updateIsPlaying, updateUrl };
}

let VideoContext = createContainer(useVideoState);
export default VideoContext;
