import React, { Component } from 'react';
import styled from 'styled-components';
import { Spring, config } from 'react-spring/renderprops';
import * as easings from 'd3-ease';
import breakpoint from 'styled-components-breakpoint';

const BackWrapper = styled.div`
  cursor: pointer;
  rect {
    will-change: width;
    transition: all 0.3s;
  }
`;

class Back extends Component {
  state = {
    mouseOver: false,
    clicked: false,
    focus: false,
  };

  handleHover = state => {
    this.setState({
      mouseOver: state,
      focus: !state ? false : this.state.focus,
    });
  };

  handleFocus = state => {
    this.setState({
      focus: state,
    });
  };

  render() {
    const state = this.state;

    return (
      <Spring config={config.slow} delay={1000} from={{ opacity: 0, transform: 'translate3d(0px, -100px, 0px)' }} to={{opacity: 1, transform: 'translate3d(0px, 0px, 0px)'  }}>
        {props => (
          <BackWrapper
            className="BackIcon-container"
            onMouseEnter={() => this.handleHover(true)}
            onMouseLeave={() => this.handleHover(false)}
            onMouseDown={() => this.handleFocus(true)}
            onMouseUp={() => this.handleFocus(false)}
            onClick={this.props.handleClick}
            style={props}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12.823"
              height="22.627"
              viewBox="0 0 12.823 22.627"
            >
              <g transform="translate(-1242.961 -50.85)">
                <rect
                  width={state.mouseOver ? 10 : 16}
                  height="2.134"
                  transform="translate(1242.961 62.164) rotate(-45)"
                  fill="#fff"
                />
                <rect
                  width={state.mouseOver ? 10 : 16}
                  height="2.134"
                  transform="translate(1244.471 60.655) rotate(45)"
                  fill="#fff"
                />
              </g>
            </svg>
          </BackWrapper>
        )}
      </Spring>
    );
  }
}

export default Back;
