import React, {
  useRef,
  useLayoutEffect,
  useEffect,
  useState,
} from 'react';
import ReactPlayer from 'react-player';
import VideoContext from '../../framework/VideoContext';
import UseEventListener from '../../framework/UseEventListener';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { animateScroll } from 'react-scroll';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

function useLockBodyScroll() {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden';
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle);
  }, []); // Empty array ensures effect is only run on mount and unmount
}

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return width;
}

// eslint-disable-next-line react/display-name
const Functionality = React.forwardRef((props, ref) => {
  useLockBodyScroll(ref);
  return null;
});

const AnimatedDivWrapper = styled(motion.div)`
  z-index: 100000000;
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: ${props => (props.isPlaying ? 'auto' : 'none')};
  opacity: ${props => (props.isPlaying ? '1' : '0')};
  overflow: hidden;
  background: black;
  transform: translateY(100%);
  z-index: 10000;
  pointer-events: 'none';
`;

const VideoContainer = styled.div`
  position: relative;
  height: 100vh;
  margin: 0 auto;
  will-change: opacity;
  overflow: hidden;
  transition: background-color 3s;
  background: ${props => (props.isPlaying ? 'transparent' : 'black')};
`;

const CloseButton = styled.button`
  position: absolute;
  overflow: hidden;
  top: 20%;
  display: flex;
  align-items: center;
  right: 50px;
  opacity: ${props => (props.isPlaying ? 1 : 0)};
  transform: ${props =>
    props.isPlaying ? 'translateY(0px)' : 'translateY(-100px)'};
  transition: ${props =>
    props.isPlaying ? 'opacity 1s, transform 1s' : ' opacity 0s, transform 0s'};
  transition-delay: ${props => (props.isPlaying ? '0.4s' : '0s')};
  z-index: ${props => (props.isPlaying ? 100000 : 1)};
  color: white;
  cursor: pointer;
  ${breakpoint('xs')`
    top: 10%;
  `}
  ${breakpoint('sm')`
    top: 15%;
  `}
  ${breakpoint('md')`
    top: 10%;
  `}
  ${breakpoint('lg')`
    top: 8%;
  `}
   ${breakpoint('xl')`
    right: 10%;
  `}
  svg {
    transition: opacity 0.3s, transform 0.3s;
    transform: scale(1);
    height: 20px;
    &:hover {
      opacity: 0.8;
      transform: scale(0.9);
    }
  }
  .escape {
    ${breakpoint('xs')`
    display: none;
  `}
  ${breakpoint('md')`
    display: block;
  `}
  }
`;

const HeroVideoWrapper = styled.div`
  position: relative;
  iframe {
    background: transparent;
  }
`;

const FullScreenVideo = React.memo(() => {
  const ref = useRef(ref);
  const playerRef = useRef(playerRef);
  const [isReady, setIsReady] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const { isPlaying, updateIsPlaying, url } = VideoContext.useContainer();
  const width = useWindowWidth();

  useEffect(() => {
    if (width <= 425) {
      setIsMobile(true);
    }
    playerRef.current.seekTo(0);

    animateScroll.scrollToTop({
      duration: 0,
    });
  }, [isPlaying]);

  function handleTurnOffVideo(e) {
    if (e.code === 'Escape') {
      turnOffVideo();
    }
  }

  function turnOffVideo() {
    updateIsPlaying(false);
    playerRef.current.seekTo(0);
  }

  UseEventListener('keydown', handleTurnOffVideo);

  const variants = {
    open: { transform: 'translateY(0%)', pointerEvents: 'auto', zIndex: 10000 },
    closed: { transform: 'translateY(100%)', pointerEvents: 'none', zIndex: 0 },
  };

  function handleAnimationComplete() {
    if (isPlaying) {
      setIsReady(true);
    } else {
      setIsReady(false);
    }
  }

  const closeStyle = {
    position: 'absolute',
    width: '0px',
    height: '0px',
  };

  const openStyle = {
    position: 'absolute',
    width: '100%',
    height: '100vh',
  };
  return (
    <>
      <Helmet>
        <html style="background-color: #000;" />
      </Helmet>
      {isPlaying && <Functionality ref={ref} />}
      <div ref={ref} />
      <CloseButton
        onClick={() => {
          turnOffVideo();
        }}
        isPlaying={isPlaying}
        className="close-button"
      >
        {' '}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30.213"
          height="28.172"
          viewBox="0 0 30.213 28.172"
        >
          <defs>
            <clipPath id="a">
              <rect width="30.213" height="28.172" fill="none" />
            </clipPath>
          </defs>
          <g transform="translate(0 0)" clipPath="url(#a)">
            <rect
              width="37.707"
              height="2.134"
              transform="translate(3.55 0) rotate(45)"
              fill="#fff"
            />
            <rect
              width="37.707"
              height="2.134"
              transform="translate(2.04 26.663) rotate(-45)"
              fill="#fff"
            />
          </g>
        </svg>
      </CloseButton>
      <AnimatedDivWrapper
        variants={variants}
        isPlaying={isPlaying}
        animate={isPlaying ? 'open' : 'closed'}
        transition={{ ease: 'easeOut', duration: 0.75 }}
        onAnimationComplete={() => handleAnimationComplete()}
      >
        <HeroVideoWrapper isPlaying={isPlaying}>
          <VideoContainer
            className="video-container"
            isReady={isReady}
            isPlaying={isPlaying}
          >
            <ReactPlayer
              ref={playerRef}
              style={isPlaying ? openStyle : closeStyle}
              height={'100vh'}
              width="100%"
              className="player"
              url={url}
              playing={true}
              onEnded={() => updateIsPlaying(false)}
              preload="true"
              muted={isMobile || !isPlaying ? true : false}
              playsinline={true}
              volume={isMobile || !isPlaying ? 0 : 1}
              config={{
                vimeo: {
                  playerOptions: {
                    preload: true,
                    playsinline: true,
                    transparent: true,
                    quality: '1080p',
                  },
                },
              }}
            />
          </VideoContainer>
        </HeroVideoWrapper>
      </AnimatedDivWrapper>
    </>
  );
});

export default React.memo(FullScreenVideo);
