import React, { Component } from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const BackgroundImageStyled = styled.img`
  height: 120%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: -10%;
  left: 0%;
  filter: opacity(0.4);

  ${breakpoint('xs')`
    display: none;
  `}

  ${breakpoint('lg')`
    display: block;
  `}
`;

class BackgroundImage extends Component {
  render() {
    const { src, styleProps } = this.props;
    return (
      <BackgroundImageStyled src={src} style={styleProps} preload="true" />
    );
  }
}

export default BackgroundImage;
