import React, { Component } from 'react';
import NotFound from '../NotFound/NotFound';
import { Helmet } from 'react-helmet';

import { RichText, Date } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import InViewMonitor from 'react-inview-monitor';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import breakpoint from 'styled-components-breakpoint';
import LoadingPage from '../LoadingPage/LoadingPage';
import { animateScroll } from 'react-scroll';

const NewsArticleStyled = styled.div`
  ${breakpoint('xs')`
     margin-top: 2.65em;
  `}

  ${breakpoint('md')`
     margin-top: 2em;
  `}

  h2 {
    margin-bottom: 18px;
    margin-top: 10px;
  }

  h3 {
    max-width: 537px;
    text-align: center;
    margin: 0 auto;

    ${breakpoint('xs')`
      margin-top: 80px;
    `}

    ${breakpoint('md')`
      margin-top: 190px;
    `}
  }

  h6 {
    margin-bottom: 86px;
    margin-top: 8px;
  }

  img {
    width: 100%;
  }

  p {
    max-width: 720px;
    margin: 0 auto;
    &:first-of-type {
      ${breakpoint('xs')`
        margin-top: 40px;
      `}

      ${breakpoint('md')`
        margin-top: 160px;
      `}
    }
    &:not(:first-of-type) {
      margin-top: 40px;
    }
  }

  .read-next-article-wrapper {
    margin: 0 auto;
    margin-top: 18px;
    text-align: center;
  }

  .next-article {
    padding-bottom: 16px;
    display: inline-block;

    ${breakpoint('xs')`
      margin-top: 40px;
    `}

    ${breakpoint('md')`
      margin-top: 100px;
    `}
  }
`;

export default class NewsArticle extends Component {
  state = {
    doc: null,
    notFound: false,
    isEditable: true,
  };

  componentWillMount() {
    this.fetchPage(this.props);
  }

  componentWillReceiveProps(props) {
    this.fetchPage(props);
  }

  componentDidMount() {
    animateScroll.scrollToTop({
      duration: 0,
      delay: 750,
    });
  }

  fetchPage(props) {
    /* TODO CLEAN UP THIS LOGIC */
    if (props.prismicCtx) {
      return props.prismicCtx.api.getByUID(
        'news_article',
        props.match.params.uid,
        {},
        (err, doc) => {
          if (doc) {
            this.setState({ doc });
          } else {
            this.setState({ notFound: !doc });
          }
        }
      );
    }
    return null;
  }

  render() {
    if (this.state.doc) {
      const { data } = this.state.doc;
      const date = moment(Date(data.news_article_date).toString()).format(
        'MMMM D, YYYY'
      );
      return (
        <React.Fragment>
          <Helmet>
            <title>{data.news_article_title[0].text}</title>
          </Helmet>
          <NewsArticleStyled>
            <Grid>
              <InViewMonitor
                classNameNotInView="animated fadeOutUpBlur"
                classNameInView="animated fadeInUp"
                intoViewMargin="10%"
              >
                <Row between="xs" bottom="xs">
                  <Col xs={1} />
                  <Col xs={10}>
                    <h2>{data.news_article_title[0].text}</h2>
                  </Col>
                  <Col xs={1} />
                </Row>
              </InViewMonitor>

              <InViewMonitor
                classNameNotInView="animated fadeOutUp"
                classNameInView="animated fadeInUp delay-1"
              >
                <Row between="xs" bottom="xs">
                  <Col xs={1} />
                  <Col xs={10}>
                    <h6>{date}</h6>
                  </Col>
                  <Col xs={1} />
                </Row>
              </InViewMonitor>

              <InViewMonitor
                classNameNotInView="animated fadeOutUp"
                classNameInView="animated fadeInUp delay-2"
              >
                <Row>
                  <Col xs={12}>
                    <img alt="news" src={data.news_article_image.url} />
                  </Col>
                </Row>
              </InViewMonitor>

              <InViewMonitor
                classNameNotInView="animated fadeOutUp"
                classNameInView="animated fadeInUp"
              >
                <Row between="xs" bottom="xs">
                  <Col xs={1} />
                  <Col xs={10}>{RichText.render(data.news_article_copy)}</Col>
                  <Col xs={1} />
                </Row>
              </InViewMonitor>

              <InViewMonitor
                classNameNotInView="animated fadeOutUp"
                classNameInView="animated fadeInUp"
              >
                <Row between="xs" bottom="xs">
                  <Col xs={1} />
                  <Col xs={10}>
                    {RichText.render(data.next_article_title)}
                    <div className="read-next-article-wrapper">
                      <Link
                        className="next-article underline"
                        to={`/insights/${data.next_article_link.uid}`}
                      >
                        Read next article
                      </Link>
                    </div>
                  </Col>
                  <Col xs={1} />
                </Row>
              </InViewMonitor>
            </Grid>
          </NewsArticleStyled>
        </React.Fragment>
      );
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return <LoadingPage />;
  }
}
