import React, { Component } from 'react';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import InViewMonitor from 'react-inview-monitor';
import PropTypes from 'prop-types';
import breakpoint from 'styled-components-breakpoint';
import { Col, Row } from 'react-styled-flexboxgrid';

const WhoWeAreWrapper = styled.div`
  h3 {
    margin: 60px 0 40px;
  }
  p:first-of-type {
    margin-bottom: 20px;
  }
`;

const LinkStyled = styled.a`
  padding-bottom: 10px;
  margin-top: 60px;
  ${breakpoint('xs')`
    font-size: 12px;
    letter-spacing: .02em;
  `}
  
  ${breakpoint('md')`
    font-size: 13px;
  `}
  
  ${breakpoint('lg')`
    font-size: 14px;
    letter-spacing: .05em;
  `}

  ${breakpoint('xl')`
    font-size: 16px;
  `}
`;

export default class WhoWeAreCopy extends Component {
  static propTypes = {
    data: PropTypes.shape({
      who_we_are_copy_body: PropTypes.array,
      who_we_are_copy_title: PropTypes.array,
    }),
  };

  render() {
    const { who_we_are_copy_body, who_we_are_copy_title } = this.props.data;
    return (
      <WhoWeAreWrapper>
        <InViewMonitor
          classNameNotInView="animated fadeOutUpBlur"
          classNameInView="animated fadeInUp"
        >
          <Row>
            <Col xs={1} sm={1} md={2} />
            <Col xs={12} sm={10} md={8}>
              {RichText.render(who_we_are_copy_title)}
            </Col>
            <Col xs={1} sm={1} />
          </Row>
        </InViewMonitor>
        <InViewMonitor
          classNameNotInView="animated fadeOutUp"
          classNameInView="animated fadeInUp delay-1"
        >
          <Row>
            <Col xs={1} sm={1} md={2} />
            <Col xs={12} sm={10} md={8}>
              {RichText.render(who_we_are_copy_body)}
            </Col>
            <Col xs={1} sm={1} md={2} />
          </Row>
        </InViewMonitor>
        <InViewMonitor
          classNameNotInView="animated fadeOutUp"
          classNameInView="animated fadeInUp"
        >
          <Row>
            <Col xs={1} sm={1} md={2} />
            <Col xs={12} sm={10} md={8}>
              <LinkStyled
                className="underline"
                href="https://www.weareenvoy.com/"
              >
                Visit Envoy Group
              </LinkStyled>
            </Col>
            <Col xs={1} sm={1} md={2} />
          </Row>
        </InViewMonitor>
      </WhoWeAreWrapper>
    );
  }
}
