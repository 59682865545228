import React from 'react';
import styled from 'styled-components';
import InViewMonitor from 'react-inview-monitor';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import breakpoint from 'styled-components-breakpoint';

const ImageStyled = styled.img`
  height: auto;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
  ${breakpoint('xs')`
    margin: 25px 0;
  `}
  ${breakpoint('md')`
    margin: 50px 0;
  `}
`;

function Image(props) {
  const { image } = props.data.items[0];
  return (
    <InViewMonitor
      classNameNotInView="animated fadeOutUpZoomOut"
      classNameInView="animated fadeInUp"
    >
      <Grid>
        <Row>
          <Col xs={12}>
            <ImageStyled alt="" src={image.url} />
          </Col>
        </Row>
      </Grid>
    </InViewMonitor>
  );
}

export default React.memo(Image);
