export default {
  apiEndpoint: 'https://lvthn.prismic.io/api/v2',

  // -- Access token if the Master is not open
  // accessToken: 'xxxxxx',

  // OAuth
  // clientId: 'xxxxxx',
  // clientSecret: 'xxxxxx',

  // -- Links resolution rules
  // This function will be used to generate links to Prismic.io documents
  // As your project grows, you should update this function according to your routes
  linkResolver(doc) {
    if (doc.type === 'work_detail') {
      return `/work/${doc.uid}`;
    }
    if (doc.type === 'career_post') {
      return `/careers/${doc.uid}`;
    }
    if (doc.type === 'insight_item') {
      return `/insights/${doc.uid}`;
    }
    if (doc.type === 'insights') {
      return `/insights`;
    }
    if (doc.type === 'work_list') {
      return `/work`;
    }
    if (doc.type === 'careers') {
      return `/careers`;
    }
    if (doc.type === 'contact') {
      return `/contact`;
    }
    if (doc.type === 'home') {
      return `/`;
    }
    return '/';
  },
};
