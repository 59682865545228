import React, { Component } from 'react';
import { RichText } from 'prismic-reactjs';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import breakpoint from 'styled-components-breakpoint';
import InViewMonitor from 'react-inview-monitor';
import styled from 'styled-components';
import moment from 'moment';

const StyledHeader = styled.div`
  position: relative;
  z-index: 10;
  .project-title {
    margin-bottom: 2rem;
  }

  ${breakpoint('xs')`
    margin: -30px 0 auto;
  `}

  ${breakpoint('md')`
     margin: -50px auto 5rem;
  `}
`;

class Header extends Component {
  render() {
    const { title, date, type } = this.props;
    return (
      <StyledHeader>
        <InViewMonitor
          classNameNotInView="animated fadeOutUpBlur"
          classNameInView="animated fadeInUp"
          intoViewMargin="10%"
        >
          <Row between="xs" bottom="xs">
            <Col xs={1} />
            <Col xs={10}>
              <h2 className="project-title">{title}</h2>
            </Col>
            <Col xs={1} />
          </Row>
        </InViewMonitor>

        <Row between="xs">
          <Col xs={1} />
          <Col xs={5}>
            <InViewMonitor
              classNameNotInView="animated fadeOutUp"
              classNameInView="animated fadeInUp delay-1"
              intoViewMargin="10%"
            >
              <h6 className="project-detail">
                Application posted {moment(date).format('l')}
              </h6>
            </InViewMonitor>
          </Col>
          <Col xs={5}>
            <InViewMonitor
              classNameNotInView="animated fadeOutUp"
              classNameInView="animated fadeInUp delay-2"
            >
              <h6 style={{ textAlign: 'right' }}>{type}</h6>
            </InViewMonitor>
          </Col>
          <Col xs={1} />
        </Row>
      </StyledHeader>
    );
  }
}

export default Header;
