import React, { Component } from 'react';
import styled from 'styled-components';
import InViewMonitor from 'react-inview-monitor';
import { Col } from 'react-styled-flexboxgrid';
import breakpoint from 'styled-components-breakpoint';

const ScopeOfWorkStyled = styled.div`
  /* margin-left: ${props => (props.isCareers ? '1em' : '0')}; */
  margin-right: ${props => (props.isCareers ? '3em' : '0')};

  h4 {
    margin-bottom: 24px;
  }
  h6 {
    &:first-of-type {
      margin-top: 0;
    }
    margin-top: 8px;
  }

  ${breakpoint('xs')`
    margin-bottom: 2rem;
  `}

  ${breakpoint('md')`
    margin-bottom: 5rem;
  `}
`;

export default class ScopeOfWork extends Component {
  state = {};

  renderScopeItems() {
    return this.props.data.items.map((item, index) => (
      <h6 key={index}>{item.scope_item[0].text}</h6>
    ));
  }

  render() {
    const { scope_title } = this.props.data.primary;
    const { isCareers } = this.props;
    return (
      <Col xs={12} sm={4}>
        <ScopeOfWorkStyled isCareers={isCareers}>
          <InViewMonitor
            classNameNotInView="animated fadeOutUpBlur"
            classNameInView="animated fadeInUp "
          >
            <h4>{scope_title[0].text}</h4>
          </InViewMonitor>

          <InViewMonitor
            classNameNotInView="animated fadeOutUp"
            classNameInView="animated fadeInUp delay-1"
          >
            {this.renderScopeItems()}
          </InViewMonitor>
        </ScopeOfWorkStyled>
      </Col>
    );
  }
}
