import React, { Component } from 'react';
import { Col, Row, Grid } from 'react-styled-flexboxgrid';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import InViewMonitor from 'react-inview-monitor';

const StyledRow = styled(Row)`
  ${breakpoint('xs')`
     margin-bottom: 170px;
  `}
  ${breakpoint('md')`
     margin-bottom: 260px ;
  `}
  padding: 9em 0;
  h2 {
    margin-bottom: 10%;
  }
`;
const BrandShowcaseWrapper = styled.div`
  background: #0f0f0f;
`;

class BrandShowcase extends Component {
  render() {
    const { data } = this.props;
    return (
      <BrandShowcaseWrapper>
        <Grid>
          <InViewMonitor
            classNameNotInView="animated fadeOutUpBlur"
            classNameInView="animated fadeInUp"
          >
            <StyledRow>
              <Col style={{ textAlign: 'center' }} xs={12}>
                {RichText.render(data.primary.brands_title)}
              </Col>
              <Col xs={1} />
              <Col xs={10}>
                <img
                  style={{ width: '100%' }}
                  src={data.items[0].brand.url}
                  alt=""
                />
              </Col>
              <Col xs={1} />
            </StyledRow>
          </InViewMonitor>
        </Grid>
      </BrandShowcaseWrapper>
    );
  }
}

export default BrandShowcase;
