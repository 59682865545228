import React, { Component } from 'react';
import NotFound from '../NotFound/NotFound';
import LoadingPage from '../LoadingPage/LoadingPage';
import Header from './components/Header';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import InViewMonitor from 'react-inview-monitor';
import axios from 'axios';
import parse from 'html-react-parser';
import { startCase } from 'lodash';
import { animateScroll, Element } from 'react-scroll';
import {
  StyledLink,
  FormWrapper,
  LinkStyled,
  StyledCareerPost,
  FromFieldWrapper,
  FloatingLabelWrap,
  Spacer,
  FileList,
  FileUploadWrapper,
  LeftInfoWrapper,
  LinkStyledSubmit,
} from './components/StyledComponents';

class CareerPost extends Component {
  constructor(props) {
    super(props);
    this.resumeRef = React.createRef();
    this.coverLetter = React.createRef();
    this.formRef = React.createRef();
  }
  state = {
    doc: null,
    notFound: false,
    activeSlug: null,
    activeLink: 'summary',
    jobId: null,
    formFields: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      linkedin: '',
      how_hear: '',
      resume: '',
      cover_letter: '',
    },
  };

  componentDidMount() {
    this.getJobId();
    animateScroll.scrollToTop({
      duration: 0,
      delay: 750,
    });
  }

  async fetchPage() {
    if (!this.state.jobId) {
      return;
    }
    const doc = await axios.get(
      `https://boards-api.greenhouse.io/v1/boards/weareenvoy/jobs/${this.state.jobId}`
    );
    this.setState({ doc });
  }

  async getJobId() {
    // Check if jobId was passed from state prop on react router dom
    if (this.props.history.location.state) {
      const {
        state: { jobId },
      } = this.props.history.location;
      if (jobId) {
        return this.setState(
          () => {
            return { jobId };
          },
          () => {
            this.fetchPage();
          }
        );
      }
    }

    //Get id by slug
    const { pathname } = this.props.history.location;
    const jobs = await axios.get(
      'https://boards-api.greenhouse.io/v1/boards/weareenvoy/jobs'
    );
    const activeJob = jobs.data.jobs.find(job =>
      job.title.includes(startCase(pathname.split('/')[pathname.split.length]))
    );
    return this.setState(
      () => {
        return { jobId: activeJob.id };
      },
      () => {
        this.fetchPage();
      }
    );
  }

  submitApplication() {
    const { formFields, jobId } = this.state;
    let fd = new FormData();
    fd.append('job_id', `${jobId}`);
    Object.keys(formFields).map(field => {
      if (formFields[field] !== '') {
        return fd.append(field, formFields[field]);
      }
    });
    fetch('http://localhost:5000/post', {
      method: 'POST',
      body: fd,
    }).then(data => {
      console.log(data);
      data.json().then(resp => {
        console.log(resp);
      });
    });
  }

  render() {
    if (this.state.doc) {
      const { content, title, updated_at, departments } = this.state.doc.data;
      const {
        first_name,
        last_name,
        email,
        phone,
        linkedin,
        how_hear,
        resume,
        cover_letter,
      } = this.state.formFields;
      const readableContent = parse(content);
      return (
        <React.Fragment>
          <Grid>
            <Header
              title={title}
              date={updated_at}
              type={departments[0].name}
            />
            <Row>
              <Col xsOffset={10} xs={2}>
                <StyledLink to="application" smooth={true} duration={750}>
                  <h5>Apply</h5>
                </StyledLink>
              </Col>
            </Row>
            <Row>
              <Col xs={2} />
              <Col xs={8}>
                <InViewMonitor
                  classNameNotInView="animated fadeOutUp"
                  classNameInView="animated fadeInUp"
                >
                  <StyledCareerPost>{parse(readableContent)}</StyledCareerPost>
                </InViewMonitor>
              </Col>
              <Col xs={2} />
            </Row>
          </Grid>
          <InViewMonitor
            classNameNotInView="animated fadeOutUp"
            classNameInView="animated fadeInUp"
          >
            <Element
              encType="multipart/form-data"
              method="post"
              action="/"
              name="application"
            >
              <FormWrapper>
                <Grid>
                  <Row>
                    <Col xs={false} sm={1} />
                    <Col xs={12} sm={11}>
                      <h3 className="apply">Apply for this position</h3>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <LeftInfoWrapper xs={false} sm={3}>
                      <h5>{title}</h5>
                      <h6>Leviathan</h6>
                      <h6>Chicago, IL</h6>
                    </LeftInfoWrapper>
                    <Col xs={12} sm={8} md={6}>
                      <FromFieldWrapper encType="multipart/form-data">
                        <FloatingLabelWrap
                          id="first-name"
                          label="First Name*"
                          onChange={e => {
                            let inputVal = e.target.value;
                            this.setState(state => {
                              this.setState({
                                formFields: {
                                  ...state.formFields,
                                  first_name: inputVal,
                                },
                              });
                            });
                          }}
                          value={first_name}
                        />
                        <Spacer />
                        <FloatingLabelWrap
                          id="last-name"
                          label="Last Name*"
                          onChange={e => {
                            let inputVal = e.target.value;
                            this.setState(state => {
                              this.setState({
                                formFields: {
                                  ...state.formFields,
                                  last_name: inputVal,
                                },
                              });
                            });
                          }}
                          value={last_name}
                        />
                        <Spacer />
                        <FloatingLabelWrap
                          id="email"
                          label="Email*"
                          type="email"
                          onChange={e => {
                            let inputVal = e.target.value;
                            this.setState(state => {
                              this.setState({
                                formFields: {
                                  ...state.formFields,
                                  email: inputVal,
                                },
                              });
                            });
                          }}
                          value={email}
                        />
                        <Spacer />
                        <FloatingLabelWrap
                          id="phone"
                          label="Phone"
                          onChange={e => {
                            let inputVal = e.target.value;
                            this.setState(state => {
                              this.setState({
                                formFields: {
                                  ...state.formFields,
                                  phone: inputVal,
                                },
                              });
                            });
                          }}
                          value={phone}
                        />
                        <Spacer />
                        <FloatingLabelWrap
                          id="linkedin"
                          label="Linkedin Profile"
                          onChange={e => {
                            let inputVal = e.target.value;
                            this.setState(state => {
                              this.setState({
                                formFields: {
                                  ...state.formFields,
                                  linkedin: inputVal,
                                },
                              });
                            });
                          }}
                          value={linkedin}
                        />
                        <Spacer />
                        <FloatingLabelWrap
                          id="how-hear"
                          label="How did you hear about this job?"
                          onChange={e => {
                            let inputVal = e.target.value;
                            this.setState(state => {
                              this.setState({
                                formFields: {
                                  ...state.formFields,
                                  how_hear: inputVal,
                                },
                              });
                            });
                          }}
                          value={how_hear}
                        />
                      </FromFieldWrapper>
                      <FileUploadWrapper>
                        <div>
                          <LinkStyled
                            onClick={() => this.resumeRef.current.click()}
                            className="underline"
                          >
                            Upload Resume
                          </LinkStyled>
                          <input
                            ref={this.resumeRef}
                            style={{
                              visibility: 'hidden',
                              width: '1px',
                              height: '1px',
                            }}
                            type="file"
                            onChange={e => {
                              let inputVal = e.target.files[0];
                              this.setState(state => {
                                this.setState({
                                  formFields: {
                                    ...state.formFields,
                                    resume: inputVal,
                                  },
                                });
                              });
                            }}
                          />
                          {resume && <FileList>{resume.name}</FileList>}
                        </div>
                        <div>
                          <LinkStyled
                            onClick={() => this.coverLetter.current.click()}
                            className="underline"
                          >
                            Cover Letter
                          </LinkStyled>
                          <input
                            style={{
                              visibility: 'hidden',
                              width: '1px',
                              height: '1px',
                            }}
                            ref={this.coverLetter}
                            type="file"
                            onChange={e => {
                              let inputVal = e.target.files[0];
                              this.setState(state => {
                                this.setState({
                                  formFields: {
                                    ...state.formFields,
                                    cover_letter: inputVal,
                                  },
                                });
                              });
                            }}
                          />
                          {cover_letter && (
                            <FileList>{cover_letter.name}</FileList>
                          )}
                        </div>
                      </FileUploadWrapper>
                      <LinkStyledSubmit
                        className="underline"
                        onClick={() => this.submitApplication()}
                      >
                        Submit Application
                      </LinkStyledSubmit>
                    </Col>
                    <Col xs={false} sm={2} />
                  </Row>
                </Grid>
              </FormWrapper>
            </Element>
          </InViewMonitor>
        </React.Fragment>
      );
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return <LoadingPage />;
  }
}

export default CareerPost;
