import React, { Component } from 'react';
import styled from 'styled-components';
import * as easings from 'd3-ease';
import { Trail } from 'react-spring/renderprops';

const LogoContainer = styled.div`
  cursor: pointer;
  #circle {
    transition: all 0.4s;
  }
  path {
    transition: all 0.4s;
  }
`;

class Play extends Component {
  state = {
    mouseOver: false,
    clicked: false,
    focus: false,
  };

  handleHover = state => {
    this.setState({
      mouseOver: state,
      focus: !state ? false : this.state.focus,
    });
  };

  handleFocus = state => {
    this.setState({
      focus: state,
    });
  };

  render() {
    const state = this.state;
    const fillColor = state.mouseOver ? '#999' : '#fff';
    const paths = [
      {
        letter: 'box',
        key: 1,
        path:
          'M0,0v90h90V0H0z M54.2,33.7H42.5v7.9h8v6.7h-8v7.9h11.7V63H35.8V27h18.4V33.7z',
      },

      {
        letter: 'f',
        key: 2,
        path:
        'M108.7,53.1V34.4h12.5v2.3h-10.1v5.7h8.7v2.3h-8.7v8.4C111.1,53.1,108.7,53.1,108.7,53.1z',
      },

      {
        letter: 'f',
        key: 3,
        path:
        'M122.7,46.3c0-2.6,0.8-4.4,2.1-5.6c1.2-1.1,2.6-1.5,4.2-1.5c1.8,0,3.3,0.6,4.5,1.8c1.2,1.2,1.8,2.9,1.8,5 c0,1.8-0.3,3.2-0.8,4.1c-0.5,1.1-1.2,1.8-2.3,2.3c-1.1,0.5-2.1,0.8-3.3,0.8c-2,0-3.5-0.6-4.5-1.8C123.3,50.4,122.7,48.6,122.7,46.3 z M125.1,46.3c0,1.7,0.3,3,1.2,3.9c0.8,0.9,1.7,1.4,2.9,1.4c1.2,0,2.1-0.5,2.9-1.4c0.8-0.9,1.2-2.1,1.2-3.9c0-1.7-0.5-2.9-1.2-3.8 c-0.8-0.9-1.7-1.4-2.9-1.4c-1.2,0-2.1,0.5-2.9,1.4C125.6,43.5,125.1,44.7,125.1,46.3z',
      },

      {
        letter: 'o',
        key: 4,
        path:
        'M138.1,53.1V39.5h2.1v2.1c0.5-0.9,1.1-1.7,1.5-2c0.5-0.3,0.9-0.5,1.5-0.5c0.8,0,1.5,0.3,2.4,0.8l-0.8,2.1 c-0.6-0.3-1.1-0.5-1.7-0.5c-0.5,0-0.9,0.2-1.4,0.5c-0.5,0.3-0.8,0.8-0.9,1.2c-0.3,0.8-0.5,1.7-0.5,2.7v7.1 C140.5,53.1,138.1,53.1,138.1,53.1z',
      },

      {
        letter: 'r',
        key: 5,
        path:
        'M148.3,53.1V39.5h2.1v2c0.5-0.6,1.1-1.2,1.7-1.7c0.8-0.5,1.5-0.6,2.4-0.6c1.1,0,1.8,0.2,2.4,0.6 c0.6,0.5,1.1,1.1,1.4,1.8c1.1-1.5,2.4-2.4,4.2-2.4c1.4,0,2.4,0.3,3,1.1c0.6,0.8,1.1,1.8,1.1,3.5v9.2h-2.3v-8.4c0-0.9,0-1.5-0.2-2 c-0.2-0.5-0.5-0.8-0.8-0.9c-0.3-0.2-0.9-0.3-1.4-0.3c-0.9,0-1.8,0.3-2.4,0.9c-0.6,0.6-0.9,1.7-0.9,3v7.8h-2.3v-8.7 c0-1.1-0.2-1.8-0.6-2.3c-0.3-0.5-1.1-0.8-1.8-0.8c-0.6,0-1.2,0.2-1.8,0.5c-0.6,0.3-0.9,0.9-1.2,1.5c-0.3,0.6-0.3,1.7-0.3,2.9v6.9 C150.7,53.1,148.3,53.1,148.3,53.1z',
      },

      {
        letter: 'm',
        key: 6,
        path:
        'M179.2,48.7l2.4,0.3c-0.3,1.4-1.1,2.4-2.1,3.2c-1.1,0.8-2.3,1.2-3.9,1.2c-2,0-3.5-0.6-4.7-1.8 c-1.2-1.2-1.8-2.9-1.8-5.1s0.6-4.1,1.8-5.3c1.2-1.2,2.7-1.8,4.5-1.8s3.3,0.6,4.4,1.8c1.1,1.2,1.7,3,1.7,5.1c0,0.2,0,0.3,0,0.6 h-10.1c0.2,1.5,0.5,2.6,1.2,3.5c0.8,0.8,1.7,1.2,2.9,1.2c0.9,0,1.5-0.2,2.1-0.6C178.4,50.4,178.9,49.8,179.2,48.7z M171.6,45.1h7.5 c-0.2-1.2-0.5-2-0.9-2.6c-0.8-0.9-1.7-1.4-2.9-1.4c-1.1,0-2,0.3-2.7,1.1C171.9,43,171.8,43.9,171.6,45.1z',
      },

      {
        letter: 'e',
        key: 7,
        path:
        'M184.9,53.1V39.5h2.1v2.1c0.5-0.9,1.1-1.7,1.5-2c0.5-0.3,0.9-0.5,1.5-0.5c0.8,0,1.5,0.3,2.4,0.8l-0.8,2.1 c-0.6-0.3-1.1-0.5-1.7-0.5c-0.5,0-0.9,0.2-1.4,0.5c-0.5,0.3-0.8,0.8-0.9,1.2c-0.3,0.8-0.5,1.7-0.5,2.7v7.1 C187.3,53.1,184.9,53.1,184.9,53.1z',
      },
      {
        letter: 'r',
        key: 8,
        path:
        'M194.7,53.1V34.4h2.3v18.7H194.7z',
      },

      {
        letter: 'l',
        key: 9,
        path:
        'M200.4,58.2l-0.3-2.1c0.5,0.2,0.9,0.2,1.4,0.2c0.5,0,0.9-0.2,1.2-0.3c0.3-0.2,0.6-0.5,0.8-0.8 c0.2-0.3,0.3-0.8,0.8-1.7c0-0.2,0.2-0.3,0.2-0.6l-5.1-13.6h2.4l2.9,7.8c0.3,1.1,0.8,2,0.9,3.2c0.3-1.1,0.6-2.1,0.9-3l2.9-7.8h2.3 l-5.1,13.7c-0.6,1.5-0.9,2.6-1.4,3c-0.5,0.8-0.9,1.4-1.4,1.7c-0.5,0.3-1.2,0.6-1.8,0.6C201.3,58.5,200.9,58.5,200.4,58.2z',
      },

      {
        letter: 'y',
        key: 10,
        path:
        'M222.4,53.1V34.4h2.4v16.4h9.2v2.3H222.4z',
      },
      {
        letter: 'L',
        key: 11,
        path:
        'M245.9,48.7l2.4,0.3c-0.3,1.4-1.1,2.4-2.1,3.2c-1.1,0.8-2.3,1.2-3.9,1.2c-2,0-3.5-0.6-4.7-1.8 c-1.2-1.2-1.8-2.9-1.8-5.1s0.6-4.1,1.8-5.3c1.2-1.2,2.7-1.8,4.5-1.8s3.3,0.6,4.4,1.8s1.7,3,1.7,5.1c0,0.2,0,0.3,0,0.6H238 c0.2,1.5,0.5,2.6,1.2,3.5c0.8,0.8,1.7,1.2,2.9,1.2c0.9,0,1.5-0.2,2.1-0.6C245.1,50.4,245.6,49.8,245.9,48.7z M238.5,45.1h7.5 c-0.2-1.2-0.5-2-0.9-2.6c-0.8-0.9-1.7-1.4-2.9-1.4c-1.1,0-2,0.3-2.7,1.1C238.8,43,238.5,43.9,238.5,45.1z' ,
      },
      {
        letter: 'e',
        key: 12,
        path:
        'M253.9,53.1l-5.1-13.6h2.4l2.9,8.1c0.3,0.9,0.6,1.8,0.9,2.7c0.2-0.8,0.5-1.5,0.9-2.6l3-8.3h2.4l-5.1,13.6 H253.9z',
      },

      {
        letter: 'v',
        key: 13,
        path:
        'M205.1,49.5l-3.4-9h1.6l1.9,5.4c0.2,0.6,0.4,1.2,0.6,1.8c0.1-0.5,0.3-1,0.6-1.7l2-5.5h1.6l-3.4,9H205.1z' ,
      },
      {
        letter: 'i',
        key: 14,
        path:
        'M263.6,37.1v-2.6h2.3v2.6H263.6z M263.6,53.1V39.5h2.3v13.6H263.6z' ,
      },
      {
        letter: 'a',
        key: 15,
        path:
        'M278.3,51.4c-0.9,0.8-1.7,1.2-2.4,1.5c-0.8,0.3-1.7,0.5-2.6,0.5c-1.5,0-2.6-0.3-3.5-1.1 c-0.8-0.8-1.2-1.7-1.2-2.7c0-0.6,0.2-1.2,0.5-1.8c0.3-0.6,0.8-1.1,1.2-1.4c0.5-0.3,1.1-0.6,1.7-0.8c0.5-0.2,1.1-0.3,2.1-0.3 c1.8-0.2,3.2-0.5,4.1-0.8c0-0.3,0-0.5,0-0.6c0-0.9-0.2-1.7-0.6-2c-0.6-0.5-1.5-0.8-2.6-0.8s-1.8,0.2-2.4,0.6 c-0.5,0.3-0.9,1.1-1.2,2l-2.3-0.3c0.2-0.9,0.6-1.7,1.1-2.3s1.2-1.1,2-1.4c0.9-0.3,2-0.5,3-0.5c1.2,0,2.1,0.2,2.9,0.5 c0.8,0.3,1.2,0.6,1.7,1.1c0.3,0.5,0.6,0.9,0.8,1.5c0.2,0.5,0.2,1.1,0.2,2.1v3c0,2.1,0,3.5,0.2,4.1c0.2,0.6,0.3,1.1,0.6,1.7h-2.4 C278.4,52.6,278.3,52,278.3,51.4z M278.1,46.3c-0.9,0.3-2.1,0.6-3.8,0.9c-0.9,0.2-1.7,0.3-2,0.5c-0.5,0.2-0.8,0.5-0.9,0.8 c-0.2,0.3-0.3,0.8-0.3,1.1c0,0.6,0.3,1.1,0.8,1.5c0.5,0.5,1.2,0.6,2,0.6c0.9,0,1.7-0.2,2.4-0.6c0.8-0.5,1.2-0.9,1.5-1.5 c0.3-0.5,0.3-1.2,0.3-2.3V46.3z' ,
      },
      {
        letter: 't',
        key: 16,
        path:
        'M289.4,51l0.3,2c-0.6,0.2-1.2,0.2-1.7,0.2c-0.9,0-1.5-0.2-2-0.5c-0.5-0.3-0.8-0.6-0.9-1.1 c-0.2-0.5-0.3-1.4-0.3-2.7V41h-1.7v-1.8h1.7v-3.3l2.3-1.4v4.7h2.3V41h-2.3v8.3c0,0.6,0,1.1,0.2,1.2c0.2,0.2,0.2,0.3,0.5,0.5 c0.2,0.2,0.5,0.2,0.8,0.2C288.6,51.1,288.9,51.1,289.4,51z',
      },
      {
        letter: 'h',
        key: 17,
        path:
        'M292.7,53.1V34.4h2.3V41c1.1-1.2,2.4-1.8,4.1-1.8c1.1,0,1.8,0.2,2.6,0.6c0.8,0.5,1.2,0.9,1.7,1.7 c0.5,0.8,0.5,1.7,0.5,3v8.6h-2.3v-8.6c0-1.2-0.3-2-0.8-2.6c-0.5-0.5-1.2-0.8-2.1-0.8c-0.6,0-1.4,0.2-2,0.6 c-0.6,0.5-1.1,0.8-1.2,1.4c-0.3,0.6-0.5,1.5-0.5,2.6v7.4C295,53.1,292.7,53.1,292.7,53.1z' ,
      },
      {
        letter: 'a',
        key: 17,
        path:
        'M315.4,51.4c-0.9,0.8-1.7,1.2-2.4,1.5c-0.8,0.3-1.7,0.5-2.6,0.5c-1.5,0-2.6-0.3-3.5-1.1 c-0.8-0.8-1.2-1.7-1.2-2.7c0-0.6,0.2-1.2,0.5-1.8c0.3-0.6,0.8-1.1,1.2-1.4c0.5-0.3,1.1-0.6,1.7-0.8c0.5-0.2,1.1-0.3,2.1-0.3 c1.8-0.2,3.2-0.5,4.1-0.8c0-0.3,0-0.5,0-0.6c0-0.9-0.2-1.7-0.6-2c-0.6-0.5-1.5-0.8-2.6-0.8s-1.8,0.2-2.4,0.6 c-0.5,0.3-0.9,1.1-1.2,2l-2.3-0.3c0.2-0.9,0.6-1.7,1.1-2.3c0.5-0.6,1.2-1.1,2-1.4c0.9-0.3,2-0.5,3-0.5c1.2,0,2.1,0.2,2.9,0.5 c0.8,0.3,1.2,0.6,1.7,1.1c0.3,0.5,0.6,0.9,0.8,1.5c0.2,0.5,0.2,1.1,0.2,2.1v3c0,2.1,0,3.5,0.2,4.1c0.2,0.6,0.3,1.1,0.6,1.7H316 C315.7,52.6,315.6,52,315.4,51.4z M315.3,46.3c-0.9,0.3-2.1,0.6-3.8,0.9c-0.9,0.2-1.7,0.3-2,0.5c-0.5,0.2-0.8,0.5-0.9,0.8 c-0.2,0.3-0.3,0.8-0.3,1.1c0,0.6,0.3,1.1,0.8,1.5c0.5,0.5,1.2,0.6,2,0.6c0.9,0,1.7-0.2,2.4-0.6c0.8-0.5,1.2-0.9,1.5-1.5 c0.3-0.5,0.3-1.2,0.3-2.3V46.3z' ,
      },
      {
        letter: 'n',
        key: 17,
        path:
        'M321.2,53.1V39.5h2.1v2c1.1-1.5,2.4-2.3,4.4-2.3c0.8,0,1.5,0.2,2.3,0.5c0.8,0.3,1.2,0.6,1.5,1.2 c0.3,0.5,0.6,1.1,0.8,1.7c0.2,0.5,0.2,1.2,0.2,2.3v8.3H330v-8.3c0-0.9-0.2-1.7-0.3-2.1c-0.2-0.5-0.5-0.9-0.9-1.1 c-0.5-0.3-1.1-0.5-1.7-0.5c-0.9,0-1.8,0.3-2.6,0.9c-0.8,0.6-1.1,1.8-1.1,3.5v7.4h-2.4V53.1z',
      },
    ];


    return (

      <LogoContainer
        className="lvthn-logo-container"
        onMouseEnter={() => this.handleHover(true)}
        onMouseLeave={() => this.handleHover(false)}
        onMouseDown={() => this.handleFocus(true)}
        onMouseUp={() => this.handleFocus(false)}
        onClick={this.props.handleClick}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 332.3 90">
          <g id="Layer_1" data-name="Layer 1">
            <g id="final_art" data-name="final art" fill="#fff">
              <Trail
                items={paths}
                keys={item => item.key}
                from={{ opacity: 0, transform: 'translate3d(0,0px,0)' }}
                to={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}
                config={{
                  tension: 140,
                  friction: 12,
                  mass: 1,
                  easing: easings.easeCubic,
                }}
              >
                {item => props => (
                  <path style={props} fill={fillColor} d={item.path} />
                )}
              </Trail>
            </g>
          </g>
        </svg>
      </LogoContainer>
    );
  }
}

export default Play;
