import React, { Component } from 'react';
import styled from 'styled-components';
import * as easings from 'd3-ease';
import { Spring } from 'react-spring/renderprops';
const StyledLoadingPage = styled.div`
  width: 100%;
  height: 100vh;
  background: black;
`;
export default class LoadingPage extends Component {
  render() {
    return <StyledLoadingPage />;
  }
}
