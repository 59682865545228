import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import InViewMonitor from 'react-inview-monitor';
import { animateScroll } from 'react-scroll';

const hover = keyframes` 
  0% {transform: scale(1); opacity: 1 }
  50%{transform: scale(0); opacity: 0 }
  100%{transform: scale(1); opacity: 1} 

`;

const coolBoxKeyframes = keyframes`
  0% {
    height: 0px;
    background: green;
  }
  100% {
    height: 200px;
    background: blue;
  }
`;

const CubeWrapper = styled.div`
  opacity: 0.4;
  position: absolute;
  z-index: 1;
  /* animation: ${hover} 30s infinite linear; */
  will-change: transform;
`;

const AuthStyled = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .cube {
    position: absolute;
    transform-style: preserve-3d;
    transform: rotateX(-20deg) rotateY(45deg);
  }

  .side {
    width: 500px;
    height: 500px;
    position: absolute;
  }

  .front {
    border: 2px solid #fff;
    transform: rotateY(0) translateZ(100px);
  }

  .top {
    border: 2px solid #cecccc;

    transform: rotateX(90deg) translateZ(100px);
    transition: 1s;
  }

  .bottom {
    border: 2px solid #e6e0e0;

    transform: rotateX(-90deg) translateZ(100px);
  }

  .left {
    border: 2px solid #d4d3d3;

    transform: rotateY(-90deg) translateZ(100px);
  }

  .right {
    border: 2px solid #a1a1a1;
    transform: rotateY(90deg) translateZ(100px);
  }

  .back {
    border: 2px solid #c4bfbf;
    transform: rotateY(180deg) translateZ(100px);
  }
`;

const Card = styled.div`
  background: rgba(255, 255, 255, 0.1);
  /* border: 2px solid white; */
  position: relative;
  z-index: 10;
  padding: 7em;
  text-align: center;
  h4 {
    margin-bottom: 2em;
  }

  button {
    display: block;
    background: white;
    margin: 0 auto;
    margin-top: 3em;
    color: black;
    padding: 0.25em 0.75em;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
      background: rgba(255, 255, 255, 0.8);
    }
  }

  input {
    letter-spacing: 1px;
    box-sizing: border-box;
    border-radius: 0px;
    font-size: 18px;
    background: transparent;
    color: white;
    padding: 0.25em 0.75em;
    border: 2px solid white;
    &:focus {
      outline: none;
    }
  }
`;

class Auth extends Component {
  state = {
    password: '',
  };
  componentDidMount() {
    window.addEventListener('keypress', e => this.handleKeyPress(e));
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', e => this.handleKeyPress(e));
  }

  handleKeyPress(e) {
    if (e.code === 'Enter') {
      this.handleSubmit();
    }
  }
  handleSubmit() {
    if (this.state.password === 'LVTHN2019') {
      this.props.authenticate();
    } else {
      alert(`Your password of ${this.state.password} was incorrect`);
    }
  }
  render() {
    return (
      <AuthStyled>
        {/* <CubeWrapper>
          <div className="cube">
            <div className="side front" />
            <div className="side back" />
            <div className="side right" />
            <div className="side left" />
            <div className="side top" />
            <div className="side bottom" />
          </div>
        </CubeWrapper> */}
        <Card>
          <h4>password</h4>
          <input
            onChange={e => this.setState({ password: e.target.value })}
            type="text"
            className="password"
          />
          <button onClick={() => this.handleSubmit()} className="submit">
            Submit
          </button>
        </Card>
      </AuthStyled>
    );
  }
}

export default Auth;
