import React, { Component } from 'react';
import styled from 'styled-components';
import InViewMonitor from 'react-inview-monitor';
import breakpoint from 'styled-components-breakpoint';
import { RichText } from 'prismic-reactjs';

const WhatWeBelieveItemWrapper = styled.div`
  /* margin: 25vh auto; */
  position: relative;
  ${breakpoint('xs')`
    margin-top: 40px;
  `}
  ${breakpoint('sm')`
    margin-top: 0px;
  `}

  .title {
    margin-bottom: 15px;
  }
`;

export default class WhatWeBelieveTextItem extends Component {
  render() {
    const { believe_title, believe_body } = this.props.data.primary;
    return (
      <WhatWeBelieveItemWrapper>
        <InViewMonitor
          classNameNotInView="animated fadeOutUpBlur"
          classNameInView="animated fadeInUp"
        >
          <h2 className="title">{RichText.render(believe_title)}</h2>
        </InViewMonitor>
        <InViewMonitor
          classNameNotInView="animated fadeOutUp"
          classNameInView="animated fadeInUp"
        >
          <div className="body">{RichText.render(believe_body)}</div>
        </InViewMonitor>
      </WhatWeBelieveItemWrapper>
    );
  }
}
