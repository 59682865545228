import React, { Component } from 'react';
import Prismic from 'prismic-javascript';
import { Helmet } from 'react-helmet';
import { RichText } from 'prismic-reactjs';
import NotFound from '../NotFound/NotFound';
import styled from 'styled-components';
import InViewMonitor from 'react-inview-monitor';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import breakpoint from 'styled-components-breakpoint';
import LoadingPage from '../LoadingPage/LoadingPage';
import './Contact.css';

import { animateScroll } from 'react-scroll';

const HeroImageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const HeroGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  ${breakpoint('xs')`
    height: 50vh;
    transform: translateY(0%) scale(1);
  `}

  ${breakpoint('md')`
    height: 100vh;
  transform: translateY(-10%) scale(1.2);

  `}
`;

const Container = styled.div`
  ${breakpoint('xs')`
    padding-bottom: 50px;
  `}
`;

const StyledLocationRow = styled(Row)`
  ${breakpoint('xs')`
     margin-bottom: 40px;
  `}

  ${breakpoint('md')`
     margin-bottom: 120px;
  `}
`;

const HeroImageStyled = styled.img`
  width: 100%;
  z-index: -1;
  object-fit: cover;

  ${breakpoint('xs')`
    height: 50vh;
    transform: translateY(0%) scale(1);
  `}

  ${breakpoint('md')`
    height: 100vh;
    transform: translateY(-10%) scale(1.2);
  `}
`;

const InfoWrapper = styled.div`
  ${breakpoint('xs')`
      margin: 80px 0 100px;
  `}

  ${breakpoint('md')`
     margin: 200px 0 320px;
  `}
`;

const ContactHeader = styled.div`
  position: relative;
`;

const TitleOverlay = styled.div`
  ${breakpoint('xs')`
    margin: 150px auto 75px;
  `}

  ${breakpoint('md')`
    margin: 150px auto;
  `}

  ${breakpoint('md')`
    position: absolute;
    top: 40%;
    z-index: 10;
  `}
`;

const ContactBucketWrapper = styled.div`
.bucket-description {
  h4 {
    margin: 9px 0px;
    
      a {
      ${breakpoint('xs')`
        font-size: 16px !important;
      `}

      ${breakpoint('md')`
        font-size: 16px !important;
      `}

      ${breakpoint('lg')`
        font-size: 20px !important;
      `}

      ${breakpoint('xl')`
        font-size: 22px !important;
      `}
      &:hover {
        &::after,
        &::before {
          width: 0;
          right: -2px;
        }
      }

      &::after,
      &::before {
        content: "";
        position: absolute;
        top: 100%;
        transform: translateY(-50%);
        width: 100%;
        left: 0;
        height: 1px;
        pointer-events: none;
      }

      &::before {
        transition: width 0.4s cubic-bezier(0.51, 0.18, 0, 0.88) 0.1s;
        background: #0F0F0F;
      }

      &::after {
        transition: width 0.2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
        background: #FFF;
      }
    }
  
  }
}
margin: 5em 0;
  .header {
    a {

    ${breakpoint('xs')`
      font-size: 16px !important;
    `}
    
    ${breakpoint('md')`
      font-size: 16px !important;
    `}
    
    ${breakpoint('lg')`
      font-size: 20px !important;
    `}

    ${breakpoint('xl')`
      font-size: 22px !important;
    `}
  }

    }
`;

const StyledLocation = styled.div`
  margin-top: 1rem;
`;

const HeroImageGradient = styled.div`
  ${breakpoint('md')`
    height: 100vh;
    width: 100%;
    position: absolute;
    left: 0;
    background: -webkit-linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
  `}
`;

const SectionTitle = styled.h2`
  ${breakpoint('xs')`
     margin-bottom: 40px;
  `}

  ${breakpoint('md')`
     margin-bottom: 120px;
  `}
`;

export default class Contact extends Component {
  state = {
    doc: null,
    notFound: false,
  };

  componentWillMount() {
    this.fetchPage(this.props);
  }

  componentWillReceiveProps(props) {
    this.fetchPage(props);
  }

  componentDidMount() {
    animateScroll.scrollToTop({
      duration: 0,
      delay: 750,
    });
  }

  async fetchPage(props) {
    if (props.prismicCtx) {
      const res = await props.prismicCtx.api.query(
        Prismic.Predicates.at('document.type', 'contact')
      );
      this.setState({ doc: res.results[0] });
    }
    return null;
  }

  renderInfo() {
    return this.state.doc.data.body
      .filter(item => item.slice_type === 'contact_buckets')
      .map((item, index) => <ContactBucket key={index} data={item} />);
  }

  renderLocations() {
    return this.state.doc.data.body
      .filter(item => item.slice_type === 'location')
      .map((item, index) => <Location key={index} data={item} />);
  }

  render() {
    const { doc } = this.state;
    if (doc) {
      return (
        <>
          <Helmet>
            <title>Contact</title>
          </Helmet>
          <ContactHeader>
            <HeroImageGradient />

            <Grid>
              <Row middle="xs" between="xs">
                <Col xs={12}>
                  <TitleOverlay>
                    <InViewMonitor
                      classNameNotInView="animated fadeOutUp"
                      classNameInView="animated fadeInUp delay-2"
                    >
                      <h1>{RichText.render(doc.data.contact_header)}</h1>
                    </InViewMonitor>
                  </TitleOverlay>
                </Col>
              </Row>
            </Grid>
            <InViewMonitor
              classNameNotInView="animated fadeOutUp"
              classNameInView="animated fadeInUp delay-1"
            >
              <HeroImageWrapper>
                <HeroImageStyled src={doc.data.contact_image.url} alt="" />
                <HeroGradient />
              </HeroImageWrapper>
            </InViewMonitor>
          </ContactHeader>

          <Container>
            <Grid>
              <InfoWrapper>{this.renderInfo()}</InfoWrapper>

              <Row>
                <Col xs={false} md={1} />
                <Col xs={12} md={10}>
                  <InViewMonitor
                    classNameNotInView="animated fadeOutUp"
                    classNameInView="animated fadeInUp"
                  >
                    <SectionTitle>Locations</SectionTitle>
                  </InViewMonitor>
                </Col>
                <Col xs={false} md={1} />
              </Row>

              <StyledLocationRow>
                <Col xs={false} md={1} />
                <Col xs={12} md={10}>
                  <Row between="xs">{this.renderLocations()}</Row>
                </Col>
                <Col xs={false} md={1} />
              </StyledLocationRow>
            </Grid>
          </Container>
        </>
      );
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return <LoadingPage />;
  }
}

function ContactBucket(props) {
  return (
    <InViewMonitor
      classNameNotInView="animated fadeOutUp"
      classNameInView="animated fadeInUp"
    >
      <ContactBucketWrapper>
        <Row>
          <Col xs={false} sm={2} />
          <Col xs={12} sm={5}>
            {RichText.render(props.data.primary.contact_bucket_title)}
          </Col>
          <Col className="bucket-description" xs={12} sm={3}>
            {RichText.render(props.data.primary.contact_bucket_description)}
          </Col>
          <Col xs={false} sm={2} />
        </Row>
      </ContactBucketWrapper>
    </InViewMonitor>
  );
}

function Location(props) {
  return (
    <Col>
      <InViewMonitor
        classNameNotInView="animated fadeOutUp"
        classNameInView="animated fadeInUp"
      >
        <StyledLocation>
          {RichText.render(props.data.primary.location_city)}
          {RichText.render(props.data.primary.location_address)}
        </StyledLocation>
      </InViewMonitor>
    </Col>
  );
}
