import React, { useState } from 'react';
import styled from 'styled-components';
import VideoContext from '../../framework/VideoContext';
import breakpoint from 'styled-components-breakpoint';

const PlayWrapper = styled.div`
  transform: rotate(-90deg);
  ${breakpoint('xs')`
    transform: rotate(-90deg) scale(0.5);
  `}
  ${breakpoint('sm')`
    transform: rotate(-90deg) scale(0.8);
  `}
  ${breakpoint('md')`
    transform: rotate(-90deg);
  `}
  cursor: pointer;
  #circle {
    transition: all 0.4s;
  }
`;

function Play() {
  const [mouseOver, isMouseOver] = useState(false);

  function handleHover(val) {
    isMouseOver(val);
  }

  const VideoState = VideoContext.useContainer();

  const dashOffset = mouseOver ? -247 : 0;

  return (
    <PlayWrapper
      className="PlayIcon-container"
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      onClick={() => {
        VideoState.updateIsPlaying(true);
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="136.808"
        height="136.808"
        viewBox="0 0 136.808 136.808"
      >
        <defs>
          <radialGradient
            id="a"
            cx="0.5"
            cy="0.5"
            r="0.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" />
            <stop offset="1" stopOpacity="0" />
          </radialGradient>
        </defs>
        <circle
          cx="68.404"
          cy="68.404"
          r="68.404"
          opacity="0.3"
          fill="url(#a)"
          className="shadow"
        />
        <circle
          cx="39.356"
          cy="39.356"
          r="39.356"
          transform="translate(29.048 29.048)"
          fill="none"
          stroke="rgba(255,255,255,0.4)"
          strokeWidth="2"
          id="opacity-circle"
        />
        <circle
          cx="39.356"
          cy="39.356"
          r="39.356"
          transform="translate(29.048 29.048)"
          fill="none"
          stroke="rgba(255,255,255,1)"
          strokeWidth="2"
          id="circle"
          strokeDashoffset={dashOffset}
          strokeDasharray="3339.292"
        />

        <path
          d="M10.148,0l3.065,13.226L0,10.616Z"
          transform="translate(58.247 72.304) rotate(-75)"
          fill="#fff"
          className="play"
        />
      </svg>
    </PlayWrapper>
  );
}

export default Play;
