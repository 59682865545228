import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import InViewMonitor from 'react-inview-monitor';
import breakpoint from 'styled-components-breakpoint';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';

const WorkStatementStyled = styled.div`
  margin: 115px auto;
  ${breakpoint('xs')`
    margin: 75px auto;
  `}
  ${breakpoint('sm')`
    margin: 115px auto;
  `}
  h3 {
    margin-bottom: 50px;
  }
`;

export default class WorkStatement extends Component {
  state = {};

  static propTypes = {
    data: PropTypes.shape({
      work_statement_detail: PropTypes.array,
      work_statement_title: PropTypes.array,
    }),
  };

  render() {
    const { work_statement_detail, work_statement_title } = this.props.data;
    return (
      <Grid>
        <Row>
          <Col xs={1} sm={2} />
          <Col xs={10} sm={8}>
            <WorkStatementStyled>
              <InViewMonitor
                classNameNotInView="animated fadeOutUpBlur"
                classNameInView="animated fadeInUp"
              >
                <h3>
                  {work_statement_title[0] && work_statement_title[0].text}
                </h3>
              </InViewMonitor>

              <InViewMonitor
                classNameNotInView="animated fadeOutUp"
                classNameInView="animated fadeInUp delay-1"
              >
                {RichText.render(work_statement_detail)}
              </InViewMonitor>
            </WorkStatementStyled>
          </Col>
          <Col xs={1} sm={2} />
        </Row>
      </Grid>
    );
  }
}
