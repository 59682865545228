import React, { useEffect, useState } from 'react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import InViewMonitor from 'react-inview-monitor';

import { RichText } from 'prismic-reactjs';

import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import DragCursor from '../../assets/DragCursor.svg';

const HorizontalScrollWrapper = styled.div`
  margin-bottom: 300px;
  overflow-x: hidden;
  width: 100vw;
  h3 {
    margin-bottom: 40px;
  }
  p {
    max-width: 560px;
  }
`;

const CarouelWrapper = styled.div`
  cursor: url(${DragCursor}), auto;

  .BrainhubCarousel__dots {
    margin-top: 20px;
    margin-left: 20px;
  }
  .BrainhubCarousel__dot {
    margin-top: 10px;
    color: black;
    text-align: center;
    &::before {
      content: '-';
      background: white !important;
      color: white !important;
      border-radius: 0px !important;
      height: 3px !important;
      width: 7px !important;
    }
  }
`;

const QuoteStyled = styled.div`
  color: white;
  background: #0f0f0f;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  &:first-of-type {
    margin-left: 0;
  }

  h4 {
    margin-bottom: 36px;
  }

  ${breakpoint('xs')`
    height: 38vw;
    width: 50vw;
  padding: 1em;
  

  `}
${breakpoint('sm')`
    height: 31vw;
    width: 56vw;
  padding: 1em;

  `}
  ${breakpoint('lg')`
   height: 21vw;
  width: 33vw;


  `}
    ${breakpoint('xl')`
   height: 17vw;
  width: 33vw;
  padding: 3em;
    max-height: 380px;


  `}
`;

const StyledCarousel = styled(Carousel)`
  margin-top: 61px;
  display: flex;
  li {
    justify-content: flex-start;
  }
  li {
    &:first-of-type {
      padding-left: 0px !important;
    }
  }
`;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const HorizontalQuotes = props => {
  const { data } = props;

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getNumSlides = () => {
    if (windowDimensions.width < 720) {
      return 2;
    }
    if (windowDimensions.width < 900) {
      return 2;
    } else if (windowDimensions.width > 900 && windowDimensions.width < 1600) {
      return 3;
    } else if (windowDimensions.width > 1600) {
      return 4;
    }
  };
  const renderData = () => {
    return data.items.map((quote, index) => {
      return (
        <QuoteStyled key={index} index={index}>
          {RichText.render(quote.quote)}
        </QuoteStyled>
      );
    });
  };
  if (windowDimensions.width < 720) {
    return null;
  }
  return (
    <HorizontalScrollWrapper>
      <InViewMonitor
        classNameNotInView="animated fadeOutUp"
        classNameInView="animated fadeInUp delay-1"
      >
        <Grid>
          <Row>
            <Col xs={1} />

            <Col xs={10}>
              {RichText.render(data.primary.title)}
              {data.primary.description &&
                RichText.render(data.primary.description)}
            </Col>
          </Row>
        </Grid>
      </InViewMonitor>
      <InViewMonitor
        classNameNotInView="animated fadeOutUp"
        classNameInView="animated fadeInUp"
      >
        <Grid>
          <Row>
            <Col xs={false} sm={1} />
            <Col xs={12} sm={11}>
              <CarouelWrapper style={{ width: '100vw' }}>
                <StyledCarousel offset={20} slidesPerPage={getNumSlides()}>
                  {renderData()}
                </StyledCarousel>
              </CarouelWrapper>
            </Col>
          </Row>
        </Grid>
      </InViewMonitor>
    </HorizontalScrollWrapper>
  );
};

export default HorizontalQuotes;
