import { Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import FloatingLabelInput from 'react-floating-label-input';
import { Link } from 'react-scroll';
export const StyledLink = styled(Link)`
  padding: 0.25em 0.75em;
  border: 1px solid white;
  opacity: 0.6;
  transition: opacity 0.25s;
  position: absolute;
  z-index: 100;
  top: 2.5%;
  ${breakpoint('xs')`
  top: 2.5%;
  right: 12%;
  `}
    ${breakpoint('md')`
  top: 3%;
  right: 19%;
  `}
    ${breakpoint('lg')`
  top: 3.5%;
  right: 14%;
  `}
  ${breakpoint('lg')`
  top: 3%;
  right: auto;
  `}

  &:hover {
    opacity: 1;
  }
`;

export const FormWrapper = styled.div`
  background: #0f0f0f;
  padding: 8em;
  ${breakpoint('xs')`
  padding: 5em 2em;
  `}

  ${breakpoint('md')`
    padding: 8em;
  `}
  .apply {
    margin-bottom: 50px;
  }
`;

export const LinkStyled = styled.a`
  padding-bottom: 10px;
  margin-top: 60px;
  color: white;
  padding-right: 10px;
  ${breakpoint('xs')`
    font-size: 11px;
    letter-spacing: .02em;
  `}
  
  ${breakpoint('md')`
    font-size: 12px;
  `}
  
  ${breakpoint('lg')`
    font-size: 13px;
    letter-spacing: .05em;
  `}

  ${breakpoint('xl')`
    font-size: 15px;
  `}
`;

export const LinkStyledSubmit = styled.a`
  padding-bottom: 20px;
  margin-top: 100px;
  color: white;
  padding-right: 10px;
  ${breakpoint('xs')`
    font-size: 14px;
    letter-spacing: .02em;
  `}
  
  ${breakpoint('md')`
    font-size: 18px;
  `}
  
  ${breakpoint('lg')`
    font-size: 24px;
    letter-spacing: .05em;
  `}

  ${breakpoint('xl')`
    font-size: 30px;
  `}
`;

export const StyledCareerPost = styled.div`
  position: relative;
  
  ${breakpoint('xs')`
      margin-top: -75px;
      margin-bottom: 75px;

    `}
    
    ${breakpoint('md')`
      margin-top: -123px;
      margin-bottom: 123px;

    `}
    
    ${breakpoint('lg')`
      margin-top: -135px;
      margin-bottom: 135px;

    `}

    ${breakpoint('xl')`
       margin-top: -162px;
      margin-bottom: 162px;

    `}

  h4 {
    &:first-of-type {
      margin-top: 0px;
    }
   
    font-weight: 100;
    color: rgba(255,255,255,1);
    letter-spacing: .025em;
    line-height: 1.5;
    
    ${breakpoint('xs')`
      font-size: 14px;
      margin-top: 50px;

    `}
     ${breakpoint('sm')`
      font-size: 14px;

    `}
    
    ${breakpoint('md')`
      font-size: 18px;
      margin-top: 75px;
    `}
    
    ${breakpoint('lg')`
      font-size: 24px;
    `}

    ${breakpoint('xl')`
      font-size: 30px;
    `}
  }

  p:empty {
    display: none;
  }

  ul {
    display: block;
    list-style-type: square;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    &:last-of-type {

      ${breakpoint('xs')`
      margin-bottom: 50px;

    `}
  
    
    ${breakpoint('md')`
      margin-bottom: 75px;
    `}
    }
  }
  li {
    margin: 10px 0;
    padding-left: 10px;
  }

  .link-wrapper {
    margin-top: 50px;
    position: sticky;
    height: 500px;
    top: 50px;
  }
`;

export const FromFieldWrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FloatingLabelWrap = styled(FloatingLabelInput)`
  border: 0;
  input {
    border-bottom: 2px solid white;
    color: white;
  }
  label {
    border: 0;
    color: white;
    opacity: 0.6;
    top: 7px;
  }
  background: transparent;
  &:focus {
    outline: 0;
    box-shadow: 0;
  }
`;

export const Spacer = styled.div`
  height: 50px;
`;

export const LeftInfoWrapper = styled(Col)`
  h5 {
    margin-bottom: 20px;
  }
  h6 {
    margin-bottom: 10px;
  }
`;

export const FileUploadWrapper = styled.div`
  display: flex;
  a {
    margin-right: 50px;
  }
`;

export const FileList = styled.h6`
  font-size: 11px;
  max-width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
  background: black;
  margin-top: 10px;
  padding: 0.25em 0.5em;
`;
