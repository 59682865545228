import React from 'react';
import breakpoint from 'styled-components-breakpoint';
import InViewMonitor from 'react-inview-monitor';
import notFoundImage from '../../assets/404.jpg';
import { Link } from 'react-router-dom';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';

import styled from 'styled-components';

const NotFoundHeader = styled.div`
  position: relative;
  height: 100vh;

  .has-escaped {
    margin-top: 10px;
  }
`;
const HeroImageStyled = styled.img`
  width: 100vw;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.5;
`;

const TitleOverlay = styled.div``;

const ContentWrapper = styled.div`
  padding-top: 50%;
  ${breakpoint('sm')`
    padding-top: 30%;
  `}
  ${breakpoint('lg')`
    padding-top: 20%;
  `}
   ${breakpoint('xl')`
    padding-top: 15%;
  `}
`;

const SubTitleOverlay = styled.div`
  margin-top: 50px;
  ${breakpoint('xs')`
    margin-top: 30px;
  `}
  ${breakpoint('sm')`
    margin-top: 50px;
  `}
`;

const LinkStyled = styled(Link)`
  padding-bottom: 10px;
  margin-top: 70px;
  ${breakpoint('xs')`
    font-size: 12px;
    letter-spacing: .02em;
    margin-top: 40px;
  `}
  
  ${breakpoint('md')`
    font-size: 13px;
  margin-top: 70px;
  `}
  
  ${breakpoint('lg')`
    font-size: 14px;
    letter-spacing: .05em;
  `}

  ${breakpoint('xl')`
    font-size: 16px;
  `}
`;

export default function NotFound() {
  return (
    <NotFoundHeader>
      <HeroImageStyled src={notFoundImage} alt="" />
      <ContentWrapper>
        <Grid>
          <Row middle="sm" between="sm">
            <Col xs={12}>
              <InViewMonitor
                classNameNotInView="animated fadeOutUp"
                classNameInView="animated fadeInUp"
              >
                <h1>This page</h1>
              </InViewMonitor>
            </Col>
          </Row>
          <Row middle="sm" between="sm">
            <Col xs={12}>
              <InViewMonitor
                classNameNotInView="animated fadeOutUp"
                classNameInView="animated fadeInUp"
              >
                <h1 className="has-escaped">
                  has <strong>escaped</strong>{' '}
                </h1>
              </InViewMonitor>
            </Col>
          </Row>
          <Row middle="sm" between="sm">
            <Col xs={12}>
              <SubTitleOverlay>
                <InViewMonitor
                  classNameNotInView="animated fadeOutUp"
                  classNameInView="animated fadeInUp"
                >
                  <h4>Let us know if you find it </h4>
                </InViewMonitor>
              </SubTitleOverlay>
            </Col>
          </Row>
          <InViewMonitor
            classNameNotInView="animated fadeOutUp"
            classNameInView="animated fadeInUp"
          >
            <Row>
              <Col xs={12} sm={10} md={8}>
                <LinkStyled className="underline" to="/">
                  Back to home page
                </LinkStyled>
              </Col>
              <Col xs={1} sm={1} md={2} />
            </Row>
          </InViewMonitor>
        </Grid>
      </ContentWrapper>
    </NotFoundHeader>
  );
}
