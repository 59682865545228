import React, { Component } from 'react';
import InViewMonitor from 'react-inview-monitor';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -20px;
  @media (max-width: 920px) {
    display: none;
  }
  button {
    margin-right: 20px;
  }
`;
const ButtonStyled = styled.button``;

const StyledA = styled.a`
  white-space: nowrap;
  cursor: pointer;
  opacity: ${props => (props.active ? 1 : 0.6)};
  transition: opacity 0.2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
  font-family: 'Gotham', sans-serif;
  &:hover {
    opacity: 1;
    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 100%;
      transform: translateY(-50%);
      width: 100%;
      left: 0;
      height: 2px;
      background: rgba(255, 255, 255, 0.6);
      pointer-events: none;
    }
  }

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 100%;
    transform: translateY(-50%);
    width: ${props => (props.active ? '100%' : '0%')};
    left: 0;
    height: 2px;
    background: rgba(255, 255, 255, 0.6);
    pointer-events: none;
  }

  &::before {
    transition: width 0.4s cubic-bezier(0.51, 0.18, 0, 0.88) 0.1s;
    background: #0f0f0f;
  }

  &::after {
    transition: width 0.2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
    background: #fff;
  }
  ${breakpoint('md')`
      font-size: 13px !important;
      `}
  ${breakpoint('lg')`
          font-size: 16px !important;
        `}
`;

class Filter extends Component {
  state = {
    activeFilter: 'all',
  };

  setActiveFilter = filter => {
    this.props.setActiveFilter(filter);
    this.setState({ activeFilter: filter });
  };
  render() {
    const { activeFilter } = this.state;
    return (
      <InViewMonitor
        classNameNotInView="animated fadeOutUp"
        classNameInView="animated delay-1 fadeInUp"
        intoViewMargin="10%"
      >
        <FilterWrapper>
          <ButtonStyled
            onClick={() => this.setActiveFilter('all')}
            active={activeFilter === 'all'}
            className="filterLink"
          >
            <h6>
              <StyledA active={activeFilter === 'all'}>All</StyledA>
            </h6>
          </ButtonStyled>
          <ButtonStyled
            active={activeFilter === 'branded-environments'}
            className="filterLink"
            onClick={() => this.setActiveFilter('branded-environments')}
          >
            <h6>
              <StyledA active={activeFilter === 'branded-environments'}>
                Branded Environments
              </StyledA>
            </h6>
          </ButtonStyled>
          <ButtonStyled
            active={activeFilter === 'themed-entertainment'}
            className="filterLink"
            onClick={() => this.setActiveFilter('themed-entertainment')}
          >
            <h6>
              <StyledA active={activeFilter === 'themed-entertainment'}>
                Themed Entertainment
              </StyledA>
            </h6>
          </ButtonStyled>
          <ButtonStyled
            active={activeFilter === 'events'}
            className="filterLink"
            onClick={() => this.setActiveFilter('events')}
          >
            <h6>
              <StyledA active={activeFilter === 'events'}>Events</StyledA>
            </h6>
          </ButtonStyled>
          <ButtonStyled
            active={activeFilter === 'public-spaces'}
            className="filterLink"
            onClick={() => this.setActiveFilter('public-spaces')}
          >
            <h6>
              <StyledA active={activeFilter === 'public-spaces'}>
                Public Spaces
              </StyledA>
            </h6>
          </ButtonStyled>
        </FilterWrapper>
      </InViewMonitor>
    );
  }
}

export default Filter;
