import React, { Component } from 'react';
import NotFound from '../NotFound/NotFound';
import { Keyframes, animated } from 'react-spring/renderprops.cjs';
import { Helmet } from 'react-helmet';
import Prismic from 'prismic-javascript';
import LoadingPage from '../LoadingPage/LoadingPage';
import WorkListItem from './components/WorkListItem';
import Filter from './components/Filter';
import uuid4 from 'uuid/v4';
import { RichText } from 'prismic-reactjs';
import { kebabCase } from 'lodash';
import styled from 'styled-components';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { animateScroll } from 'react-scroll';
import breakpoint from 'styled-components-breakpoint';
import InViewMonitor from 'react-inview-monitor';

const WorkListTitle = styled.div`
  ${breakpoint('xs')`
    margin: 8rem 0 2rem;
  `}

  ${breakpoint('md')`
    margin: 10rem 0 6rem;
  `}

  h2 {
    color: rgba(255, 255, 255, 0.6);
    strong {
      color: white;
    }
  }
`;

const Content = Keyframes.Trail({
  peek: {
    transform: 'translate3d(0px, 0px, 0px)',
    from: {
      transform: 'translate3d(0px, 30px. 0px)',
    },
  },
  nothing: { opacity: 1 },
  from: { opacity: 1 },
});

class WorkList extends Component {
  state = {
    doc: null,
    notFound: false,
    activeFilter: 'all',
    animate: true,
  };
  '';
  componentWillMount() {
    this.fetchPage(this.props);
  }

  componentDidMount() {
    animateScroll.scrollToTop({
      duration: 0,
    });
    this.setState({ animate: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.activeFilter !== prevState.activeFilter) {
      this.setState({ animate: true }, () => {
        this.setState({ animate: false });
      });
    }
  }

  componentWillReceiveProps(props) {
    this.fetchPage(props);
  }

  setActiveFilter = filter => {
    this.setState({ activeFilter: filter });
  };

  async fetchPage(props) {
    if (props.prismicCtx) {
      const res = await props.prismicCtx.api.query(
        Prismic.Predicates.at('document.type', 'work_list')
      );
      this.setState({ doc: res.results[0] });
    }
    return null;
  }

  renderWorkListItems() {
    const { doc, activeFilter } = this.state;
    if (activeFilter !== 'all') {
      const filteredWorkItems = doc.data.body.filter(item => {
        const itemType = kebabCase(item.primary.type[0].text);
        return itemType === activeFilter;
      });
      return filteredWorkItems.map((item, index) => (
        <WorkListItem key={uuid4()} data={item} />
      ));
    }

    return doc.data.body.map((item, index) => (
      <WorkListItem key={uuid4()} data={item} />
    ));
  }

  render() {
    const { doc } = this.state;
    const animState = this.state.animate ? 'peek' : 'nothing';
    if (doc) {
      const workListItems = this.renderWorkListItems();
      return (
        <React.Fragment>
          <Helmet>
            <title>Leviathan</title>
          </Helmet>
          <div style={{ width: '100vw' }}>
            <Grid>
              <Row>
                <InViewMonitor
                  classNameNotInView="animated fadeOutUp"
                  classNameInView="animated fadeInUp delay"
                >
                  <Col xs={12}>
                    <WorkListTitle>
                      {RichText.render(doc.data.work_list_title)}
                    </WorkListTitle>
                  </Col>
                </InViewMonitor>
              </Row>
              <Filter setActiveFilter={this.setActiveFilter} />
              <Content
                native
                config={{ mass: 1, tesnsion: 251, friction: 100 }}
                items={workListItems}
                state={animState}
                keys={item => item.key}
              >
                {item => props => (
                  <animated.div style={props}>{item}</animated.div>
                )}
              </Content>
            </Grid>
          </div>
        </React.Fragment>
      );
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return <LoadingPage />;
  }
}

export default WorkList;
