import React, { useRef, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { Col } from 'react-styled-flexboxgrid';
import { Spring, animated } from 'react-spring/renderprops';
import * as easings from 'd3-ease';
import InViewMonitor from 'react-inview-monitor';
import breakpoint from 'styled-components-breakpoint';
import Play from '../../Components/Play';
import VideoContext from '../../../framework/VideoContext';

const HeroVideoStyled = styled.div`
  margin-top: ${props => (props.isPlaying ? '90px' : '90px')};
  width: 100vw;
  position: relative;
  z-index: 1000;

  img {
    object-fit: cover;
    transition: 0.5s;
    height: 100%;
    width: 100%;
  }
  &:after {
    position: absolute;
    content: '';
    width: 100%;
    top: 0;
    left: 0;
  }
`;

const LoopingVidWrapper = styled.div`
  max-height: 500px;
  width: 100%;
  overflow: hidden;
  video {
    ${breakpoint('lg')`
      transform: translateY(-16%);
    `}
    ${breakpoint('xl')`
      transform: translateY(-18%);
    `}
    @media(min-width: 1800px) {
      transform: translateY(-31%);
    }
  }
`;

const loopingVidStyles = {
  objectFit: 'cover',
};

const ButtonStyled = styled.div`
  transition: opacity 0.5s;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  button {
    background: none;
    border: none;
    cursor: pointer;
    transition: 1s var(--util-ease);
    opacity: 1;

    &:hover {
      opacity: 0.5;
      transform: scale(0.975);
    }
  }
  img {
    ${breakpoint('xs')`
      width: 100px;
      height: 100px;
    `}

    ${breakpoint('md')`
      width: 146px;
      height: 146px;
      margin: 0;
    `}
  }
`;

export default function HeroVideo(props) {
  const player = useRef(player);
  // eslint-disable-next-line no-unused-vars
  const [isPlaying, setIsPlaying] = useState(false);
  const [playerIsReady, setPlayerIsReady] = useState(false);

  const VideoState = VideoContext.useContainer();
  const { work_detail_video_image, work_detail_video, hero_video } = props.data;

  useEffect(() => {
    VideoState.updateUrl(work_detail_video.embed_url);
  }, []);
  return (
    <InViewMonitor
      classNameNotInView="animated fadeOutUp"
      classNameInView="animated fadeInUp delay-3"
    >
      {!isPlaying && hero_video.url && (
        <HeroVideoStyled
          className="hero-vid-styled"
          isPlaying={isPlaying}
          style={{ display: playerIsReady ? 'block' : 'none' }}
        >
          <>
            <Spring
              native
              reset={!VideoState.isPlaying}
              from={{ opacity: 0, transform: 'translate3d(0, -40px, 0)' }}
              to={{ opacity: 1, transform: 'translate3d(0, 0px, 0)' }}
              config={{
                tension: 180,
                friction: 50,
                easing: easings.easeCubic,
              }}
            >
              {props => (
                <animated.div style={props}>
                  <LoopingVidWrapper>
                    <ReactPlayer
                      url={hero_video.url}
                      width={'100%'}
                      height={'100%'}
                      preload="true"
                      style={loopingVidStyles}
                      playing={!isPlaying}
                      onReady={() => setPlayerIsReady(true)}
                      loop={true}
                      muted={true}
                      playsinline={true}
                      volume={0}
                    />
                  </LoopingVidWrapper>
                </animated.div>
              )}
            </Spring>
            {work_detail_video.embed_url && playerIsReady && (
              <Col xs={12} md={2}>
                <ButtonStyled>
                  <Spring
                    native
                    delay={1000}
                    from={{
                      opacity: 0,
                      transform: 'translate3d(0, 40px, 0)',
                    }}
                    to={{ opacity: 1, transform: 'translate3d(0, 0px, 0)' }}
                    config={{
                      tension: 180,
                      friction: 100,
                      easing: easings.easeCubic,
                    }}
                  >
                    {props => (
                      <animated.div style={props}>
                        <Play
                          onClick={() => VideoState.updateIsPlaying(true)}
                        />
                      </animated.div>
                    )}
                  </Spring>
                </ButtonStyled>
              </Col>
            )}
          </>
        </HeroVideoStyled>
      )}
      {!isPlaying && (!hero_video.url || !playerIsReady) && (
        <HeroVideoStyled isPlaying={isPlaying}>
          <>
            <Spring
              native
              from={{ opacity: 0, transform: 'translate3d(0, -40px, 0)' }}
              to={{ opacity: 1, transform: 'translate3d(0, 0px, 0)' }}
              config={{
                tension: 180,
                friction: 100,
                easing: easings.easeCubic,
              }}
            >
              {props => (
                <animated.div style={props}>
                  <img alt="" src={work_detail_video_image.url} />
                  {work_detail_video.embed_url && (
                    <ButtonStyled className="button">
                      <Spring
                        native
                        delay={1000}
                        from={{
                          opacity: 0,
                          transform: 'translate3d(0, 40px, 0)',
                        }}
                        to={{ opacity: 1, transform: 'translate3d(0, 0px, 0)' }}
                        config={{
                          tension: 180,
                          friction: 100,
                          easing: easings.easeCubic,
                        }}
                      >
                        {props => (
                          <animated.div style={props}>
                            <Play
                              onClick={() => {
                                VideoState.updateIsPlaying(true);
                              }}
                              url={work_detail_video.embed_url}
                            />
                          </animated.div>
                        )}
                      </Spring>
                    </ButtonStyled>
                  )}
                </animated.div>
              )}
            </Spring>
          </>
        </HeroVideoStyled>
      )}
    </InViewMonitor>
  );
}
