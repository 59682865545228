import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import breakpoint from 'styled-components-breakpoint';
import { Spring, Trail, animated } from 'react-spring/renderprops';
import * as easings from 'd3-ease';
import VideoContext from '../../../framework/VideoContext';
import Play from '../../Components/Play';

const HeroReelStyled = styled.div`
  position: relative;
  width: 100vw;
`;

const ButtonStyled = styled.div`
  opacity: ${props => (props.showContent ? 1 : 0)};

  transition: opacity 0.5s;
  display: flex;
  align-items: center;
  button {
    background: transparent;
    border: none;
    cursor: pointer;
    transition: 1s var(--util-ease);
    opacity: 1;

    &:hover {
      opacity: 0.5;
      transform: scale(0.975);
    }
  }
  img {
    ${breakpoint('xs')`
      width: 100px;
      height: 100px;
    `}

    ${breakpoint('md')`
      width: 146px;
      height: 146px;
      margin: 0;
    `}
  }

  &:hover {
    ${breakpoint('md')`
      opacity: 1;
    `}
  }

  ${breakpoint('md')`
    margin: 0 auto;
    transform: translate(0%, 0%);
    width: 84px;
  `}
`;

const SmallButtonStyled = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0%, 50%);
  z-index: 1000;
  ${breakpoint('xs')`
  transform: translate(0%, 25%);
    
    `}
  ${breakpoint('sm')`
  transform: translate(0%, 50%);
    
    `}
`;

const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10;

  /* ${breakpoint('xs')`
    position: absolute;
    top: 54vh;
    transform: translateY(-50%);
  `} */

  ${breakpoint('sm')`
    position: absolute;
    top: 47%;
  `}
   ${breakpoint('md')`
    position: absolute;
    top: 73%;
  `}
`;

const HeroHeader = styled.h1`
  ${breakpoint('md')`
    opacity: ${props => (props.showContent ? 1 : 0)};
    transition: 1s;
  `}
`;

const SmallHeader = styled.h1`
  ${breakpoint('xs')`
      margin: 150px auto 41px ;
  
    `}
  ${breakpoint('sm')`
      margin: 150px auto 50px;
  
    `}
`;

const ContentWrapper = styled.div`
  opacity: ${props => (props.showContent ? 1 : 0)};
  transition: 1s;
  overflow: hidden;

  .image-holder {
    left: 0;
    top: 0;
    width: 100%;
    position: relative;
    background: rgba(0, 0, 0, 0.2);
    transition: 0.75s;
    display: ${props => (props.showContent ? 'block' : 'none')};

    &:after {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }

    ${breakpoint('xs')`
      margin:  0px auto 50px;
      position: relative;
    `}

    ${breakpoint('md')`
      margin: 0;
      height: 100vh;
    `}
  }

  video {
    height: 100%;
    width: 100vw;
    overflow: hidden;
    transition: 0.75s;

    ${breakpoint('md')`
       object-fit: cover;
    `}
  }

  ${breakpoint('md')`
    &:after {
      position: absolute;
      content: '';
      height: 110%;
      width: 100%;
      bottom: -5%;
      left: 0;
      background: linear-gradient(
          to left,
          rgba(0, 0, 0, 0) 50%,
          rgba(0, 0, 0, 0.4) 100%
        ),
        linear-gradient(to left, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
    }
  `}
`;

const VideoFlex = styled.div`
  position: relative;
`;

function HeroReel(props) {
  const [longReelIsPlaying, setLongReelIsPlaying] = useState(false);
  const playerRef = useRef(playerRef);
  const VideoState = VideoContext.useContainer();

  useEffect(() => {
    setLongReelIsPlaying(true);
    setLongReelIsPlaying(false);
  }, []);

  const { hero_reel, home_long_reel } = props.data;
  const IntroMessage = [
    <span key="1">Imagination</span>,
    <strong key="2"> Unleashed.</strong>,
  ];

  useEffect(() => {
    VideoState.updateUrl(home_long_reel.url);
  }, []);

  return (
    <HeroReelStyled className="hero-reel-styled">
      <Grid>
        <Row className="small-header">
          <Col xs={12} sm={false}>
            <SmallHeader
              className="small-header"
              showContent={!longReelIsPlaying}
            >
              <Trail
                delay={1500}
                native
                reset={true}
                items={IntroMessage}
                keys={item => item.key}
                from={{ opacity: 0, transform: 'translate3d(0, 40px, 0)' }}
                to={{ opacity: 1, transform: 'translate3d(0, 0px, 0)' }}
                config={{
                  tension: 180,
                  friction: 30,
                  easing: easings.easeCubic,
                }}
              >
                {item => props => (
                  <animated.div style={props} children={item} />
                )}
              </Trail>
            </SmallHeader>
          </Col>
        </Row>
      </Grid>
      <VideoFlex>
        {!longReelIsPlaying && (
          <Col xs={12} sm={false}>
            <Row center="xs">
              <SmallButtonStyled
                className="button-styled"
                showContent={!longReelIsPlaying}
              >
                <Play url={home_long_reel.url} />
              </SmallButtonStyled>
            </Row>
          </Col>
        )}
      </VideoFlex>

      <ContentWrapper showContent={!longReelIsPlaying}>
        <Spring
          native
          delay={2500}
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}
          config={{ tension: 180, friction: 30, easing: easings.easeCubic }}
        >
          {props => (
            <animated.div style={props}>
              <div className="image-holder">
                <ReactPlayer
                  ref={playerRef}
                  height="100%"
                  width="100%"
                  url={hero_reel.url}
                  loop={true}
                  onPause={() => playerRef.current.seekTo(0)}
                  playing={!longReelIsPlaying}
                  preload="true"
                  volume={0}
                  muted={true}
                  playsinline={true}
                />
              </div>
            </animated.div>
          )}
        </Spring>
      </ContentWrapper>

      <HeroOverlay className="hero-overlay">
        <Grid>
          <Row middle="xs" between="xs">
            <Col xs={false} sm={9}>
              <HeroHeader
                className="hero-header"
                showContent={!longReelIsPlaying}
              >
                <Trail
                  delay={1500}
                  native
                  items={IntroMessage}
                  keys={item => item.key}
                  from={{ opacity: 0, transform: 'translate3d(0, 40px, 0)' }}
                  to={{ opacity: 1, transform: 'translate3d(0, 0px, 0)' }}
                  config={{
                    tension: 180,
                    friction: 30,
                    easing: easings.easeCubic,
                  }}
                >
                  {item => props => (
                    <animated.div style={props} children={item} />
                  )}
                </Trail>
              </HeroHeader>
            </Col>
            <Col xs={false} sm={2}>
              <Row center="xs">
                <ButtonStyled
                  className="button-styled"
                  showContent={!longReelIsPlaying}
                >
                  <Spring
                    native
                    delay={1000}
                    reset
                    reverse={longReelIsPlaying}
                    from={{
                      opacity: 0,
                      transform: 'translate3d(0, 40px, 0)',
                    }}
                    to={{ opacity: 1, transform: 'translate3d(0, 0px, 0)' }}
                    config={{
                      tension: 180,
                      friction: 100,
                      easing: easings.easeCubic,
                    }}
                  >
                    {props => (
                      <animated.div style={props}>
                        <Play url={home_long_reel.url} />
                      </animated.div>
                    )}
                  </Spring>
                </ButtonStyled>
              </Row>
            </Col>
            <Col xs={false} md={1} />
          </Row>
        </Grid>
      </HeroOverlay>
    </HeroReelStyled>
  );
}

export default HeroReel;
