import React from 'react';
import styled from 'styled-components';
import InViewMonitor from 'react-inview-monitor';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import breakpoint from 'styled-components-breakpoint';

const ImageGridStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 15px;
  height: 100%;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    &:first-of-type {
      grid-row-start: 1;
      grid-row-end: ${props => (props.numOfImages > 2 ? 1 : 3)};
    }
    &:nth-of-type(2) {
      grid-row-start: 1;
      grid-row-end: ${props => (props.numOfImages > 3 ? 1 : 3)};
    }
  }
`;

const ImageGridContainer = styled.div`
  ${breakpoint('xs')`
    margin: 25px 0;
  `}

  ${breakpoint('md')`
    margin: 100px 0;
  `}
`;

function ImageGrid(props) {
  function renderImages() {
    return this.props.data.items.map((image, index) => {
      return (
        <InViewMonitor
          classNameNotInView="animated fadeOutUp"
          classNameInView="animated fadeInUp"
          key={index}
        >
          <img alt="" src={image.grid_image.url} />
        </InViewMonitor>
      );
    });
  }

  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <ImageGridContainer>
            <ImageGridStyled
              numOfImages={props.data.items.length}
              className="image-grid"
            >
              {this.renderImages()}
            </ImageGridStyled>
          </ImageGridContainer>
        </Col>
      </Row>
    </Grid>
  );
}

export default React.memo(ImageGrid);
