import React, { Component } from 'react';
import NotFound from '../NotFound/NotFound';
import { withRouter } from 'react-router-dom';
import Prismic from 'prismic-javascript';
import { Helmet } from 'react-helmet';
import NewsItem from './components/NewsItem';
import FirstNewsItem from './components/FirstNewsItem';
import LoadingPage from '../LoadingPage/LoadingPage';
import InViewMonitor from 'react-inview-monitor';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { sortBy } from 'lodash';
import breakpoint from 'styled-components-breakpoint';
import { animateScroll } from 'react-scroll';

const HeaderWrapper = styled.div`
  position: relative;
  ${breakpoint('xs')`
    margin: 8rem 0 2rem;
  `}

  ${breakpoint('md')`
    margin: 10rem 0 6rem;
  `}

  h2 {
    color: rgba(255, 255, 255, 0.6);
    strong {
      color: white;
    }
  }
`;

class News extends Component {
  state = {
    doc: null,
    notFound: false,
  };

  componentDidMount() {
    animateScroll.scrollToTop({
      duration: 0,
      delay: 750,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      animateScroll.scrollToTop({
        duration: 0,
        delay: 750,
      });
    }
  }

  componentWillMount() {
    this.fetchPage(this.props);
  }

  componentWillReceiveProps(props) {
    this.fetchPage(props);
  }

  setActiveItem = item => {
    this.setState({ activeSlug: item });
  };

  async fetchPage(props) {
    if (props.prismicCtx) {
      const res = await props.prismicCtx.api.query(
        Prismic.Predicates.at('document.type', 'news_article')
      );
      this.setState({ doc: res.results });
    }
    return null;
  }

  renderFirstNewsItem() {
    const { doc } = this.state;
    return sortBy(doc, item => item.data.news_article_date)
      .reverse()
      .map((newsItem, index) => {
        if (index === 0) {
          return (
            <InViewMonitor
              classNameNotInView="animated fadeOutUp"
              classNameInView="animated fadeInUp delay-1"
              key={index}
            >
              <FirstNewsItem
                key={index}
                data={newsItem.data}
                uid={newsItem.uid}
              />
            </InViewMonitor>
          );
        }
        return null;
      });
  }

  renderNewsItems() {
    const { doc } = this.state;
    return sortBy(doc, item => item.data.news_article_date)
      .reverse()
      .map((newsItem, index) => {
        if (index === 0) {
          return null;
        }
        return (
          <Col key={index} xs={12} sm={6}>
            <InViewMonitor
              classNameNotInView="animated fadeOutUp"
              classNameInView="animated fadeInUp"
            >
              <NewsItem key={index} data={newsItem.data} uid={newsItem.uid} />
            </InViewMonitor>
          </Col>
        );
      });
  }

  render() {
    const { doc } = this.state;
    if (doc) {
      return (
        <React.Fragment>
          <Helmet>
            <title>News</title>
          </Helmet>
          <Grid>
            <Row>
              <Col xs={12} sm={8}>
                <HeaderWrapper>
                  <InViewMonitor
                    classNameNotInView="animated fadeOutUpBlur"
                    classNameInView="animated fadeInUp delay-2"
                  >
                    <h2>
                      <strong>Ongoings, insights,</strong> and{' '}
                      <strong>experiments</strong> at the studio{' '}
                    </h2>
                  </InViewMonitor>
                </HeaderWrapper>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>{this.renderFirstNewsItem()}</Col>
            </Row>

            <Row>{this.renderNewsItems()}</Row>
          </Grid>
        </React.Fragment>
      );
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return <LoadingPage />;
  }
}

export default withRouter(News);
