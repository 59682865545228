import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import styled from 'styled-components';
import InViewMonitor from 'react-inview-monitor';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import breakpoint from 'styled-components-breakpoint';

const StyledHeader = styled.div`
  position: relative;
  z-index: 10;
  .project-title {
    margin-bottom: 2rem;
  }

  ${breakpoint('xs')`
    margin: 3.3rem auto;
  `}

  ${breakpoint('md')`
     margin: 2.5rem auto 5rem;
  `}
`;

function Header(props) {
  const { project, project_detail } = props.data;

  return (
    <div style={{ width: '100vw' }}>
      <Grid>
        <StyledHeader>
          <InViewMonitor
            classNameNotInView="animated fadeOutUpBlur"
            classNameInView="animated fadeInUp"
            intoViewMargin="10%"
          >
            <Row between="xs" bottom="xs">
              <Col xs={1} />
              <Col xs={10}>
                <h2 className="project-title">{project[0].text}</h2>
              </Col>
              <Col xs={1} />
            </Row>
          </InViewMonitor>

          <Row between="xs">
            <Col xs={1} />
            <Col xs={5}>
              <InViewMonitor
                classNameNotInView="animated fadeOutUp"
                classNameInView="animated fadeInUp delay-1"
                intoViewMargin="10%"
              >
                <h6 className="project-detail">{project_detail[0].text}</h6>
              </InViewMonitor>
            </Col>
            <Col xs={5}>
              <InViewMonitor
                classNameNotInView="animated fadeOutUp"
                classNameInView="animated fadeInUp delay-2"
              >
                <h6 style={{ textAlign: 'right' }}>{startCase(props.tag)}</h6>
              </InViewMonitor>
            </Col>
            <Col xs={1} />
          </Row>
        </StyledHeader>
      </Grid>
    </div>
  );
}

export default React.memo(Header);
