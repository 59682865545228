import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import InViewMonitor from 'react-inview-monitor';
import breakpoint from 'styled-components-breakpoint';

const WorkListItemStyled = styled.div`
  width: 100%;
  margin-top: 55px;
  display: block;
  position: relative;
  overflow: hidden;

  ${breakpoint('xs')`
    height: 180px;
  `}

  ${breakpoint('md')`
    height: 360px;
  `}

  .text-wrapper {
    transform: translateY(-50%);
    top: 50%;
    left: 5%;
    position: absolute;
    z-index: 10;
  }

  .work-list-item-title,
  .work-list-item-subtitle {
    ${breakpoint('xs')`
      opacity: 1;
    `}

    ${breakpoint('md')`
      opacity: 0;
      transform: translateX(-40px);
      transition: 0.3s ease 0s;
      will-change: transform;
    `}
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .gradient1 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: left center;
    z-index: 5;

    ${breakpoint('xs')`
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.8) 10%,
        rgba(0, 0, 0, 0.6) 25%,
        rgba(0, 0, 0, 0) 100%
      );
    `}

    ${breakpoint('md')`
      opacity: 0;
      transition: 1s var(--util-ease);
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 1) 15%,
        rgba(0, 0, 0, 0.75) 50%,
        rgba(0, 0, 0, 0) 100%
      );
    `}
  }

  &:hover {
    ${breakpoint('md')`
      .gradient1 {
        background-position: right center;
        opacity: 1;
      }

      .work-list-item-title {
        transition: 0.5s ease 0.2s;
      }

      .work-list-item-subtitle {
        transition: 0.5s ease 0.3s;
      }

      .work-list-item-title,
      .work-list-item-subtitle {
        opacity: 1;
        transform: translateX(0);
      }

      img {
        transform: scale(1.05);
      }
    `}
  }
`;

function WorkListItem(props) {
  const {
    work_list_image,
    work_list_item_link,
    work_list_item_title,
    work_list_item_subtitle,
  } = props.data.primary;
  return (
    <InViewMonitor
      classNameNotInView="animated fadeOutUp"
      classNameInView="animated fadeInUp"
      intoViewMargin="10%"
    >
      <WorkListItemStyled as={Link} to={`/work/${work_list_item_link.uid}`}>
        <div className="gradient1" />
        <div className="text-wrapper">
          <h2 className="work-list-item-title">
            {work_list_item_title[0].text}
          </h2>
          <h6 className="work-list-item-subtitle">
            {work_list_item_subtitle[0].text}
          </h6>
        </div>
        <img src={work_list_image.url} alt="" />
      </WorkListItemStyled>
    </InViewMonitor>
  );
}

export default React.memo(WorkListItem);
