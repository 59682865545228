import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { kebabCase } from 'lodash';
import { RichText, Date } from 'prismic-reactjs';
import styled from 'styled-components';
import moment from 'moment';
import LoadingPage from '../../LoadingPage/LoadingPage';
import breakpoint from 'styled-components-breakpoint';

const LinkStyled = styled.div`
  position: relative;
  display: block;

  ${breakpoint('xs')`
    margin: 0 auto 80px;
  `}

  ${breakpoint('md')`
    margin: 0 auto 160px;
  `}

  h4 {
    margin-top: 0px;
  }

  h6 {
    ${breakpoint('xs')`
      margin-top: 15px;
    `}

    ${breakpoint('md')`
      margin-top: 30px;
    `}
  }

  .image-wrapper {
    overflow: hidden;
    margin-bottom: 25px;
  }

  img {
    width: 100%;
    max-height: 300px;
    min-height: 243px;
    object-fit: cover;
    transform: scale(1);
    transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    ${breakpoint('xs')`
     min-height: 150px;
  `}
    ${breakpoint('sm')`
      min-height: 243px;
  `}
     ${breakpoint('lg')`
      min-height: 300px;
  `}
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
`;

class NewsItem extends Component {
  static propTypes = {
    data: PropTypes.shape({
      news_article_date: PropTypes.string,
      news_article_image: PropTypes.object,
      news_article_title: PropTypes.array,
    }),
    index: PropTypes.number,
    uid: PropTypes.string,
  };

  render() {
    const { data, uid } = this.props;
    const date = moment(Date(data.news_article_date).toString()).format(
      'MMMM D, YYYY'
    );
    if (data.loading) {
      return <LoadingPage />;
    }
    return (
      <LinkStyled as={Link} to={`/insights/${uid}`}>
        <div className="image-wrapper">
          <img alt="" src={data.news_article_image.url} />
        </div>
        <h6>{date}</h6>
        <h4>{RichText.render(data.news_article_title)}</h4>
      </LinkStyled>
    );
  }
}

export default NewsItem;
