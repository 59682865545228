import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InViewMonitor from 'react-inview-monitor';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import HoverVideoImage from '../../Components/HoverVideoImage';
import breakpoint from 'styled-components-breakpoint';

const ItemStyled = styled.div`
  align-items: center;

  ${breakpoint('xs')`
     margin: 0 0 100px;
  `}

  ${breakpoint('sm')`
     margin: 0 0 130px;
  `}

  ${breakpoint('md')`
     margin: 0 0 300px;
  `}
`;

const DescriptionStyled = styled.h3`
  ${breakpoint('xs')`
    margin-bottom: 30px;
    h3 {
    }
  `}

  ${breakpoint('sm')`
     margin-bottom: 60px;
  `}
`;

const TitleStyled = styled.h6`
  ${breakpoint('xs')`
     margin: 15px auto;
  `}

  ${breakpoint('sm')`
     margin: 0 0 30px;
  `}
`;

const LinkStyled = styled.a`
  padding-bottom: 10px;
  ${breakpoint('xs')`
    font-size: 12px;
    letter-spacing: .02em;
  `}
  
  ${breakpoint('md')`
    font-size: 13px;
  `}
  
  ${breakpoint('lg')`
    font-size: 14px;
    letter-spacing: .05em;
  `}

  ${breakpoint('xl')`
    font-size: 16px;
  `}
`;

export default class FeaturedWorkItem extends Component {
  static propTypes = {
    data: PropTypes.shape({
      featured_work_description: PropTypes.array,
      featured_work_image: PropTypes.object,
      featured_work_link: PropTypes.object,
      featured_work_title: PropTypes.array,
    }),
    index: PropTypes.number,
  };

  state = {
    isPlaying: false,
  };

  setIsPlaying = () => {
    this.setState(prevState => {
      if (!prevState.isPlaying) {
        return { isPlaying: true };
      }
    });
  };

  turnOffIsPlaying = () => {
    this.setState(prevState => {
      if (prevState.isPlaying) {
        return { isPlaying: false };
      }
    });
  };

  render() {
    const {
      featured_work_description,
      featured_work_image,
      featured_work_link,
      featured_work_title,
      video,
    } = this.props.data;
    return (
      <ItemStyled>
        <Row
          style={{
            flexDirection: `${this.props.index === 1 ? 'row-reverse' : 'row'}`,
          }}
          middle="xs"
        >
          <Col xs={12} sm={7}>
            <InViewMonitor
              classNameNotInView="animated fadeOutUpZoomOut"
              classNameInView="animated fadeOutIn"
              intoViewMargin="10%"
            >
              <HoverVideoImage
                url={video.url}
                src={featured_work_image.url}
                isPlaying={this.state.isPlaying}
                link={`/work/${featured_work_link.uid}`}
              />
            </InViewMonitor>
          </Col>

          <Col xs={false} sm={1} />

          <Col xs={12} sm={4}>
            <InViewMonitor
              classNameNotInView="animated fadeOutUp"
              classNameInView="animated fadeInUp"
              intoViewMargin="10%"
            >
              <TitleStyled>{featured_work_title[0].text}</TitleStyled>
            </InViewMonitor>

            <InViewMonitor
              classNameNotInView="animated fadeOutUpBlur"
              classNameInView="animated fadeInUp"
              intoViewMargin="10%"
            >
              <DescriptionStyled>
                {featured_work_description[0].text}
              </DescriptionStyled>
            </InViewMonitor>

            <InViewMonitor
              classNameNotInView="animated fadeOutUp"
              classNameInView="animated fadeInUp"
              intoViewMargin="10%"
            >
              <LinkStyled
                className="underline"
                as={Link}
                to={`/work/${featured_work_link.uid}`}
                onMouseOver={this.setIsPlaying}
                onMouseOut={this.turnOffIsPlaying}
              >
                View project
              </LinkStyled>
            </InViewMonitor>
          </Col>
        </Row>
      </ItemStyled>
    );
  }
}
