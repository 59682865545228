import React, { Component } from 'react';
import Prismic from 'prismic-javascript';
import { Helmet } from 'react-helmet';
import { pick } from 'lodash';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import NotFound from '../NotFound/NotFound';
import Header from './Components/Header';
import LottiePlayer from './Components/LottiePlayer';
import WhoWeAreCopy from './Components/WhoWeAreCopy';
import WhatWeBelieveTextItem from './Components/WhatWeBelieveTextItem';
import LoadingPage from '../LoadingPage/LoadingPage';
import { animateScroll } from 'react-scroll';
import InViewMonitor from 'react-inview-monitor';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const UnitedData = 'https://assets2.lottiefiles.com/packages/lf20_XrfGaJ.json';
const HumanData = 'https://assets1.lottiefiles.com/packages/lf20_Zo3CAf.json';
const BalancedData =
  'https://assets1.lottiefiles.com/packages/lf20_gU18dY.json';
const CommitedData =
  'https://assets8.lottiefiles.com/packages/lf20_XT4Tce.json';
const PioneersData =
  'https://assets4.lottiefiles.com/packages/lf20_XFv7qY.json';

const HeadlineStyled = styled.h3`
  margin-bottom: 150px;
`;

const HeaderStyled = styled(Row)`
  min-height: 100vh;
  display: flex;
  align-items: center;
`;

const WhatWeBelieveWrapper = styled.div`
  margin-bottom: 300px;
  &:last-of-type {
    margin-bottom: 150px;
  }
  ${breakpoint('sm')`
    flex-direction: ${props => (props.index % 2 !== 0 ? 'row-reverse' : 'row')};
  `}
`;

const LogoImage = styled.img`
  max-width: 270px;

  ${breakpoint('xs')`
    margin: 25px 30px;

  `}
  ${breakpoint('md')`
    margin: 0 30px;
  `}
`;

const animData = [
  HumanData,
  CommitedData,
  UnitedData,
  BalancedData,
  PioneersData,
];

export default class WhatWeDo extends Component {
  state = {
    doc: null,
    notFound: false,
  };

  componentWillMount() {
    this.fetchPage(this.props);
  }

  componentDidMount() {
    animateScroll.scrollToTop({
      duration: 0,
      delay: 750,
    });
  }

  componentWillReceiveProps(props) {
    this.fetchPage(props);
  }

  async fetchPage(props) {
    if (props.prismicCtx) {
      const res = await props.prismicCtx.api.query(
        Prismic.Predicates.at('document.type', 'who_we_are')
      );
      this.setState({ doc: res.results[0] });
    }
    return null;
  }

  filterComponentData(section) {
    const { data } = this.state.doc;
    const whoWeAreCopy = data.body.filter(
      item => item.slice_type === 'who_we_are_copy'
    );
    const { primary } = whoWeAreCopy[0];
    let allowed;
    switch (section) {
      case 'header':
        allowed = ['title', 'subtitle', 'subdescription', 'description'];
        return pick(data, allowed);
      case 'who-we-are':
        allowed = ['who_we_are_copy_body', 'who_we_are_copy_title'];
        return pick(primary, allowed);
      default:
        return null;
    }
  }

  renderPartnerLogos() {
    const { body } = this.state.doc.data;
    return body.map((item, i) => {
      if (item.slice_type === 'partner_logos') {
        return item.items.map((partnerImage, key) => {
          return <LogoImage index={key} src={partnerImage.partner.url} />;
        });
      }
      return null;
    });
  }

  formatWhatWeBelieveTextItem() {
    const { body } = this.state.doc.data;
    return body.map((item, i) => {
      if (item.slice_type === 'what_we_believe_item') {
        const imageSrc = item.primary.believe_image.url;
        return (
          <WhatWeBelieveWrapper as={Row} index={i} key={i} middle="xs">
            <Col xs={2} sm={1} />

            <Col xs={8} sm={5}>
              <InViewMonitor
                classNameNotInView="animated fadeOutUp"
                classNameInView="animated fadeInUp delay-1"
              >
                {<LottiePlayer animationData={animData[i]} />}
              </InViewMonitor>
            </Col>
            <Col xs={1} />
            <Col xs={12} sm={4}>
              <WhatWeBelieveTextItem data={item} />
            </Col>
            <Col xs={2} sm={1} />
          </WhatWeBelieveWrapper>
        );
      }
      return null;
    });
  }

  render() {
    const { doc } = this.state;
    if (doc) {
      return (
        <div>
          <Helmet>
            <title>Leviathan</title>
          </Helmet>
          <Grid>
            <HeaderStyled>
              <Col xs={false} md={1} />
              <Col xs={12} md={8}>
                <Header data={this.filterComponentData('header')} />
              </Col>
              <Col xs={false} md={1} />
              <Col xs={false} md={1} />
              <Col xs={false} md={1} />
            </HeaderStyled>
            <InViewMonitor
              classNameNotInView="animated fadeOutUp"
              classNameInView="animated fadeInUp"
            >
              <Row>
                <Col xsOffset={1} xs={11}>
                  <HeadlineStyled>What we believe</HeadlineStyled>
                </Col>
              </Row>
            </InViewMonitor>
            {this.formatWhatWeBelieveTextItem()}
          </Grid>
          <Grid>
            <Row>
              <WhoWeAreCopy data={this.filterComponentData('who-we-are')} />
            </Row>
          </Grid>
        </div>
      );
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return <LoadingPage />;
  }
}
