import React, { useEffect, useState } from 'react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import InViewMonitor from 'react-inview-monitor';

import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import DragCursor from '../../assets/DragCursor.svg';

const HorizontalImagesWrapper = styled.div`
  margin-bottom: 300px;
  overflow-x: hidden;
  width: 100vw;
  h3 {
    margin-bottom: 40px;
  }
  p {
    max-width: 560px;
  }
`;

const ImageStyled = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const CarouelWrapper = styled.div`
  cursor: url(${DragCursor}), auto;

  li {
    ${breakpoint('xs')`
      max-width: 400px !important;
      width: 400px !important;
      height: 200px !important;
  `}

${breakpoint('sm')`
      max-width: 600px !important;
      width: 600px !important;
      height: 400px !important;
  `}

    ${breakpoint('xl')`
    max-width: 800px !important;
    width: 800px !important;
    height: 500px !important;
  `}
  }
`;

const StyledCarousel = styled(Carousel)`
  margin-top: 100px;
  display: flex;
  li {
    justify-content: flex-start;
    max-width: 800px;
  }
  li {
    &:first-of-type {
      padding-left: 0px !important;
    }
  }
`;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const HorizontalImages = props => {
  const { data } = props;

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getNumSlides = () => {
    if (windowDimensions.width < 900) {
      return 2;
    } else if (windowDimensions.width > 900 && windowDimensions.width < 1600) {
      return 3;
    } else if (windowDimensions.width > 1600) {
      return 4;
    }
  };

  const getImageWidth = () => {
    if (windowDimensions.width < 900) {
      return 400;
    } else if (windowDimensions.width < 1100) {
      return 600;
    } else {
      return 800;
    }
  };

  const renderData = () => {
    return data.items.map((image, index) => {
      return <ImageStyled key={index} src={image.image.url} alt="" />;
    });
  };
  return (
    <HorizontalImagesWrapper>
      <InViewMonitor
        classNameNotInView="animated fadeOutUp"
        classNameInView="animated fadeInUp"
      >
        <Grid>
          <Row>
            <Col xs={12}>
              <CarouelWrapper style={{ width: '100vw' }}>
                <StyledCarousel
                  itemWidth={getImageWidth()}
                  offset={20}
                  slidesPerPage={getNumSlides()}
                  keepDirectionWhenDragging
                >
                  {renderData()}
                </StyledCarousel>
              </CarouelWrapper>
            </Col>
          </Row>
        </Grid>
      </InViewMonitor>
    </HorizontalImagesWrapper>
  );
};

export default HorizontalImages;
