import React, { Component } from 'react';
import Prismic from 'prismic-javascript';
import { Helmet } from 'react-helmet';
import { pick } from 'lodash';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import NotFound from '../NotFound/NotFound';
import styled from 'styled-components';
import Header from './Components/Header';
import WhatWeDoTextBlock from './Components/WhatWeDoTextBlock';
import PracticeArea from './Components/PracticeArea';
import InViewMonitor from 'react-inview-monitor';
import BrandShowcase from './Components/BrandShowcase';
import AwardShowcase from './Components/AwardShowcase';
import LoadingPage from '../LoadingPage/LoadingPage';
import { animateScroll } from 'react-scroll';

const PracticeAreasWrapper = styled.div`
  .practice-areas {
    margin-bottom: 85px;
  }
`;

const HeaderStyled = styled(Row)`
  min-height: 100vh;
  display: flex;
  align-items: center;
`;

export default class WhatWeDo extends Component {
  state = {
    doc: null,
    notFound: false,
  };

  componentWillMount() {
    this.fetchPage(this.props);
  }

  componentWillReceiveProps(props) {
    this.fetchPage(props);
  }
  componentDidMount() {
    animateScroll.scrollToTop({
      duration: 0,
      delay: 750,
    });
  }

  async fetchPage(props) {
    if (props.prismicCtx) {
      const res = await props.prismicCtx.api.query(
        Prismic.Predicates.at('document.type', 'what_we_do')
      );
      this.setState({ doc: res.results[0] });
    }
    return null;
  }

  filterComponentData(section) {
    const { data } = this.state.doc;
    let allowed;
    switch (section) {
      case 'header':
        allowed = [
          'title',
          'subtitle',
          'description_title',
          'description_body',
        ];
        return pick(data, allowed);
      default:
        return null;
    }
  }

  formatPracticeAreas() {
    const { body } = this.state.doc.data;
    return body.map((item, i) => {
      if (item.slice_type === 'practice_area') {
        return <PracticeArea index={i} key={i} data={item} />;
      }
      return null;
    });
  }

  formatWhatWeDoTextBlock() {
    const { body } = this.state.doc.data;
    return body.map((item, i) => {
      if (item.slice_type === 'what_we_do_text_block') {
        return <WhatWeDoTextBlock key={i} data={item} />;
      }
      return null;
    });
  }

  renderBrandSection() {
    const { body } = this.state.doc.data;
    return body.map((item, i) => {
      if (item.slice_type === 'brand_showcase') {
        return <BrandShowcase key={i} data={item} />;
      }
      return null;
    });
  }

  renderAwardSection() {
    const { body } = this.state.doc.data;
    return body.map((item, i) => {
      if (item.slice_type === 'award_showcase') {
        return <AwardShowcase key={i} data={item} />;
      }
      return null;
    });
  }

  render() {
    const { doc } = this.state;
    if (doc) {
      return (
        <div>
          <Helmet>
            <title>Leviathan</title>
          </Helmet>
          <Grid>
            <HeaderStyled>
              <Col xs={false} md={1} />
              <Col xs={12} md={8}>
                <Header data={this.filterComponentData('header')} />
              </Col>
              <Col xs={false} md={1} />
              <Col xs={false} md={1} />
              <Col xs={false} md={1} />
            </HeaderStyled>

            <PracticeAreasWrapper>
              <InViewMonitor
                classNameNotInView="animated fadeOutUp"
                classNameInView="animated fadeInUp"
              >
                <Row>
                  <Col xs={12}>
                    <h3 className="practice-areas">Practice areas</h3>
                  </Col>
                </Row>
              </InViewMonitor>

              {this.formatPracticeAreas()}
            </PracticeAreasWrapper>
            {this.formatWhatWeDoTextBlock()}
          </Grid>
          {this.renderBrandSection()}
          {this.renderAwardSection()}
        </div>
      );
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return <LoadingPage />;
  }
}
