import React, { Component } from 'react';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import InViewMonitor from 'react-inview-monitor';
import PropTypes from 'prop-types';
import breakpoint from 'styled-components-breakpoint';

const HeaderWrapper = styled.div`
  margin: 25vh auto;
  position: relative;
`;

const ContentWrapper = styled.div`
  width: 100%;
  h6 {
    margin-bottom: 10px;
  }
  h5 {
    color: white;
    margin-bottom: 20px;
    font-weight: 600;
  }
  hr {
    margin-inline-start: initial;
    margin-top: 60px;
    margin-bottom: 60px;
    width: 43px;
    opacity: 0.6;
    ${breakpoint('xs')`
   margin-top: 30px;
    margin-bottom: 30px;
  `}
    ${breakpoint('sm')`
   margin-top: 60px;
    margin-bottom: 60px;
  `}
  }
  p {
    &:first-of-type {
      margin-bottom: 15px;
    }
  }
`;

export default class Header extends Component {
  static propTypes = {
    data: PropTypes.shape({
      description_body: PropTypes.array,
      description_title: PropTypes.array,
      subtitle: PropTypes.array,
      title: PropTypes.array,
    }),
  };

  render() {
    const {
      description_body,
      description_title,
      subtitle,
      title,
    } = this.props.data;
    return (
      <HeaderWrapper>
        <ContentWrapper>
          <InViewMonitor
            classNameNotInView="animated fadeOutUp"
            classNameInView="animated fadeInUp"
          >
            {RichText.render(subtitle)}
          </InViewMonitor>
          <InViewMonitor
            classNameNotInView="animated fadeOutUpBlur"
            classNameInView="animated fadeInUp delay-1"
          >
            {RichText.render(title)}
          </InViewMonitor>
          <InViewMonitor
            classNameNotInView="animated fadeOutUp"
            classNameInView="animated fadeInUp delay-2"
          >
            <hr />
          </InViewMonitor>
          <InViewMonitor
            classNameNotInView="animated fadeOutUp"
            classNameInView="animated fadeInUp delay-3"
          >
            {RichText.render(description_title)}
          </InViewMonitor>
          <InViewMonitor
            classNameNotInView="animated fadeOutUp"
            classNameInView="animated fadeInUp delay-4"
          >
            {RichText.render(description_body)}
          </InViewMonitor>
        </ContentWrapper>
      </HeaderWrapper>
    );
  }
}
