import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import NotFound from '../../Pages/NotFound/NotFound';
import LoadingPage from '../../Pages/LoadingPage/LoadingPage';
import { animateScroll } from 'react-scroll';
import InViewMonitor from 'react-inview-monitor';
import Auth from './Auth';

const MainWrapper = styled.div`
  padding: 5em 0 0;
  max-width: 1200px;
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  p {
    margin: 2em auto;
    max-width: 80vw;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  margin: 3em 0;
  max-width: 1200px;
  h5 {
    margin-bottom: 2em;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  margin: 3em 0;
  width: 80vw;
  height: 60vh;
  max-width: 1200px;
  h5 {
    margin-bottom: 2em;
  }
`;

class ThreeDShowcase extends Component {
  ref = player => {
    this.player = player;
  };
  state = {
    doc: null,
    notFound: false,
    verified: false,
  };

  componentWillMount() {
    this.fetchPage(this.props);
  }

  componentDidMount() {
    animateScroll.scrollToTop({
      duration: 0,
      delay: 750,
    });
  }

  componentWillReceiveProps(props) {
    this.fetchPage(props);
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      return props.prismicCtx.api.getByUID(
        'showcase',
        props.match.params.uid,
        {},
        (err, doc) => {
          if (doc) {
            this.setState({ doc });
          } else {
            this.setState({ notFound: !doc });
          }
        }
      );
    }
    return null;
  }

  authenticate = () => {
    this.setState({ verified: true });
  };

  renderMedia() {
    const { body } = this.state.doc.data;
    return body
      .filter(
        items => items.slice_type === 'image' || items.slice_type === 'video'
      )
      .map(item => {
        if (item.slice_type === 'image') {
          return (
            <InViewMonitor
              classNameNotInView="animated fadeOutUp"
              classNameInView="animated fadeInUp"
            >
              <ImageContainer>
                {RichText.render(item.primary.image_title)}

                <iframe
                  height="100%"
                  width="100%"
                  allowFullScreen="true"
                  src={item.primary.image.url}
                />
              </ImageContainer>
            </InViewMonitor>
          );
        } else if (item.slice_type === 'video') {
          return (
            <InViewMonitor
              classNameNotInView="animated fadeOutUp"
              classNameInView="animated fadeInUp"
            >
              <VideoContainer>
                {RichText.render(item.primary.video_title)}
                <ReactPlayer
                  ref={this.ref}
                  width="80vw"
                  height="60vh"
                  className="player"
                  url={item.primary.video.url}
                  playing={true}
                  loop={true}
                  style={{ maxWidth: '1200px' }}
                  preload="true"
                  playsinline={true}
                  muted={true}
                  volume={0}
                  config={{
                    vimeo: {
                      playerOptions: { byline: false, preload: true },
                    },
                  }}
                />
              </VideoContainer>
            </InViewMonitor>
          );
        }
      });
  }

  renderContent(data) {
    if (this.state.verified) {
      return (
        <MainWrapper className="main-wrapper">
          <InViewMonitor
            classNameNotInView="animated fadeOutUp"
            classNameInView="animated fadeInUp"
          >
            {RichText.render(data.title)}
          </InViewMonitor>
          <InViewMonitor
            classNameNotInView="animated fadeOutUp"
            classNameInView="animated fadeInUp delay-1"
          >
            {RichText.render(data.description)}
          </InViewMonitor>

          {this.renderMedia()}
        </MainWrapper>
      );
    } else {
      return <Auth authenticate={this.authenticate} />;
    }
  }
  render() {
    if (this.state.doc) {
      const { data } = this.state.doc;
      return this.renderContent(data);
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return <LoadingPage />;
  }
}

export default ThreeDShowcase;
