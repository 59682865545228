import React from 'react';
import 'whatwg-fetch';
import Prismic from 'prismic-javascript';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
import PrismicConfig from './prismic-configuration';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './GlobalStyle';
import ReactGA from 'react-ga';

import App from './App';

const tracker = 'UA-138147805-1';
const history = createBrowserHistory();

ReactGA.initialize(tracker);

history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const theme = {
  paleGray: 'rgba(255,255,255,0.8)',
  lightGray: 'rgba(255,255,255,0.6)',
  darkGray: '#0F0F0F',
  fontFamily: 'Gotham',
  flexboxgrid: {
    gridSize: 12,
    gutterWidth: 1,
    outerMargin: 2,
    mediaQuery: 'only screen',
    container: {
      sm: 44, // em
      md: 59, // em
      lg: 76, // em
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 85, // em
    },
  },
  breakpoints: {
    xs: 0, // px
    sm: 576, // px
    md: 769, // px
    lg: 1024, // px
    xl: 1360, // px
  },

  // CONVERT PX to EM with 16px baseline
  // http://pxtoem.com/
};

export default class PrismicApp extends React.Component {
  state = {
    prismicCtx: null,
    isLoaded: false,
  };

  componentWillMount() {
    this.buildContext()
      .then(prismicCtx => {
        this.setState({ prismicCtx: prismicCtx, isLoaded: true });
      })
      .catch(e => {
        console.error(
          `Cannot contact the API, check your prismic configuration:\n${e}`
        );
      });
  }

  buildContext() {
    const accessToken = PrismicConfig.accessToken;
    return Prismic.api(PrismicConfig.apiEndpoint, { accessToken }).then(
      api => ({
        api,
        endpoint: PrismicConfig.apiEndpoint,
        accessToken,
        linkResolver: PrismicConfig.linkResolver,
      })
    );
  }

  render() {
    const { isLoaded, prismicCtx } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <BrowserRouter>
            <App prismicCtx={prismicCtx} isLoaded={isLoaded} />
          </BrowserRouter>
        </>
      </ThemeProvider>
    );
  }
}
