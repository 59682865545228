import React from 'react';

import ga from 'react-ga';

ga.initialize('UA-138147805-1');

export default Component =>
  class WithAnalytics extends React.Component {
    componentDidMount() {
      const page = this.props.location.pathname;
      this.trackPage(page);
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;
      if (currentPage !== nextPage) this.trackPage(nextPage);
    }

    trackPage = page => {
      ga.set({ page });
      ga.pageview(page);
    };

    render() {
      return <Component {...this.props} />;
    }
  };

export const trackUser = userId => ga.set({ userId });

export const trackEvent = (category, action, label) =>
  ga.event({ category, action, label });
