import React, { Component } from 'react';
import { RichText } from 'prismic-reactjs';
import { Col, Row, Grid } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import InViewMonitor from 'react-inview-monitor';

const StyledRow = styled(Row)`
  padding: 9em 0;

  h2 {
    margin-bottom: 10%;
  }
`;

class AwardShowcase extends Component {
  render() {
    const { data } = this.props;
    return (
      <Grid>
        <InViewMonitor
          classNameNotInView="animated fadeOutUpBlur"
          classNameInView="animated fadeInUp"
        >
          <StyledRow>
            <Col style={{ textAlign: 'center' }} xs={12}>
              {RichText.render(data.primary.award_title)}
            </Col>
            <Col xs={1} />
            <Col xs={10}>
              <img
                style={{ width: '100%' }}
                src={data.items[0].award.url}
                alt=""
              />
            </Col>
            <Col xs={1} />
          </StyledRow>
        </InViewMonitor>
      </Grid>
    );
  }
}

export default AwardShowcase;
