import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { withRouter } from 'react-router-dom';
import { Spring, animated } from 'react-spring/renderprops';
import * as easings from 'd3-ease';
import VideoContext from '../../framework/VideoContext';
import Headroom from 'react-headroom';
import breakpoint from 'styled-components-breakpoint';
import LeviathanLogo from './LeviathanLogo';
import Back from './Back';

import Menu from '../Menu/Menu';

const StyledHeader = styled.div`
  position: ${props => (props.isCareerPost ? 'relative' : 'fixed')} ;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  opacity: ${props => (props.isVideoPlaying ? 0 : 1)};
  transition: opacity 0.4s;
  .lvthn-logo {
    width: 90%;
    max-width: 180px;
    svg {
      max-width: 100%;
    }
  }
  .back-image {
    height: 22px;
    transform: scale(1);
    transition: all 0.2s;
    ${breakpoint('xs')`
      height: 16px;
    `}
    ${breakpoint('sm')`
      height: 18px;
    `}
    ${breakpoint('lg')`
      height: 22px;
    `}
    &:hover {
      opacity: 0.7;
      transform: scale(0.95);
    }
  }
`;

function Header(props) {
  const [isGridVisible, setIsGridVisible] = useState(false);
  const [slug, setSlug] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let showBackButton = false;

  useEffect(() => {
    const slug = getSlug();
    setSlug(slug);
  }, [props.location.pathname]);

  function getSlug() {
    return props.history.location.pathname.split('/');
  }

  function toggleIsMenuOpen() {
    setIsMenuOpen(!isMenuOpen);
  }

  function handleCloseMenu() {
    document.body.style = '';
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  }

  function renderLeftContent() {
    if (!slug) {
      return null;
    }
    showBackButton = slug[1] === 'insights' && slug[2] && !isMenuOpen;

    if (slug) {
      if (slug[1] === 'insights' && slug[2] && !isMenuOpen) {
        return (
          <Link to="/insights">
            <Back />
          </Link>
        );
      }
      if (slug[1] === 'work' && slug[2] && !isMenuOpen) {
        return (
          <Link to="/work">
            <Back />
          </Link>
        );
      }
      if (slug[1] === 'careers' && slug[2] && !isMenuOpen) {
        return (
          <Link to="/careers">
            <Back />
          </Link>
        );
      }
      return (
        <a className="lvthn-logo" href="https://www.weareenvoy.com/services/digital-environments">
          <LeviathanLogo handleClick={handleCloseMenu} />
        </a>
      );
    }
  }
  if (slug && slug[1] === 'showcase') {
    return null;
  }
  const VideoState = VideoContext.useContainer();
  return (
    <>
      <StyledHeader
        isVideoPlaying={VideoState.isPlaying}
        className="app-header"
        slug={slug ? slug : ''}
        isCareerPost={slug && slug[1] === 'careers' && slug[2]}
      >
        <Headroom
          disableInlineStyles
          disable={slug && slug[1] === 'careers' && slug[2]}
          style={{
            width: '100vw',
            paddingTop: VideoState.isPlaying ? '0px' : '3.5rem',
          }}
        >
          <Grid>
            <Row middle="xs" between="xs">
              <Col xs={5} sm={4} md={3} lg={2}>
                <Spring
                  native
                  reset={showBackButton}
                  from={{ opacity: 0, transform: 'translate3d(0, -40px, 0)' }}
                  to={{ opacity: 1, transform: 'translate3d(0, 0px, 0)' }}
                  config={{
                    tension: 180,
                    friction: 50,
                    easing: easings.easeCubic,
                  }}
                >
                  {props => (
                    <animated.div style={props}>
                      {renderLeftContent()}
                    </animated.div>
                  )}
                </Spring>
              </Col>

              <Col>
                <Menu
                  onMenuToggle={toggleIsMenuOpen}
                  isMenuOpen={isMenuOpen}
                  isGridVisible={isGridVisible}
                  handleCloseMenu={handleCloseMenu}
                />
              </Col>
            </Row>
          </Grid>
        </Headroom>
      </StyledHeader>
    </>
  );
}

export default React.memo(withRouter(Header));
