import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { kebabCase } from 'lodash';
import { RichText, Date } from 'prismic-reactjs';
import styled from 'styled-components';
import moment from 'moment';
import breakpoint from 'styled-components-breakpoint';

const LinkStyled = styled.div`
  position: relative;
  display: block;

  ${breakpoint('xs')`
    margin-bottom: 80px;
  `}

  ${breakpoint('md')`
    margin-bottom: 160px ;
  `}

  h4 {
    margin-top: 0px;
  }

  h6 {
    margin-top: 30px;
  }

  .image-wrapper {
    overflow: hidden;
    margin-bottom: 25px;
    max-height: 440px;
  }

  img {
    width: 100%;
    transform: scale(1) translateY(-10px);
    transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    ${breakpoint('lg')`
        transform: scale(1) translateY(-150px);
    `}
  }

  &:hover {
    img {
      transform: scale(1.05) translateY(-10px);
      ${breakpoint('lg')`
        transform: scale(1.05) translateY(-150px);
    `}
    }
  }
`;

export default class FirstNewsItem extends Component {
  static propTypes = {
    data: PropTypes.shape({
      news_article_date: PropTypes.string,
      news_article_image: PropTypes.object,
      news_article_title: PropTypes.array,
    }),
    index: PropTypes.number,
    uid: PropTypes.string,
  };

  render() {
    const { data, uid } = this.props;
    const date = moment(Date(data.news_article_date).toString()).format(
      'MMMM D, YYYY'
    );
    return (
      <LinkStyled as={Link} to={`/insights/${uid}`}>
        <div className="image-wrapper">
          <img alt="" src={data.news_article_image.url} />
        </div>
        <h6>{date}</h6>
        <h4>{RichText.render(data.news_article_title)}</h4>
      </LinkStyled>
    );
  }
}
