import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import InViewMonitor from 'react-inview-monitor';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import breakpoint from 'styled-components-breakpoint';

const QuoteStyled = styled.div`
  padding: 160px 0 260px;
  p {
    font-size: 24px;
    font-weight: 100;
    color: rgba(255, 255, 255, 0.6);
    letter-spacing: 0.025em;
    line-height: 40px;
    &:last-of-type {
      text-align: center;
    }
    strong {
      text-align: center;
      color: white;
    }
  }
  h3 {
    margin-top: 40px;
    text-align: center;
  }
  h6 {
    text-align: center;
    margin-top: 46px;
    display: inline;
  }

  .author-wrapper {
    margin-top: 40px;
    text-align: center;

    ${breakpoint('xs')`
    `}

    ${breakpoint('md')`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  .comma {
      color: rgba(255, 255, 255, 0.6);
      ${breakpoint('xs')`
        display: none;
      `}

      ${breakpoint('md')`
        display: block;
      `}
    }
  }
`;
function Quote(props) {
  const { quote_author, quote_author_position, quote } = props.data.primary;
  return (
    <InViewMonitor
      classNameNotInView="animated fadeOutUp"
      classNameInView="animated fadeInUp"
    >
      <QuoteStyled className="quote">
        <Grid>
          <Row>
            <Col xs={1} />
            <Col xs={10}>
              {RichText.render(quote)}
              <div className="author-wrapper">
                {RichText.render(quote_author)}
                <div className="comma">{','}&nbsp;</div>
                {RichText.render(quote_author_position)}
              </div>
            </Col>
            <Col xs={1} />
          </Row>
        </Grid>
      </QuoteStyled>
    </InViewMonitor>
  );
}

export default React.memo(Quote);
