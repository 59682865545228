import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import breakpoint from 'styled-components-breakpoint';

const FooterStyled = styled.div`
  width: 100vw;
  background: ${props => props.theme.darkGray};
  padding: 4rem 0;
  position: relative;
  z-index: 10;

  ${breakpoint('xs')`
    margin-top: 80px;
  `}

  ${breakpoint('md')`
    margin-top: 100px;
  `}
  ${breakpoint('lg')`
    margin-top: 190px;
  `}
`;

const StyledCopyrightRow = styled(Row)`
  ${breakpoint('md')`
    max-width: 904px;
    font-size: 12px;
    `}
  ${breakpoint('lg')`
        margin-top: 3em;
        max-width: 1109px;
      `}
`;

const StyledLink = styled.h4`
  margin-top: 5px;
  display: inline-block;
  transition: color 0.3s ease-in-out;
  color: ${props => {
    if (!props.ishover) {
      return 'rgba(255,255,255,1)';
    } else if (props.ishover && props.isactive) {
      return 'rgba(255,255,255,1)';
    } else {
      return 'rgba(255,255,255,0.4)';
    }
  }};
`;

const StyledExternalLink = styled.a`
  margin-top: 5px;
  display: inline-block;

  transition: color 0.3s ease-in-out;
  color: ${props => {
    if (!props.ishover) {
      return 'rgba(255,255,255,1)';
    } else if (props.ishover && props.isactive) {
      return 'rgba(255,255,255,1)';
    } else {
      return 'rgba(255,255,255,0.4)';
    }
  }};
`;

const Copyright = styled.h6`
line-height: 1;
  ${breakpoint('xs')`
     margin: 2rem 0 0;
     font-size: 11px;

  `}
    ${breakpoint('sm')`
     font-size: 12px;
text-align: left;


  `}
  ${breakpoint('md')`
     margin: 2rem 0 0;
     font-size: 12px;
text-align: right;

  `}
`;

const StyledHeader = styled.h6`
  margin-bottom: 1em;
`;

const StyledDivider = styled.div`
  ${breakpoint('xs')`
    display: block;
    height: 40px;
    width: 100%;
  `}

  ${breakpoint('sm')`
    display: none;
  `}
`;

const StyledOffset = styled.div`
  ${breakpoint('xs')`
    margin-top: 30px;
  `}

  ${breakpoint('sm')`
    margin-top: 35px;
  `}
`;

class Footer extends Component {
  state = {
    activeLink: '',
  };

  handleHoverIn(name) {
    this.setState(state => {
      return { activeLink: name };
    });
  }

  handleHoverOut(name) {
    this.setState(state => {
      return { activeLink: '' };
    });
  }

  render() {
    if (this.props.history.location.pathname === '/showcase') {
      return null;
    }
    return (
      <FooterStyled>
        <Grid>
          <Row>
            <Col xs={12} sm={6}>
              <Row>
                <Col xs={6}>
                  <StyledHeader>Menu</StyledHeader>
                  <div className="">
                    <StyledLink
                      onMouseOver={() => this.handleHoverIn('work')}
                      ishover={this.state.activeLink !== '' ? 1 : 0}
                      isactive={this.state.activeLink === 'work' ? 1 : 0}
                      onMouseOut={() => this.handleHoverOut()}
                      as={Link}
                      to="/work"
                    >
                      Work
                    </StyledLink>
                  </div>
                  <div className="">
                    <StyledLink
                      onMouseOver={() => this.handleHoverIn('who-we-are')}
                      ishover={this.state.activeLink !== '' ? 1 : 0}
                      isactive={this.state.activeLink === 'who-we-are' ? 1 : 0}
                      onMouseOut={() => this.handleHoverOut()}
                      as={Link}
                      to="/who-we-are"
                    >
                      Who we are
                    </StyledLink>
                  </div>
                  <div className="">
                    <StyledLink
                      onMouseOver={() => this.handleHoverIn('what-we-are')}
                      ishover={this.state.activeLink !== '' ? 1 : 0}
                      isactive={this.state.activeLink === 'what-we-are' ? 1 : 0}
                      onMouseOut={() => this.handleHoverOut()}
                      as={Link}
                      to="/what-we-do"
                    >
                      What we do
                    </StyledLink>
                  </div>
                </Col>

                <Col xs={6}>
                  <StyledOffset>
                    <div className="">
                      <StyledLink
                        onMouseOver={() => this.handleHoverIn('insights')}
                        ishover={this.state.activeLink !== '' ? 1 : 0}
                        isactive={this.state.activeLink === 'insights' ? 1 : 0}
                        onMouseOut={() => this.handleHoverOut()}
                        as={Link}
                        to="/insights"
                      >
                        Insights
                      </StyledLink>
                    </div>
                  </StyledOffset>
                  <div>
                    <StyledLink
                      onMouseOver={() => this.handleHoverIn('careers')}
                      ishover={this.state.activeLink !== '' ? 1 : 0}
                      isactive={this.state.activeLink === 'careers' ? 1 : 0}
                      onMouseOut={() => this.handleHoverOut()}
                      as={Link}
                      to="/careers"
                    >
                      Careers
                    </StyledLink>
                  </div>
                  <div>
                    <StyledLink
                      onMouseOver={() => this.handleHoverIn('contact')}
                      ishover={this.state.activeLink !== '' ? 1 : 0}
                      isactive={this.state.activeLink === 'contact' ? 1 : 0}
                      onMouseOut={() => this.handleHoverOut()}
                      as={Link}
                      to="/contact"
                    >
                      Contact
                    </StyledLink>
                  </div>
                </Col>
              </Row>
            </Col>

            <StyledDivider />

            <Col xs={12} sm={3}>
              <StyledHeader>Follow</StyledHeader>
              <div>
                <StyledExternalLink
                  target="_blank"
                  href="https://www.instagram.com/weareenvoy"
                  onMouseOver={() => this.handleHoverIn('instagram')}
                  ishover={this.state.activeLink !== '' ? 1 : 0}
                  isactive={this.state.activeLink === 'instagram' ? 1 : 0}
                  onMouseOut={() => this.handleHoverOut()}
                >
                  Instagram
                </StyledExternalLink>
              </div>
              <div>
                <StyledExternalLink
                  target="_blank"
                  href="https://www.linkedin.com/company/we-are-envoy"
                  onMouseOver={() => this.handleHoverIn('linkedin')}
                  ishover={this.state.activeLink !== '' ? 1 : 0}
                  isactive={this.state.activeLink === 'linkedin' ? 1 : 0}
                  onMouseOut={() => this.handleHoverOut()}
                >
                  Linkedin
                </StyledExternalLink>
              </div>
            </Col>

            <StyledDivider />

            <Col xs={12} sm={3}>
              <StyledHeader>General</StyledHeader>
              <div>
                <StyledExternalLink
                  onMouseOver={() => this.handleHoverIn('info')}
                  ishover={this.state.activeLink !== '' ? 1 : 0}
                  isactive={this.state.activeLink === 'info' ? 1 : 0}
                  onMouseOut={() => this.handleHoverOut()}
                  href="mailto:engage@weareenvoy.com"
                >
                  engage@weareenvoy.com
                </StyledExternalLink>
              </div>
              <div>
                <StyledExternalLink
                  onMouseOver={() => this.handleHoverIn('number')}
                  ishover={this.state.activeLink !== '' ? 1 : 0}
                  isactive={this.state.activeLink === 'number' ? 1 : 0}
                  onMouseOut={() => this.handleHoverOut()}
                  href={'tel:+1-312-878-1500'}
                >
                  +1 312.878.1500
                </StyledExternalLink>
              </div>
            </Col>
          </Row>

          <StyledCopyrightRow bottom="xs">
              <Copyright>
                &copy; Envoy Chicago | Formerly Leviathan 2010 - 2022
              </Copyright>
          </StyledCopyrightRow>
        </Grid>
      </FooterStyled>
    );
  }
}
export default withRouter(Footer);
