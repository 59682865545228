import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Row, Grid } from 'react-styled-flexboxgrid';
import InViewMonitor from 'react-inview-monitor';
import breakpoint from 'styled-components-breakpoint';

const StyledRow = styled(Row)`
  ${breakpoint('xs')`
    margin: 260px 0px;
  `}
  ${breakpoint('md')`
    margin: 360px 0px;
  `}
`;

const ImageStyled = styled.img`
  width: 100%;
  max-height: 800px;
  ${breakpoint('xs')`
    margin-bottom: 1rem;
  `}
  ${breakpoint('lg')`
     margin-bottom: 7vw;
  `}
`;

const FirstColumnStyled = styled(Col)``;
const SecondColumnStyled = styled(Col)`
  ${breakpoint('xs')`
    margin-top: 104px;
  `}
  ${breakpoint('md')`
    margin-top: 150px;
  `}
`;

const PhotoGallery = props => {
  const { data } = props;
  const [urls, set] = useState({ 1: [], 2: [] });
  useEffect(() => {
    if (!data) {
      return;
    }
    data.items.forEach((item, i) => {
      if (i % 2 === 0) {
        urls[1].push(item.image.url);
        return set(urls);
      }
      urls[2].push(item.image.url);
      return set(urls);
    });
  }, [data]);

  return (
    <Grid>
      <StyledRow around="xs">
        <FirstColumnStyled xs={6} md={5}>
          {urls[1].map((url, i) => {
            return (
              <InViewMonitor
                classNameNotInView="animated fadeOutUp"
                classNameInView="animated fadeInUp "
                key={i}
              >
                <ImageStyled src={url} />
              </InViewMonitor>
            );
          })}
        </FirstColumnStyled>

        <SecondColumnStyled xs={6} md={5}>
          {urls[2].map((url, i) => {
            return (
              <InViewMonitor
                classNameNotInView="animated fadeOutUp"
                classNameInView="animated fadeInUp "
                key={i}
              >
                <ImageStyled src={url} key={i} />
              </InViewMonitor>
            );
          })}
        </SecondColumnStyled>
      </StyledRow>
    </Grid>
  );
};

export default PhotoGallery;
