import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import InViewMonitor from 'react-inview-monitor';
import { Col } from 'react-styled-flexboxgrid';
import breakpoint from 'styled-components-breakpoint';

const ImageContainer = styled.div`
  width: 100%;
  overflow: hidden;

  img {
    line-height: 0;
    ${breakpoint('xs')`
    object-position: 0 -25px;
  `}
    ${breakpoint('sm')`
    object-position: 0 1px;
  `}
  }

  .image-wrapper {
    overflow: hidden;
    margin-bottom: 25px;
    ${breakpoint('xs')`
      height: 200px;
  `}
    ${breakpoint('sm')`
      height: auto;
  `}
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }

  ${breakpoint('xs')`
    margin: 25px 0;
  `}

  ${breakpoint('md')`
    margin: 0;
  `}
`;

const RelatedProjectImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
  object-position: 0 1px;
  cursor: pointer;
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
`;

function RelatedProject(props) {
  const {
    related_project_client,
    related_project_image,
    related_project_name,
    related_project_link,
  } = props.data.primary;
  return (
    <Col xs={12} sm={4}>
      <InViewMonitor
        classNameNotInView="animated fadeOutUpZoomOut"
        classNameInView="animated fadeOutIn"
      >
        <ImageContainer as={Link} to={`/work/${related_project_link.uid}`}>
          <div className="image-wrapper">
            <RelatedProjectImage alt="" src={related_project_image.url} />
          </div>
          <h6>{related_project_client[0].text}</h6>
          <h4>{related_project_name[0].text}</h4>
        </ImageContainer>
      </InViewMonitor>
    </Col>
  );
}
export default React.memo(RelatedProject);
