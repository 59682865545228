import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InViewMonitor from 'react-inview-monitor';
import ReactPlayer from 'react-player';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import breakpoint from 'styled-components-breakpoint';

const GalleryItemStyled = styled.section`
  ${breakpoint('xs')`
    margin: 50px auto;
  `}

  ${breakpoint('md')`
    margin: 155px auto 100px;
  `}

  &:last-of-type {
    margin-bottom: 0;
  }
  h3 {
    margin-bottom: 50px;
  }
  p {
    margin-bottom: 100px;
  }
  img {
    width: 100%;
    ${breakpoint('xs')`
      margin: 0 0 50px;
    `}

    ${breakpoint('md')`
      margin: 0 0 100px;
    `}

    /* &:last-of-type {
      margin-bottom: 0;
    } */
  }
  .player {
    width: 100%;
  }
`;

const Container = styled(Col)`
  overflow: hidden;
  max-width: 100%;
`;

function GalleryItem(props) {
  function renderContent() {
    const { items } = props.data;
    return items.map((item, index) => {
      let galleryVideo = null;
      if (item.gallery_video && item.gallery_video.url) {
        galleryVideo = (
          <ReactPlayer
            muted={true}
            loop={true}
            volume={0}
            preload="true"
            playing={true}
            url={item.gallery_video.url}
            className="player"
            width="100%"
            height="auto"
            playsinline={true}
          />
        );
      }
      return (
        <InViewMonitor
          classNameNotInView="animated fadeOutUpZoomOut"
          classNameInView="animated fadeOutIn"
          key={index}
        >
          {galleryVideo}
          <img key={index} alt="" src={item.gallery_image.url} />
        </InViewMonitor>
      );
    });
  }

  const { primary, items } = props.data;
  return (
    <Grid>
      <GalleryItemStyled className="gallery-item">
        <Row>
          <Col xs={1} md={2} />
          <Col xs={10} md={8}>
            <InViewMonitor
              classNameNotInView="animated fadeOutUpBlur"
              classNameInView="animated fadeInUp"
            >
              {primary.gallery_title[0] && (
                <h3>{primary.gallery_title[0].text}</h3>
              )}
            </InViewMonitor>
            <InViewMonitor
              classNameNotInView="animated fadeOutUp "
              classNameInView="animated fadeInUp delay-1"
            >
              {primary.gallery_subtitle[0] && (
                <p>{primary.gallery_subtitle[0].text}</p>
              )}
            </InViewMonitor>
          </Col>
          <Col xs={1} md={2} />
        </Row>

        {items.length > 0 && (
          <InViewMonitor
            classNameNotInView="animated fadeOutUp"
            classNameInView="animated fadeInUp"
          >
            <Row className="container">
              <Container xs={12}>{renderContent()}</Container>
            </Row>
          </InViewMonitor>
        )}
      </GalleryItemStyled>
    </Grid>
  );
}

export default React.memo(GalleryItem);
