import React, { useContext } from 'react';
import { Route, Switch, __RouterContext, withRouter } from 'react-router-dom';
import * as easings from 'd3-ease';
import { useTransition, animated, config } from 'react-spring';
import withAnalytics from './analytics';
import VideoContext from './VideoContext';

import NotFound from '../Pages/NotFound/NotFound';
import WorkDetail from '../Pages/WorkDetail/WorkDetail';
import NewsArticle from '../Pages/NewsArticle/NewsArticle';
import Careers from '../Pages/Careers/Careers';
import Contact from '../Pages/Contact/Contact';
import Footer from '../Pages/Footer/Footer';
import Header from '../Pages/Header/Header';
import News from '../Pages/News/News';
import WhatWeDo from '../Pages/WhatWeDo/WhatWeDo';
import WhoWeAre from '../Pages/WhoWeAre/WhoWeAre';
import CareerPost from '../Pages/CareerPost/CareerPost';
import ThreeDShowcase from '../Pages/3DShowcase/ThreeDShowcase';
import FullScreenVideo from '../Pages/FullScreenVideo/FullScreenVideo';
import Home from '../Pages/Home/Home';
import WorkList from '../Pages/WorkList/WorkList';

function App(prismicProps) {
  const { location } = useContext(__RouterContext);
  const isWorkList =
    location.pathname.split('/')[1] === 'work' &&
    !location.pathname.split('/')[2];
  const transitions = useTransition(location, location => location.pathname, {
    from: {
      opacity: 0,
      transform: isWorkList ? 'translate(0%, 0%)' : 'translate(0%, 0%)',
    },
    enter: {
      opacity: 1,
      transform: 'translate(0%, 0%)',
    },
    leave: [
      {
        opacity: 1,
        transform: 'translate(0%, 0%)',
      },
      {
        opacity: 0,
        transform: isWorkList ? 'translate(0%, 10%)' : 'translate(0%, 5%)',
      },
    ],
    config: { mass: 1, tension: 269, friction: 52 },
  });
  return (
    <VideoContext.Provider>
      <FullScreenVideo />
      <Header />
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} style={props}>
          <Switch location={item}>
            <Route
              exact
              path="/"
              render={routeProps => (
                <Home {...routeProps} prismicCtx={prismicProps.prismicCtx} />
              )}
            />
            <Route
              exact
              path="/work"
              render={routeProps => (
                <WorkList
                  {...routeProps}
                  prismicCtx={prismicProps.prismicCtx}
                />
              )}
            />
            <Route
              exact
              path="/work/:uid"
              render={routeProps => (
                <WorkDetail
                  {...routeProps}
                  prismicCtx={prismicProps.prismicCtx}
                />
              )}
            />
            <Route
              exact
              path="/insights"
              render={routeProps => (
                <News {...routeProps} prismicCtx={prismicProps.prismicCtx} />
              )}
            />
            <Route
              exact
              path="/insights/:uid"
              render={routeProps => (
                <NewsArticle
                  {...routeProps}
                  prismicCtx={prismicProps.prismicCtx}
                />
              )}
            />
            <Route
              exact
              path="/careers"
              component={() => {
                window.location.href = 'https://www.weareenvoy.com/careers';
                return null;
              }}
            />
            <Route
              exact
              path="/careers/:uid"
              component={() => {
                window.location.href = 'https://www.weareenvoy.com/careers';
                return null;
              }}
            />
            <Route
              exact
              path="/Contact"
              component={() => {
                window.location.href = 'https://www.weareenvoy.com/contact';
                return null;
              }}
            />
            <Route
              exact
              path="/what-we-do"
              render={routeProps => (
                <WhatWeDo
                  {...routeProps}
                  prismicCtx={prismicProps.prismicCtx}
                />
              )}
            />
            <Route
              exact
              path="/who-we-are"
              render={routeProps => (
                <WhoWeAre
                  {...routeProps}
                  prismicCtx={prismicProps.prismicCtx}
                />
              )}
            />
            <Route
              exact
              path="/showcase/:uid"
              render={routeProps => (
                <ThreeDShowcase
                  {...routeProps}
                  prismicCtx={prismicProps.prismicCtx}
                />
              )}
            />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </animated.div>
      ))}
    </VideoContext.Provider>
  );
}

export default withRouter(withAnalytics(App));
