import React, { Component } from 'react';
import InViewMonitor from 'react-inview-monitor';
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const PracticeAreasWrapper = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  background: #0f0f0f;
  margin: 170px 0;
  ${breakpoint('xs')`
     margin: 100px 0;
  `}
  ${breakpoint('md')`
     margin: 170px 0;
     padding: 7em 0;
  `}
`;

const LinkStyled = styled(Link)`
  padding-bottom: 10px;
  margin-top: 60px;
  ${breakpoint('xs')`
    font-size: 12px;
    letter-spacing: .02em;
  `}
  
  ${breakpoint('md')`
    font-size: 13px;
  `}
  
  ${breakpoint('lg')`
    font-size: 14px;
    letter-spacing: .05em;
  `}

  ${breakpoint('xl')`
    font-size: 16px;
  `}
`;

class PracticeAreas extends Component {
  render() {
    const { practice_area_description, practice_area_title } = this.props.data;
    return (
      <PracticeAreasWrapper>
        <Grid>
          <InViewMonitor
            classNameNotInView="animated fadeOutUp"
            classNameInView="animated fadeInUp"
          >
            <Row>
              <Col xs={1} sm={1} md={2} />
              <Col xs={12} sm={10} md={8}>
                {RichText.render(practice_area_title)}
              </Col>
              <Col xs={1} sm={1} md={2} />
            </Row>
          </InViewMonitor>
          <InViewMonitor
            classNameNotInView="animated fadeOutUp"
            classNameInView="animated fadeInUp"
          >
            <Row>
              <Col xs={1} sm={1} md={2} />
              <Col xs={12} sm={10} md={8}>
                {RichText.render(practice_area_description)}
              </Col>
              <Col xs={1} sm={1} md={2} />
            </Row>
          </InViewMonitor>
          <InViewMonitor
            classNameNotInView="animated fadeOutUp"
            classNameInView="animated fadeInUp"
          >
            <Row>
              <Col xs={1} sm={1} md={2} />
              <Col xs={12} sm={10} md={8}>
                <LinkStyled className="underline" to="/what-we-do">
                  View our practice areas
                </LinkStyled>
              </Col>
              <Col xs={1} sm={1} md={2} />
            </Row>
          </InViewMonitor>
        </Grid>
      </PracticeAreasWrapper>
    );
  }
}

export default PracticeAreas;
