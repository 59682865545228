import React, { Component } from 'react';
import styled from 'styled-components';
import InViewMonitor from 'react-inview-monitor';
import { RichText } from 'prismic-reactjs';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';

const LargeTextStyled = styled.section`
  margin: 100px 0;
`;

function LargeText(props) {
  const { large_text } = props.data.primary;
  return (
    <LargeTextStyled>
      <Grid>
        <InViewMonitor
          classNameNotInView="animated fadeOutUp"
          classNameInView="animated fadeInUp"
        >
          <Row>
            <Col xs={1} md={2} />
            <Col xs={10} md={8}>
              {RichText.render(large_text)}
            </Col>
            <Col xs={1} md={2} />
          </Row>
        </InViewMonitor>
      </Grid>
    </LargeTextStyled>
  );
}

export default React.memo(LargeText);
