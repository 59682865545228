import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { Keyframes, Spring, animated } from 'react-spring/renderprops';
import * as easings from 'd3-ease';
import delay from 'delay';

import allWork from '../../assets/menu/allwork.jpg';
import careers from '../../assets/menu/careers.jpg';
import contact from '../../assets/menu/contact.jpg';
import news from '../../assets/menu/news.jpg';
import whatwedo from '../../assets/menu/whatwedo.jpg';
import whoweare from '../../assets/menu/whoweare.jpg';

import BackgroundImage from './BackgroundImage';

const Overlay = Keyframes.Spring({
  open: { delay: 0, y: 0 },
  close: async call => {
    await delay(500);
    await call({ delay: 0, y: -110 });
  },
});

const Content = Keyframes.Trail({
  open: async call => {
    await delay(250);
    await call({ y: 0, opacity: 1 });
  },
  close: { y: 30, opacity: 0, delay: 0 },
});

const Navigation = styled(animated.div)`
  background: #0f0f0f;
  position: absolute;
  top: 0%;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -5;
`;

// const CanvasStyled = styled.canvas`
//   height: 100%;
//   width: 100%;
//   position: absolute;
//   left: 0%;
//   bottom: 0%;
//   z-index: -1;
// `;

// const BackgroundImageStyled = styled.img`
//   height: 120%;
//   width: 100%;
//   object-fit: cover;
//   position: absolute;
//   top: -10%;
//   left: 0%;
//   filter: opacity(0.4);

//   ${breakpoint('xs')`
//     display: none;
//   `}

//   ${breakpoint('lg')`
//     display: block;
//   `}
// `;

const Inner = styled.div`
  position: relative;
  top: 50%;

  ${breakpoint('xs')`
    transform: translateY(-60%);
  `}

${breakpoint('sm')`
    transform: translateY(-50%);
  `}

  .nav-item {
    display: inline-block;
    h2,
    h3 {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .primary-link-title {
    font-size: 40px;
    margin: 0.75rem auto;
    ${breakpoint('xs')`
    font-size: 22px;
  `}
    ${breakpoint('md')`
    font-size: 40px;
  `}
  }

  .secondary-link-title {
    font-size: 30px;
    margin: 0.5rem auto;
    ${breakpoint('xs')`
    font-size: 18px;
  `}
    ${breakpoint('md')`
    font-size: 30px;
  `}
  }

}
`;

const StyledLink = styled(Link)`
  h2,
  h3 {
    transition: color 0.3s ease-in-out;
    color: ${props => {
      if (!props.ishover) {
        return 'rgba(255,255,255,1)';
      } else if (props.ishover && props.isactive) {
        return 'rgba(255,255,255,1)';
      } else {
        return 'rgba(255,255,255,0.4)';
      }
    }};
  }
`;

// const WorkDetailStyled = styled.div`
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   margin-bottom: 60px;

//   .link-categories {
//     font-weight: 100;
//     margin-bottom: 7px;
//   }
// `;

const ButtonContainer = styled.div`
  position: relative;
`;

const MenuButton = styled.div`
  height: 20px;
  width: 20px;
  z-index: 100;
  cursor: pointer;
  overflow: hidden;
  transition: 0.5s;

  span {
    display: block;
    width: 20px;
    height: 2px;
    background-color: white;
    margin: 6px 0;
    transition: 0.5s;
  }

  &.close {
    text-align: right;
    &:hover {
      span {
        &:nth-child(1) {
          transform: translateX(5px);
        }
        &:nth-child(2) {
          transform: translateX(-5px);
        }
        &:nth-child(3) {
          transform: translateX(5px);
        }
      }
    }
  }

  &.open {
    span {
      &:nth-child(1) {
        transform: rotate(-45deg) translate(-4px, 3px);
      }
      &:nth-child(2) {
        transform: rotate(45deg) translate(-2px, -2px);
      }
    }

    &:hover {
      opacity: 0.6;
    }
  }
`;

export default class Nav extends Component {
  constructor(props) {
    super(props);
    this.viewHeight = null;
    this.viewWidth = null;
    this.ctx = null;
    this.patternSize = 180;
    this.patternScaleX = 1;
    this.patternScaleY = 1;
    this.patternRefreshInterval = 1;
    this.patternAlpha = 40; // int between 0 and 255
    this.patternPixelDataLength = this.patternSize * this.patternSize * 4;
    this.patternCanvas = null;
    this.patternCtx = null;
    this.patternData = null;
    this.frame = 0;
    this.canvasRef = React.createRef();
  }
  state = {
    open: false,
    isGridVisible: false,
    backgroundImage: null,
    activeLink: '',
  };

  componentDidMount() {
    // document.addEventListener('keydown', this.handleKeyDown);
  }
  componentWillReceiveProps(newProps) {
    if (newProps.open !== this.props.open) {
      this.setState({ open: this.props.open });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // if (!prevProps.isMenuOpen && this.props.isMenuOpen) {
    //   this.initCanvas();
    //   this.initGrain();
    //   this.loop();
    // }
  }
  componentWillUnmount() {
    // document.removeEventListener('keydown', this.handleKeyDown);
    // this.stopLoop();
  }

  handleKeyDown = e => {
    switch (e.keyCode) {
      case 27: // ESC for close
        this.closeNav();
        break;
      default:
        break;
    }
  };

  toggleNav = () => {
    this.props.onMenuToggle();
    if (!this.props.isMenuOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style = '';
    }
  };

  closeNav = () => {
    document.body.style = '';
  };

  handleImageChange(backgroundImage, linkName) {
    this.setState(() => {
      return { backgroundImage: backgroundImage };
    });
    setTimeout(
      function() {
        this.setState({ activeLink: linkName });
      }.bind(this),
      0
    );
  }

  resetImage() {
    this.setState(() => {
      return { backgroundImage: null };
    });
    setTimeout(
      function() {
        this.setState({ activeLink: '' });
      }.bind(this),
      0
    );
  }

  render() {
    const state = this.props.isMenuOpen ? 'open' : 'close';
    const MenuItems = [
      <StyledLink
        isactive={this.state.activeLink === 'work' ? 1 : 0}
        ishover={this.state.activeLink !== '' ? 1 : 0}
        key="work"
        className="nav-item"
        to="/work"
      >
        <h2
          onMouseOver={() => this.handleImageChange(allWork, 'work')}
          onMouseOut={() => this.resetImage()}
          className="primary-link-title"
        >
          Work
        </h2>
      </StyledLink>,
      // <StyledLink isactive={this.state.activeLink ===} className="link-categories" to="/work" key="work" >
      //   All work
      // </StyledLink>,
      // <StyledLink isactive={this.state.activeLink ===} className="link-categories" to="/work/retail" key="work--retail" >
      //   Retail
      // </StyledLink>,
      // <StyledLink isactive={this.state.activeLink ===} className="link-categories" to="/work/events" key="work--events" >
      //   Events
      // </StyledLink>,
      // <StyledLink isactive={this.state.activeLink ===} className="link-categories" to="/work/public-spaces" key="work--public-spaces" >
      //   Public spaces
      // </StyledLink>,
      // <StyledLink isactive={this.state.activeLink ===}
      //   className="link-categories"
      //   to="/work/themed-entertainment"
      //   key="work--themed-entertainment"
      //
      // >
      //   Themed entertainment
      // </StyledLink>,
      // <StyledLink isactive={this.state.activeLink ===}
      //   className="link-categories"
      //   to="/work/branded-enviornments"
      //   key="work--branded-enviornments"
      //
      // >
      //   Branded enviornments
      // </StyledLink>,
      <StyledLink
        isactive={this.state.activeLink === 'who-we-are' ? 1 : 0}
        ishover={this.state.activeLink !== '' ? 1 : 0}
        className="nav-item"
        to="/who-we-are"
        key="who-we-are"
      >
        <h2
          onMouseOver={() => this.handleImageChange(whoweare, 'who-we-are')}
          onMouseOut={() => this.resetImage()}
          className="primary-link-title"
        >
          Who we are
        </h2>
      </StyledLink>,
      <StyledLink
        isactive={this.state.activeLink === 'what-we-do' ? 1 : 0}
        ishover={this.state.activeLink !== '' ? 1 : 0}
        className="nav-item"
        to="/what-we-do"
        key="what-we-do"
      >
        <h2
          onMouseOver={() => this.handleImageChange(whatwedo, 'what-we-do')}
          onMouseOut={() => this.resetImage()}
          className="primary-link-title"
        >
          What we do
        </h2>
      </StyledLink>,
      <StyledLink
        isactive={this.state.activeLink === 'news' ? 1 : 0}
        ishover={this.state.activeLink !== '' ? 1 : 0}
        className="nav-item"
        to="/insights"
        key="insights"
      >
        <h3
          onMouseOver={() => this.handleImageChange(news, 'news')}
          onMouseOut={() => this.resetImage()}
          className="secondary-link-title"
        >
          Insights
        </h3>
      </StyledLink>,
      <StyledLink
        isactive={this.state.activeLink === 'careers' ? 1 : 0}
        ishover={this.state.activeLink !== '' ? 1 : 0}
        className="nav-item"
        to="/careers"
        key="careers"
      >
        <h3
          onMouseOver={() => this.handleImageChange(careers, 'careers')}
          onMouseOut={() => this.resetImage()}
          className="secondary-link-title"
        >
          Careers
        </h3>
      </StyledLink>,
      <StyledLink
        isactive={this.state.activeLink === 'contact' ? 1 : 0}
        ishover={this.state.activeLink !== '' ? 1 : 0}
        className="nav-item"
        to="/contact"
        key="contact"
      >
        <h3
          onMouseOver={() => this.handleImageChange(contact, 'contact')}
          onMouseOut={() => this.resetImage()}
          className="secondary-link-title"
        >
          Contact
        </h3>
      </StyledLink>,
    ];
    return (
      <>
        <ButtonContainer>
          <Spring
            native
            delay={1000}
            from={{ opacity: 0, transform: 'translate3d(0, -40px, 0)' }}
            to={{ opacity: 1, transform: 'translate3d(0, 0px, 0)' }}
            config={{ tension: 180, friction: 50, easing: easings.easeCubic }}
          >
            {props => (
              <animated.div style={props}>
                <MenuButton className={state} onClick={this.toggleNav}>
                  <span />
                  <span />
                </MenuButton>
              </animated.div>
            )}
          </Spring>
        </ButtonContainer>

        <Overlay native state={state}>
          {({ y }) => (
            <>
              <Navigation
                style={{
                  transform: y.interpolate(y => `translate3d(0,${y}%,0)`),
                }}
              >
                {this.state.backgroundImage && (
                  <Spring
                    from={{ opacity: 0, transform: 'translate3d(0, 40px, 0)' }}
                    to={{ opacity: 1, transform: 'translate3d(0, 0px, 0)' }}
                    reverse={!this.state.backgroundImage}
                    reset
                    config={{
                      tension: 180,
                      friction: 100,
                      easing: easings.easeCubic,
                    }}
                  >
                    {props => (
                      <animated.div>
                        <BackgroundImage
                          styleProps={props}
                          src={this.state.backgroundImage}
                        />
                      </animated.div>
                    )}
                  </Spring>
                )}

                <Inner>
                  <Grid>
                    <Content
                      native
                      items={MenuItems}
                      keys={item => item.key}
                      reverse={!this.props.isMenuOpen}
                      state={state}
                    >
                      {item => ({ y, opacity }) => (
                        <animated.div
                          onClick={this.toggleNav}
                          style={{
                            opacity,
                            transform: y.interpolate(
                              y => `translate3d(0,${y}px,0)`
                            ),
                          }}
                        >
                          <Row>
                            <Col>{item}</Col>
                          </Row>
                        </animated.div>
                      )}
                    </Content>
                  </Grid>
                </Inner>
              </Navigation>
            </>
          )}
        </Overlay>
      </>
    );
  }
}
