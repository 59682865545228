import React, { Component } from 'react';
import NotFound from '../NotFound/NotFound';
import GalleryItem from '../Components/GalleryItem';
import Quote from '../Components/Quote';
import Image from '../Components/Image';
import { RichText } from 'prismic-reactjs';
import { Helmet } from 'react-helmet';
import Prismic from 'prismic-javascript';
import axios from 'axios';
import styled from 'styled-components';
import LoadingPage from '../LoadingPage/LoadingPage';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import InViewMonitor from 'react-inview-monitor';
import { animateScroll, Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import breakpoint from 'styled-components-breakpoint';
import { kebabCase } from 'lodash';
import ScopeOfWork from '../WorkDetail/Components/ScopeOfWork';
import PhotoGallery from '../Components/PhotoGallery';
import HorizontalQuotes from '../Components/HorizontalQuotes';

const FeaturedHeader = styled.div`
  position: relative;
  width: 100vw;
`;

const TitleOverlay = styled.div`
  
  ${breakpoint('xs')`
    margin: 150px auto 75px;
    `}

  ${breakpoint('md')`
    margin: 150px auto;
    `}

  ${breakpoint('md')`
    position: absolute;
    top: 40%;
    z-index: 100;
    `}
`;
const HeroImageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const HeroGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  ${breakpoint('xs')`
    height: 50vh;
    transform: translateY(0%) scale(1);
  `}

  ${breakpoint('md')`
    height: 100vh;
  transform: translateY(-10%) scale(1.2);

  `}
`;

const StyledJobTitle = styled.li`
  ${breakpoint('xs')`
    &:first-of-type {
      margin-top: 8em;
    }
  `}

  ${breakpoint('md')`
    &:first-of-type {
      margin-top: 0em;
    }
  `}
`;

const HeroImageStyled = styled.img`
  width: 100%;
  z-index: -1;
  object-fit: cover;

  ${breakpoint('xs')`
    height: 50vh;
    transform: translateY(0%) scale(1);
  `}

  ${breakpoint('md')`
    height: 100vh;
  transform: translateY(-10%) scale(1.2);

  `}
`;

const FallbackWrapper = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  margin: 100px 0;
  ${breakpoint('xs')`
     margin: 100px 0;
  `}
  ${breakpoint('md')`
     margin: 100px 0;
     padding: 7em 0;
  `}
  h3 {
    margin-bottom: 50px;
  }
`;

const LinkStyled = styled.a`
  padding-bottom: 10px;
  margin-top: 60px;
  color: white;
  ${breakpoint('xs')`
    font-size: 12px;
    letter-spacing: .02em;
  `}
  
  ${breakpoint('md')`
    font-size: 13px;
  `}
  
  ${breakpoint('lg')`
    font-size: 14px;
    letter-spacing: .05em;
  `}

  ${breakpoint('xl')`
    font-size: 16px;
  `}
`;

const TextBlock = styled.div`
  margin: 10rem 0;
  .see-all {
    ${breakpoint('lg')`
    font-size: 16px;
  `}
  }
`;

const SectionTitle = styled.h2`
  margin-bottom: 40px;
`;

const WhyLeviathanWrapper = styled(Row)`
  margin-top: 7rem;
  h3 {
    ${breakpoint('xs')`
    margin-bottom: 50px;
  `}

    ${breakpoint('md')`
    margin-bottom: 90px
  `}
  }
`;
const WhyLeviathanTextWrapper = styled(Row)`
  margin-top: 4rem;
`;
export default class Careers extends Component {
  state = {
    doc: null,
    notFound: false,
    jobs: [],
  };

  componentWillMount() {
    this.fetchPage(this.props);
  }

  componentWillReceiveProps(props) {
    this.fetchPage(props);
  }
  componentDidMount() {
    animateScroll.scrollToTop({
      duration: 0,
      delay: 750,
    });
  }

  async fetchPage(props) {
    if (props.prismicCtx) {
      const res = await props.prismicCtx.api.query(
        Prismic.Predicates.at('document.type', 'careers')
      );
      this.setState({ doc: res.results[0] });
    }
    const jobs = await axios.get(
      'https://boards-api.greenhouse.io/v1/boards/weareenvoy/jobs'
    );

    this.setState({ jobs: jobs.data.jobs });
    return null;
  }

  getJobOpenings() {
    if (!this.state.jobs) {
      return;
    }
    return this.state.jobs.filter(job => job.location.name === 'Chicago');
  }

  renderJobOpenings() {
    if (this.state.jobs) {
      return this.getJobOpenings().map((job, index) => {
        return (
          <ul key={index}>
            <StyledJobTitle>
              <RouterLink
                to={{
                  pathname: `/careers/${kebabCase(job.title)}`,
                  state: { jobId: job.id },
                }}
              >
                {job.title}
              </RouterLink>
            </StyledJobTitle>
          </ul>
        );
      });
    }
    return null;
  }

  renderBody() {
    let els = [];
    if (this.state.doc) {
      els = this.state.doc.data.body.map((slice, index) => {
        switch (slice.slice_type) {
          case 'horizontal_scroll':
            return <HorizontalQuotes key={index} data={slice} />;
          case 'photo_gallery':
            return <PhotoGallery key={index} data={slice} />;
          case 'gallery_item':
            return <GalleryItem key={index} data={slice} />;
          case 'image':
            return <Image key={index} data={slice} />;
          case 'quote':
            return <Quote key={index} data={slice} />;
          default:
            return null;
        }
      });
    }
    els[2] = (
      <Grid key={'whyleviathanwrapper'}>
        <WhyLeviathanWrapper>
          <Col xs={1} />
          <Col xs={10}>
            <h3>Why Leviathan</h3>
            <WhyLeviathanTextWrapper>
              {this.renderWhyLeviathan()}
            </WhyLeviathanTextWrapper>
          </Col>
          <Col xs={1} />
        </WhyLeviathanWrapper>
      </Grid>
    );
    return els;
  }

  renderWhyLeviathan() {
    const { body } = this.state.doc.data;
    return body
      .filter(item => item.slice_type === 'scope_of_work')
      .map((project, i) => (
        <ScopeOfWork isCareers={true} key={i} data={project} />
      ));
  }

  renderHeader() {
    const { doc } = this.state;
    if (this.getJobOpenings().length > 0) {
      return (
        <InViewMonitor
          classNameNotInView="animated fadeOutUp"
          classNameInView="animated fadeInUp"
        >
          <TextBlock>
            <Grid>
              <Row>
                <Col xs={false} sm={1} />
                <Col xs={12} sm={4}>
                  <SectionTitle>{`Current openings`}</SectionTitle>
                  {RichText.render(doc.data.careers_description)}
                </Col>

                <Col xs={false} sm={2} />
                <Col between="xs" xs={12} sm={4} className="job openings">
                  <InViewMonitor
                    classNameNotInView="animated fadeOutUp delay-2"
                    classNameInView="animated fadeInUp"
                  >
                    <div>{this.renderJobOpenings()}</div>
                    <LinkStyled
                      className="underline"
                      href="https://www.weareenvoy.com/careers"
                      target="_blank"
                    >
                      See all Envoy Group positions
                    </LinkStyled>
                  </InViewMonitor>
                </Col>
                <Col xs={false} sm={1} />
              </Row>
            </Grid>
          </TextBlock>
        </InViewMonitor>
      );
    }
    return (
      <FallbackWrapper>
        <Grid>
          <InViewMonitor
            classNameNotInView="animated fadeOutUp"
            classNameInView="animated fadeInUp"
          >
            <Row>
              <Col xs={1} sm={1} md={2} />
              <Col xs={12} sm={10} md={8}>
                {RichText.render(doc.data.fallback_title)}
              </Col>
              <Col xs={1} sm={1} md={2} />
            </Row>
          </InViewMonitor>
          <InViewMonitor
            classNameNotInView="animated fadeOutUp"
            classNameInView="animated fadeInUp"
          >
            <Row>
              <Col xs={1} sm={1} md={2} />
              <Col xs={12} sm={10} md={8}>
                {RichText.render(doc.data.fallback_description)}
              </Col>
              <Col xs={1} sm={1} md={2} />
            </Row>
          </InViewMonitor>
          <InViewMonitor
            classNameNotInView="animated fadeOutUp"
            classNameInView="animated fadeInUp"
          >
            <Row>
              <Col xs={1} sm={1} md={2} />
              <Col xs={12} sm={10} md={8}>
                <LinkStyled
                  className="underline"
                  href="mailto:careers@weareenvoy.com."
                >
                  Email us at careers@weareenvoy.com.
                </LinkStyled>
              </Col>
              <Col xs={1} sm={1} md={2} />
            </Row>
          </InViewMonitor>
        </Grid>
      </FallbackWrapper>
    );
  }

  render() {
    const { doc } = this.state;

    if (doc) {
      return (
        <>
          <Helmet>
            <title>Careers</title>
          </Helmet>
          <FeaturedHeader>
            <Grid>
              <Row middle="xs" between="xs">
                <Col xs={12}>
                  <TitleOverlay>
                    <InViewMonitor
                      classNameNotInView="animated fadeOutUp"
                      classNameInView="animated fadeInUp delay-2"
                    >
                      {RichText.render(doc.data.careers_title)}
                    </InViewMonitor>
                  </TitleOverlay>
                </Col>
              </Row>
            </Grid>
            <Row xs={12}>
              <InViewMonitor
                classNameNotInView="animated fadeOutUp"
                classNameInView="animated fadeInUp"
              >
                <HeroImageWrapper>
                  <HeroGradient />
                  <HeroImageStyled
                    alt=""
                    src={doc.data.careers_hero_image.url}
                  />
                </HeroImageWrapper>
              </InViewMonitor>
            </Row>
          </FeaturedHeader>
          {this.renderHeader()}
          {this.renderBody()}
        </>
      );
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return <LoadingPage />;
  }
}
