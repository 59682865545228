import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { pick } from 'lodash';
import NotFound from '../NotFound/NotFound';
import Header from './Components/Header';
import HeroVideo from './Components/HeroVideo';
import Video from './Components/Video';
import WorkStatement from './Components/WorkStatement';
import GalleryItem from '../Components/GalleryItem';
import ImageGrid from './Components/ImageGrid';
import Quote from '../Components/Quote';
import ScopeOfWork from './Components/ScopeOfWork';
import Image from '../Components/Image';
import RelatedProject from './Components/RelatedProject';
import LargeText from './Components/LargeText';
import styled from 'styled-components';
import LoadingPage from '../LoadingPage/LoadingPage';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import breakpoint from 'styled-components-breakpoint';
import { animateScroll } from 'react-scroll';
import InViewMonitor from 'react-inview-monitor';
import HorizontalQuotes from '../Components/HorizontalQuotes';
import HorizontalImages from '../Components/HorizontalImages';

const WorkDetailTitle = styled.h3`
  ${breakpoint('xs')`
    margin: 0 0 2rem;
  `}

  ${breakpoint('md')`
    margin: 0 0 5rem;
  `}
`;

const ScopeContainer = styled.div`
  ${breakpoint('xs')`
    margin: 160px auto;
  `}

  ${breakpoint('lg')`
    margin: 320px auto;
  `}
`;

const ViewAllLink = styled.a`
  ${breakpoint('xs')`
    display: none;
  `}

  ${breakpoint('md')`
    display: inline-block;
  `}
`;

const ViewAllLinkMobile = styled.a`
  text-align: center;
  margin: 40px auto 0;

  ${breakpoint('xs')`
    display: inline-block;
  `}

  ${breakpoint('md')`
    display: none;
  `}
`;

export default class WorkDetail extends React.Component {
  state = {
    doc: null,
    notFound: false,
    isEditable: true,
  };

  componentWillMount() {
    this.fetchPage(this.props);
  }

  componentDidMount() {
    animateScroll.scrollToTop({
      duration: 0,
      delay: 750,
    });
  }

  componentWillReceiveProps(props) {
    this.fetchPage(props);
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      return props.prismicCtx.api.getByUID(
        'work_detail',
        props.match.params.uid,
        {},
        (err, doc) => {
          if (doc) {
            this.setState({ doc });
          } else {
            this.setState({ notFound: !doc });
          }
        }
      );
    }
    return null;
  }

  filterComponentData(section) {
    const { data } = this.state.doc;
    let allowed;
    switch (section) {
      case 'header':
        allowed = ['capabilities', 'project', 'project_detail'];
        return pick(data, allowed);
      case 'hero-video':
        allowed = [
          'work_detail_video',
          'work_detail_video_image',
          'hero_video',
        ];
        return pick(data, allowed);
      case 'work-statement':
        allowed = ['work_statement_detail', 'work_statement_title'];
        return pick(data, allowed);
      default:
        return null;
    }
  }

  formatBody() {
    const { body } = this.state.doc.data;
    return body.map((item, i) => {
      switch (item.slice_type) {
        case 'horizontal_scroll':
          return <HorizontalQuotes key={i} data={item} />;
        case 'horizontal_images':
          return <HorizontalImages key={i} data={item} />;
        case 'gallery_item':
          return <GalleryItem key={i} data={item} />;
        case 'quote':
          return <Quote key={i} data={item} />;
        case 'image':
          return <Image key={i} data={item} />;
        case 'image_grid':
          return <ImageGrid key={i} data={item} />;
        case 'large_text':
          return <LargeText key={i} data={item} />;
        case 'video_':
          return <Video key={i} data={item} />;
        default:
          return null;
      }
    });
  }

  renderScopeOfWork() {
    const { body } = this.state.doc.data;
    return body
      .filter(item => item.slice_type === 'scope_of_work')
      .map((project, i) => <ScopeOfWork key={i} data={project} />);
  }

  renderRelatedProjects() {
    const { body } = this.state.doc.data;
    return body
      .filter(item => item.slice_type === 'related_project')
      .map((project, i) => <RelatedProject key={i} data={project} />);
  }

  render() {
    if (this.state.doc) {
      return (
        <React.Fragment>
          <Helmet>
            <title>{this.state.doc.data.project[0].text}</title>
          </Helmet>
          <Header
            tag={this.state.doc.tags[0]}
            data={this.filterComponentData('header')}
          />
          <HeroVideo data={this.filterComponentData('hero-video')} />
          <WorkStatement data={this.filterComponentData('work-statement')} />

          {this.formatBody()}

          <Grid>
            <ScopeContainer>
              <InViewMonitor
                classNameNotInView="animated fadeOutUpBlur"
                classNameInView="animated fadeInUp"
              >
                <Row>
                  <Col xs={2} />
                  <Col xs={8}>
                    <WorkDetailTitle>Scope of work</WorkDetailTitle>
                  </Col>
                  <Col xs={2} />
                </Row>
              </InViewMonitor>

              <Row>
                <Col xs={2} />
                <Col xs={9}>
                  <Row>{this.renderScopeOfWork()}</Row>
                </Col>
                <Col xs={1} />
              </Row>
            </ScopeContainer>
            <Row between="xs">
              <Col xs={12} sm={9}>
                <WorkDetailTitle>Related projects</WorkDetailTitle>
              </Col>
              <Col xs={6} sm={3} style={{ textAlign: 'right' }}>
                <ViewAllLink as={Link} className="underline" to="/work">
                  View all work
                </ViewAllLink>
              </Col>
            </Row>

            <Row>{this.renderRelatedProjects()}</Row>
            <Row>
              <ViewAllLinkMobile as={Link} className="underline" to="/work">
                View all work
              </ViewAllLinkMobile>
            </Row>
          </Grid>
        </React.Fragment>
      );
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return <LoadingPage />;
  }
}
