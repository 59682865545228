import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import InViewMonitor from 'react-inview-monitor';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';

class Video extends Component {
  render() {
    if (!this.props.data) {
      return null;
    }
    const videoUrl = this.props.data.items[0].video_loop.url;
    return (
      <InViewMonitor
        classNameNotInView="animated fadeOutUpZoomOut"
        classNameInView="animated fadeOutIn"
      >
        <Grid>
          <Row>
            <Col xs={12}>
              <ReactPlayer
                muted={true}
                loop={true}
                volume={0}
                preload="true"
                playing={true}
                url={videoUrl}
                width="100%"
                height="auto"
                playsinline={true}
              />
            </Col>
          </Row>
        </Grid>
      </InViewMonitor>
    );
  }
}

export default Video;
