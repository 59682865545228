import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

const HeroVideoImageWrapper = styled.div`
  position: relative;
  padding: 0 !important;
  height: 100%;
  overflow: hidden;
  max-height: 404px;
  cursor: pointer;
  video {
    transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: ${props => (props.isActive ? 'scale(1.05)' : 'scale(1)')};
    &:hover {
      transform: scale(1.05);
    }
  }
`;

class HoverVideoImage extends Component {
  state = {
    isPlaying: false,
  };

  componentDidMount() {
    if (window.innerWidth < 900) {
      this.setState(
        () => {
          return { isPlaying: true };
        },
        () => {
          this.setState(() => {
            return { isPlaying: false };
          });
        }
      );
    }
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  resize() {
    if (this.state.isPlaying === false) {
      return;
    } else if (this.state.isPlaying && window.innerWidth > 800) {
      this.setState({ isPlaying: false });
    }
  }

  setIsPlaying = () => {
    this.setState(prevState => {
      if (!prevState.isPlaying) {
        return { isPlaying: true };
      }
    });
  };

  turnOffIsPlaying = () => {
    this.setState(prevState => {
      if (prevState.isPlaying) {
        return { isPlaying: false };
      }
    });
  };

  render() {
    return (
      <HeroVideoImageWrapper
        onMouseOver={this.setIsPlaying}
        onMouseOut={this.turnOffIsPlaying}
        isActive={this.props.isPlaying}
        onClick={() => {
          this.props.history.push(this.props.link);
        }}
      >
        <ReactPlayer
          playing={this.state.isPlaying || this.props.isPlaying}
          url={this.props.url}
          onError={err => console.log(err)}
          muted={true}
          loop={true}
          playsinline={true}
          volume={0}
          preload="true"
          width="100%"
          height="100%"
        />
      </HeroVideoImageWrapper>
    );
  }
}

export default withRouter(HoverVideoImage);
