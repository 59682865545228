import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import breakpoint from 'styled-components-breakpoint';

// fonts
import GothamLightWoff from '../assets/fonts/Gotham-Light.woff';
import GothamLightWoff2 from '../assets/fonts/Gotham-Light.woff2';
import GothamBookWoff from '../assets/fonts/Gotham-Book.woff';
import GothamBookWoff2 from '../assets/fonts/Gotham-Book.woff2';
import GothamMediumWoff from '../assets/fonts/Gotham-Medium.woff';
import GothamMediumWoff2 from '../assets/fonts/Gotham-Medium.woff2';

const GlobalStyle = createGlobalStyle`
  ${reset}
  
  @font-face {
    font-family: 'Gotham';
    src: url('${GothamLightWoff2}') format('woff2'),
        url('${GothamLightWoff}') format('woff');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url('${GothamBookWoff2}') format('woff2'),
        url('${GothamBookWoff}') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url('${GothamMediumWoff2}') format('woff2'),
        url('${GothamMediumWoff}') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  ${'' /* TODO: move to theme in PrismicApp.js */}
  :root {
    --util-ease: cubic-bezier(.19,1,.22,1);
  }
  
  
  body {
    background: black;
    color: white;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: 'Gotham';
    margin:0;
    padding:0;
    font-weight: 100;
  }


  a {
    display: inline-block;
    text-decoration: none;
    color: rgba(255,255,255,1);
    letter-spacing: .025em;
    position: relative;
    cursor: pointer;
    transition: opacity 0.2s cubic-bezier(0.29, 0.18, 0.26, 0.83);

    ${breakpoint('xs')`
      font-size: 1rem;
      line-height: 1.5rem;
    `}
    
    ${breakpoint('md')`
      font-size: 1.125rem;
      line-height: 1.875rem;
    `}
    
    ${breakpoint('lg')`
    `}
    /* TODO GIVE THIS A MORE SPECIFIC CLASS NAME*/
    /* &:hover {
      opacity: 0.7;
    } */
    
    &.underline {
      &:hover {
        &::after,
        &::before {
          width: 0;
          right: -2px;
        }
      }

      &::after,
      &::before {
        content: "";
        position: absolute;
        top: 100%;
        transform: translateY(-50%);
        width: 100%;
        left: 0;
        height: 2px;
        pointer-events: none;
      }

      &::before {
        transition: width 0.4s cubic-bezier(0.51, 0.18, 0, 0.88) 0.1s;
        background: #0F0F0F;
      }

      &::after {
        transition: width 0.2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
        background: #FFF;
      }
    }
  }

  button {
    font-family: 'Gotham';
    background: none;
    box-shadow: 0 0 0;
    border: 0;
    &:active,
    &:focus {
      outline: none;
    }

    ${breakpoint('xs')`
      font-size: 12px;
      letter-spacing: .02em;
    `}
    
    ${breakpoint('md')`
      font-size: 13px;
    `}
    
    ${breakpoint('lg')`
      font-size: 14px;
      letter-spacing: .05em;
    `}

    ${breakpoint('xl')`
      font-size: 16px;
    `}
  }

  h1 {
    font-weight: 100;
    color: rgba(255,255,255,0.7);
    letter-spacing: 0.025em;

    strong {
      color: white;
    }

    ${breakpoint('xs')`
      font-size: 32px;
    `}
    
    ${breakpoint('md')`
      font-size: 50px;
    `}
    
    ${breakpoint('lg')`
      font-size: 62px;
    `}    
  }

  h2 {
    font-weight: 100;
    color: rgba(255,255,255,1);
    letter-spacing: .02em;
    line-height: 1.5;
    
    ${breakpoint('xs')`
      font-size: 16px;
    `}
    
    ${breakpoint('md')`
      font-size: 24px;
    `}
    
    ${breakpoint('lg')`
      font-size: 30px;
    `}

    ${breakpoint('xl')`
      font-size: 38px;
    `}
  }

  h3 {
    font-weight: 100;
    color: rgba(255,255,255,1);
    letter-spacing: .025em;
    line-height: 1.5;
    
    ${breakpoint('xs')`
      font-size: 14px;
    `}
    
    ${breakpoint('md')`
      font-size: 18px;
    `}
    
    ${breakpoint('lg')`
      font-size: 24px;
    `}

    ${breakpoint('xl')`
      font-size: 30px;
    `}
  }

  h4 {
    font-weight: 100;
    color: rgba(255,255,255,1);
    letter-spacing: .025em;
    line-height: 1.5;
    
    ${breakpoint('xs')`
      font-size: 14px;
    `}
    
    ${breakpoint('md')`
      font-size: 14px;
    `}
    
    ${breakpoint('lg')`
      font-size: 18px;
    `}

    ${breakpoint('xl')`
      font-size: 20px;
    `}
  }

  h5 {
    font-weight: 100;
    color: rgba(255,255,255,1);
    letter-spacing: .05em;
    line-height: 1.7;

    ${breakpoint('xs')`
      font-size: 11px;
    `}
    
    ${breakpoint('md')`
      font-size: 14px;
    `}
    
    ${breakpoint('lg')`
      font-size: 16px;
    `}

    ${breakpoint('xl')`
      font-size: 19px;
    `}
  }

  h6 {
    font-weight: 400;
    color: rgba(255,255,255,0.6);
    line-height: 1.5;
    
    ${breakpoint('xs')`
      font-size: 10px;
      letter-spacing: .02em;
    `}
    
    ${breakpoint('md')`
      font-size: 12px;
    `}
    
    ${breakpoint('lg')`
      font-size: 13px;
      letter-spacing: .05em;
    `}

    ${breakpoint('xl')`
      font-size: 15px;
    `}
  }

  p, li,
  p a {
    font-weight: 400;
    color: rgba(255,255,255,0.7);
    line-height: 1.7;
    
    ${breakpoint('xs')`
      font-size: 13px;
      letter-spacing: .01em;
    `}
    
    ${breakpoint('md')`
      font-size: 14px;
    `}
    
    ${breakpoint('lg')`
      font-size: 15px;
      letter-spacing: .025em;
    `}

    ${breakpoint('xl')`
      font-size: 18px;
    `}
    
    br {
      content: "A" !important;
      display: block !important;
      margin-bottom: 20px !important;
    }
    
  }

  p a {
    font-weight: normal;
    transition: 0.5s ease-in-out;
    color: rgba(255,255,255,1);
    

   
  }
  
  .animated {
    transition: opacity 2s var(--util-ease), transform  2s var(--util-ease), filter 1s, max-width 1s var(--util-ease);
    will-change: transform;

    &.delay-1 {
      transition-delay: 0.2s;
    }

    &.delay-2 {
      transition-delay: 0.4s;
    }

    &.delay-3 {
      transition-delay: 0.6s;
    }

    &.delay-4 {
      transition-delay: 0.8s;
    }
  }

  .fadeOutUp {
    ${breakpoint('xs')`
      opacity: 0;
      transform: translate3d(0, 100px, 0) ;
    `};
      ${breakpoint('md')`
      opacity: 0;
      transform: translate3d(0, 100px, 0) ;
      `};
    
  }

 

  .fadeOutUpBlur {
    ${breakpoint('xs')`
      opacity: 0;
      transform: translate3d(0, 100px, 0) ;
      filter: blur(5px);
    `};
         ${breakpoint('md')`
      opacity: 0;
      transform: translate3d(0, 100px, 0) ;
      filter: blur(5px);
      `};

  }

  .fadeOutUpZoomOut {
    opacity: 0;
    transform: translate3d(0, 100px, 0) scale(1.05);
    overflow: hidden;
  }

  .fadeOutIn {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
    overflow: hidden;
  }

 

  .expandOut {
    max-width: 0px;
  }

  .expandIn {
    max-width: 50px;
  }

  .headroom--unfixed {
    transform: translateY(0);
    opacity: 1;
  }

  .headroom {
    will-change: transform opacity;
    transition: 0.5s;

    ${breakpoint('xs')`
      padding-top: 3.5rem;
    `};
    ${breakpoint('sm')`
      padding-top: 3.5rem;
    `};
    @media only screen and (min-width: 770px) and (max-width: 1023px) {
      padding-top: 3.1rem;
    }
    ${breakpoint('lg')`
      padding-top: 3.5rem;
    `};
    ${breakpoint('xl')`
      padding-top: 3.75rem;
    `};
  }

  .headroom--unpinned {
    transform: translateY(-100%);
    opacity: 0;
    pointer-events: none;

  }

  .headroom--pinned {
    transform: translateY(0%);
    opacity: 1;
    background: rgba(0,0,0,1);
    padding: 50px 0;
  }

  ${'' /* TODO: remove for prod */}
  .review-grid {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    z-index: 99999999999999999999; 
    pointer-events: none;

    .review-col {
      height: 100vh;
      position: relative;
      padding: 0 0.5em;
      pointer-events: none;

      .debug-col {
        display: block;
        opacity: 0.25;
        background: cyan;
        height: 100%;
        width: 100%;
        pointer-events: none;
      }
    }  
  }
`;

export default GlobalStyle;
