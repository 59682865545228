import React, { Component } from 'react';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import { Col, Row } from 'react-styled-flexboxgrid';
import breakpoint from 'styled-components-breakpoint';
import InViewMonitor from 'react-inview-monitor';

const PracticeAreaWrapper = styled.div`
  ${breakpoint('xs')`
    margin-bottom: 150px;
  `}

  ${breakpoint('md')`
    margin-bottom: 300px;
  `}
`;

const PracticeDescription = styled.div`
  h3 {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 60px;
  }
`;

const PracticeSections = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 10px;

  ${breakpoint('xs')`
    margin-bottom: 40px;
  `}

  ${breakpoint('md')`
    margin-bottom: 0;
  `}
`;

const StyledRow = styled.div`
  ${breakpoint('xs')`
    flex-direction: column-reverse;
  `}

  ${breakpoint('sm')`
    flex-direction: ${props => (props.index % 2 === 0 ? 'row-reverse' : 'row')};
  `}
`;

const ImageStyled = styled.img`
  width: 100%;
  ${breakpoint('xs')`
    margin-bottom: 45px;
  `}
  ${breakpoint('md')`
    margin-bottom: 0px;
  `}
`;

export default class PracticeArea extends Component {
  state = {};

  renderPracticeSections() {
    const { items } = this.props.data;
    return items.map(item => {
      return RichText.render(item.practice_sections);
    });
  }

  render() {
    const {
      practice_area_header,
      practice_area_body,
      practice_area_image,
    } = this.props.data.primary;
    return (
      <PracticeAreaWrapper>
        <StyledRow middle="xs" as={Row} index={this.props.index}>
          <Col xs={12} sm={5}>
            <PracticeDescription>
              <InViewMonitor
                classNameNotInView="animated fadeOutUpBlur"
                classNameInView="animated fadeInUp"
              >
                {RichText.render(practice_area_header)}
              </InViewMonitor>
              <InViewMonitor
                classNameNotInView="animated fadeOutUp"
                classNameInView="animated fadeInUp"
              >
                {RichText.render(practice_area_body)}
              </InViewMonitor>
            </PracticeDescription>
            <InViewMonitor
              classNameNotInView="animated fadeOutUp"
              classNameInView="animated fadeInUp"
            >
              <PracticeSections>
                {this.renderPracticeSections()}
              </PracticeSections>
            </InViewMonitor>
          </Col>
          <Col xs={false} sm={1} />
          <Col xs={12} sm={6}>
            <InViewMonitor
              classNameNotInView="animated fadeOutUp"
              classNameInView="animated fadeInUp"
              intoViewMargin={'-5%'}
            >
              <ImageStyled src={practice_area_image.url} />
            </InViewMonitor>
          </Col>
        </StyledRow>
      </PracticeAreaWrapper>
    );
  }
}
