import React, { Component } from 'react';
import InViewMonitor from 'react-inview-monitor';
const lottie = require('lottie-web');

class LottiePlayer extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
  }
  state = {
    isPlaying: false,
  };

  componentDidMount() {
    this.anim = lottie.loadAnimation({
      container: this.container.current, // the dom element that will contain the animation
      // renderer: 'svg',
      loop: true,
      autoplay: false,
      path: this.props.animationData, // the path to the animation json
    });
  }

  componentWillUnmount() {
    this.anim.stop();
    this.anim.destroy();
  }

  stopPlay() {
    this.setState(prevState => {
      return { isPlaying: false };
    });
  }
  startPlay() {
    this.setState(prevState => {
      return { isPlaying: true };
    });
  }
  render() {
    return (
      <InViewMonitor
        onInView={() => this.anim.play()}
        onNotInView={() => console.log('not in view')}
      >
        <div ref={this.container} className="container" />
      </InViewMonitor>
    );
  }
}

export default LottiePlayer;
