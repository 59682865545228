import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Prismic from 'prismic-javascript';
import { Helmet } from 'react-helmet';
import { RichText } from 'prismic-reactjs';
import InViewMonitor from 'react-inview-monitor';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import HeroReel from './Components/HeroReel';
import FeaturedWorkItem from './Components/FeaturedWorkItem';
import PracticeAreas from './Components/PracticeAreas';
import LatestNewsItem from './Components/LatestNewsItem';
import NotFound from '../NotFound/NotFound';
import LoadingPage from '../LoadingPage/LoadingPage';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { animateScroll } from 'react-scroll';

const HomeWrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5 {
    ${breakpoint('xs')`
     margin: 25px 0;
  `}

    ${breakpoint('md')`
     margin: 50px 0;
  `}
  }

  .featured-work {
    ${breakpoint('xs')`
     margin-bottom: 50px;
    `}

    ${breakpoint('md')`
     margin-bottom: 150px;
    `}
  }

  .line {
    height: 2px;
    background: white;
  }

  .view-all-work-link {
    h3 {
      display: inline-block;
      margin: 15px auto;
    }
  }
`;

const NewsWrapper = styled.div`
  .menu-wrapper {
    /* overflow: visible !important; */
    width: 100vw;
  }
`;
const NewsItemsWrapper = styled(Row)`
  /* transform: translateX(90px); */
`;

const IntroText = styled.div`
  ${breakpoint('xs')`
    margin:  10vh 0 10vh;
  `}
  ${breakpoint('md')`
    margin:  25vh 0 25vh;
  `}
`;

const LinkStyled = styled(Link)`
  padding-bottom: 10px;
  ${breakpoint('xs')`
    font-size: 12px;
    letter-spacing: .02em;
  `}

  ${breakpoint('md')`
    font-size: 13px;
  `}

  ${breakpoint('lg')`
    font-size: 14px;
    letter-spacing: .05em;
  `}

  ${breakpoint('xl')`
    font-size: 16px;
  `}
`;

export default class Home extends Component {
  state = {
    doc: null,
    notFound: false,
  };

  componentWillMount() {
    this.fetchPage(this.props);
  }

  componentWillReceiveProps(props) {
    this.fetchPage(props);
  }

  componentDidMount() {
    animateScroll.scrollToTop({
      duration: 0,
      delay: 750,
    });
  }

  async fetchPage(props) {
    if (props.prismicCtx) {
      const res = await props.prismicCtx.api.query(
        Prismic.Predicates.at('document.type', 'home')
      );
      this.setState({ doc: res.results[0] });
    }
    return null;
  }

  renderFeaturedWork() {
    const { doc } = this.state;
    return doc.data.body
      .filter(item => item.slice_type === 'featured_work_item')
      .map((item, index) => (
        <FeaturedWorkItem key={index} index={index} data={item.primary} />
      ));
  }

  renderLatestNews() {
    const { doc } = this.state;
    return doc.data.body
      .filter(item => item.slice_type === 'latest_news_item')
      .map((item, index) => {
        return (
          <Col key={index} xs={12} sm={4}>
            <LatestNewsItem data={item.primary} />
          </Col>
        );
      });
  }

  renderPracticeAreas() {
    const { doc } = this.state;
    return doc.data.body
      .filter(item => item.slice_type === 'practice_areas')
      .map((item, index) => {
        return <PracticeAreas key={index} data={item.primary} />;
      });
  }

  render() {
    const { doc } = this.state;

    if (doc) {
      return (
        <>
          <Helmet>
            <title>Leviathan</title>
          </Helmet>
          <HeroReel data={doc.data} />
          <HomeWrapper className="home-wrapper">
            <Grid>
              <IntroText>
                <InViewMonitor
                  classNameNotInView="animated fadeOutUpBlur"
                  classNameInView="animated fadeInUp"
                >
                  <Row>
                    <Col xs={false} sm={1} md={2} />
                    <Col xs={12} sm={10} md={8}>
                      {RichText.render(doc.data.home_title)}
                    </Col>
                    <Col xs={false} sm={1} md={2} />
                  </Row>
                </InViewMonitor>

                <Row>
                  <Col xs={false} sm={1} md={2} />
                  <Col xs={12} sm={10} md={8}>
                    <InViewMonitor
                      classNameNotInView="animated expandOut"
                      classNameInView="animated expandIn delay-2"
                    >
                      <div className="line" />
                    </InViewMonitor>
                  </Col>
                  <Col xs={false} sm={1} md={2} />
                </Row>

                <InViewMonitor
                  classNameNotInView="animated fadeOutUp"
                  classNameInView="animated fadeInUp"
                >
                  <Row>
                    <Col xs={false} sm={1} md={2} />
                    <Col xs={12} sm={10} md={8}>
                      {RichText.render(doc.data.home_description)}
                    </Col>
                    <Col xs={false} sm={1} md={2} />
                  </Row>
                </InViewMonitor>
              </IntroText>

              <InViewMonitor
                classNameNotInView="animated fadeOutUpBlur"
                classNameInView="animated fadeInUp"
              >
                <Row>
                  <Col xs={12}>
                    <h3 className="featured-work">Featured work</h3>
                  </Col>
                </Row>
              </InViewMonitor>

              {this.renderFeaturedWork()}

              <InViewMonitor
                classNameNotInView="animated fadeOutUp"
                classNameInView="animated fadeInUp"
              >
                <Row center="xs">
                  <Col>
                    <Link className="view-all-work-link underline" to="/work">
                      <h3>See all work</h3>
                    </Link>
                  </Col>
                </Row>
              </InViewMonitor>
            </Grid>
            <InViewMonitor
              classNameNotInView="animated fadeOutUp"
              classNameInView="animated fadeInUp"
            >
              {this.renderPracticeAreas()}
            </InViewMonitor>

            <InViewMonitor
              classNameNotInView="animated fadeOutUp"
              classNameInView="animated fadeInUp"
            >
              <NewsWrapper>
                <Grid>
                  <Row between="xs" middle="xs">
                    <Col>
                      <h3 className="latest-news">Latest insights</h3>
                    </Col>
                    <Col>
                      <LinkStyled to="/insights" className="view-all underline">
                        View all
                      </LinkStyled>
                    </Col>
                  </Row>
                  <NewsItemsWrapper className="news-wrapper" around="xs">
                    {this.renderLatestNews()}
                  </NewsItemsWrapper>
                </Grid>
              </NewsWrapper>
            </InViewMonitor>
          </HomeWrapper>
        </>
      );
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return <LoadingPage />;
  }
}
